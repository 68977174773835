import React from 'react'
import TopBar from '../CompanyDashboardComponents/TopBar'
import { useOutletContext, useNavigate } from 'react-router-dom'
import { TextField } from '@mui/material'
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useState, useEffect } from 'react'
import axios from 'axios'
import LoadingButton from '@mui/lab/LoadingButton';
import Skeleton from '@mui/material/Skeleton';


function DashboardAddCompany() {
    const [token, setToken_] = useState(localStorage.getItem("token"));
    const [companiesList, setCompanieslist] = useOutletContext()
    const [companies, setCompanies] = useState([]);
    const [users, setUsers] = useState([]);
    const [companyToolsId, setCompanyToolsId] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [companyEmail, setCompanyEmail] = useState('');
    const [userEmails, setUserEmails] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [userName, setUserName] = useState('');
    const [userPassword, setUserPassword] = useState('');
    const [userRole, setUserRole] = useState('');
    const [selectedCompany, setSelectedCompany] = useState('');
    const [selectedUser, setSelectedUser] = useState('');
    const [isLoading, setIsLoading] = useState(false)
    const [isDetailLoading, setIsDetailLoading] = useState(false)

    const nav = useNavigate()



    const handleChange = (event) => {
        const { name, value } = event.target;
        if (name === 'companyName') {
            setCompanyName(value);
        } else if (name === 'companyToolsId') {
            setCompanyToolsId(value);
        } else if (name === 'companyEmail') {
            setCompanyEmail(value);
        } else if (name === 'userEmails') {
            setUserEmails(value);
        } else if (name === 'userEmail') {
            setUserEmail(value);
        } else if (name === 'userName') {
            setUserName(value);
        } else if (name === 'userPassword') {
            setUserPassword(value);
        } else if (name === 'userRole') {
            setUserRole(value);
        }
    };

    const handleAddCompany = async () => {
        try {
            setIsLoading(true)
            const companyData = {
                company_tools_id: companyToolsId,
                company_name: companyName,
                company_email: companyEmail,
                attached_users: userEmails.split('\n').map((email) => email.trim()),
            };

            // Make an API request to add a company with companyData
            try {
                const response = await axios.post('/api/add-company', companyData);
                nav(0)
                return response.data;
            } catch (error) {
                throw error;
            }

            // Handle the resul
        } catch (error) {
            // Handle errors
        }
    };

    const handleAddUser = async () => {
        try {
            setIsLoading(true)
            const userData = {
                user_name: userName,
                user_email: userEmail,
                user_password: userPassword,
                user_role: userRole,
            };

            // Make an API request to add a user with userData
            try {
                const response = await axios.post('/api/register', userData);
                setCompanyToolsId('')
                setCompanyName('');
                setCompanyEmail('');
                setUserEmails('');
                setUserEmail('');
                setUserName('');
                setUserPassword('');
                setUserRole('');
                setIsLoading(false)
                return response.data;
            } catch (error) {
                throw error;
            }

            // Handle the result
        } catch (error) {
            // Handle errors
        }
    };

    const handleDeleteCompany = async () => {
        // Make an API request to delete the selected company
        try {
            setIsLoading(true)
            const response = await axios.delete(`/api/delete-company`, {
                params: {
                    company_id: selectedCompany
                }
            });
            setCompanyToolsId('')
            setCompanyName('');
            setCompanyEmail('');
            setUserEmails('');
            setUserEmail('');
            setUserName('');
            setUserPassword('');
            setUserRole('');
            setIsLoading(false)
            for (let i = 0; i < companiesList.length; i++) {
                if (companiesList[i].company_details._id == selectedCompany) {
                    const newCompanyList = companiesList.filter((company) => company.company_details !== companiesList[i].company_details);
                    localStorage.setItem('companies-list', JSON.stringify(newCompanyList))
                }
            }
            return response.data;
        } catch (error) {
            throw error;
        }
    };

    const handleDeleteUser = async () => {
        // Make an API request to delete the selected user
        try {
            setIsLoading(true)
            const response = await axios.delete(`/api/delete-user`, {
                params: {
                    user_id: selectedUser
                }
            });
            setCompanyToolsId('')
            setCompanyName('');
            setCompanyEmail('');
            setUserEmails('');
            setUserEmail('');
            setUserName('');
            setUserPassword('');
            setUserRole('');
            setIsLoading(false)
            return response.data;
        } catch (error) {
            throw error;
        }
    };

    const navigate = useNavigate()


    useEffect(() => {
        console.log('add company')
        const getCompanies = async () => {
            try {
                const response = await axios.get(`/api/get-company-list`,{
                    headers: { Authorization: token }
                });
                const data = await response.data;
                console.log(data)
                setCompanies(data)
            } catch (error) {
                throw error;
            }
        }

        const getUsers = async () => {
            try {
                const response = await axios.get(`/api/get-user-list`,{
                    headers: { Authorization: token }
                });
                const data = await response.data;
                setUsers(data)
            } catch (error) {
                throw error;
            }
        }



        try {
            const tok = localStorage.getItem("token")
            setToken_(tok)
        } catch (error) {
            console.log('No Token')
        }
        const fetchData = async () => {
            setIsDetailLoading(true)
            await getCompanies();
            await getUsers();
            setIsDetailLoading(false)
        }
        fetchData()
    }, [isLoading]);


    return (
        <div>
            <div className='page'>
                <div style={{ position: 'sticky', top: "0", zIndex: 2 }}>
                    <TopBar companyName={'Dashboard'} backAmount={'..'} />
                </div>
                <div style={{
                    display: 'flex',
                    backgroundColor: '#dcf2f6',
                    justifyContent: 'center',
                    gap: '5%',
                    marginTop: '3%'
                }}>
                    <div className='add-company-form' style={{
                        backgroundColor: 'white',
                        borderRadius: '10px',
                        padding: '1%',
                        width: '30%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                         }}>
                        <h3 className='form-title'> Add Company </h3>
                        <div className='input-field' style={{ width: '100%'}}>
                            <p className='input-title'>Company IceWeb Tools ID</p>
                            <TextField
                                className='text-field'
                                required
                                id="outlined-required"
                                label="Required"
                                name="companyToolsId"
                                value={companyToolsId}
                                onChange={handleChange}
                                sx={{
                                    marginTop: '-10px',
                                    width: '100%',
                                }}
                            />
                        </div>
                        <div className='input-field'  style={{ width: '100%'}}>
                            <p className='input-title'>Company Name</p>
                            <TextField
                                className='text-field'
                                required
                                id="outlined-required"
                                label="Required"
                                name="companyName"
                                value={companyName}
                                onChange={handleChange}
                                sx={{
                                    marginTop: '-10px',
                                    width: '100%',

                                }}
                            />
                        </div>
                        <div className='input-field' style={{ width: '100%'}}>
                            <p className='input-title'>Company Email</p>
                            <TextField
                                className='text-field'
                                required
                                id="outlined-required"
                                label="Required"
                                helperText="Enter company main email address"
                                name="companyEmail"
                                value={companyEmail}
                                onChange={handleChange}
                                sx={{
                                    marginTop: '-10px',
                                    width: '100%',

                                }}
                            />
                        </div>
                        <div className='input-field' style={{ width: '100%'}}>
                            <p className='input-title'>Attached Users</p>
                            <TextField
                                className='text-field'
                                required
                                multiline
                                helperText="Enter user emails seperated by new line"
                                id="filled-multiline-static"
                                label="Required"
                                name="userEmails"
                                rows={4}
                                value={userEmails}
                                onChange={handleChange}
                                sx={{
                                    width: '100%',
                                }}
                            />
                        </div>
                        <LoadingButton loading={isLoading} className='sumbit-btn' variant="contained" onClick={handleAddCompany}
                        sx={{
                            width: '50%',marginTop: '10px'
                        }}>
                            Submit
                        </LoadingButton>
                        <h3 className='form-title' style={{ marginTop: '10px'}}> Delete Company </h3>
                        <div className='delete-company-form' style={{ width: '100%'}}>
                            <p className='input-title'>Select Company</p>
                            <Select
                                className='text-field'
                                variant='filled'
                                labelId="demo-multiple-name-label"
                                id="demo-multiple-name"
                                label='Company'
                                value={selectedCompany}
                                onChange={(event) => setSelectedCompany(event.target.value)}
                                sx={{
                                    marginTop: '-10px',
                                    width: '100%'
                                }}
                            >
                                {isDetailLoading ? (
                                    <MenuItem value={'skeleton'}>
                                        <Skeleton variant="text" sx={{ fontSize: '1rem',  width: '50%', marginLeft: '25%' }} />
                                    </MenuItem>
                                ) : (
                                    companies.map((item, index) => (
                                        <MenuItem
                                            key={index}
                                            value={item.company_details._id}
                                        >
                                            {item.company_details.company_name}
                                        </MenuItem>
                                    ))
                                )}
                            </Select>
                            <LoadingButton variant="contained" loading={isLoading} color="error" onClick={handleDeleteCompany} sx={{ marginTop: '25px', width: '50%', marginLeft: '25%' }}>
                                Delete
                            </LoadingButton>
                        </div>
                    </div>
                    <div className='add-user-form' style={{ 
                        backgroundColor: 'white',
                        borderRadius: '10px',
                        padding: '1%',
                        width: '30%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        }}>
                        <h3 className='form-title'> Add User </h3>
                        <div className='input-field' style={{ width: '100%'}} >
                            <p className='input-title'>Full Name</p>
                            <TextField
                                className='text-field'
                                required
                                id="outlined-required"
                                label="Required"
                                name="userName"
                                value={userName}
                                onChange={handleChange}
                                sx={{
                                    marginTop: '-10px',
                                    width: '100%',

                                }}
                            />
                        </div>
                        <div className='input-field' style={{ width: '100%'}}>
                            <p className='input-title'>User Password</p>
                            <TextField
                                className='text-field'
                                required
                                id="outlined-required"
                                label="Required"
                                name="userPassword"
                                value={userPassword}
                                onChange={handleChange}
                                sx={{
                                    marginTop: '-10px',
                                    width: '100%',

                                }}
                            />
                        </div>
                        <div className='input-field' style={{ width: '100%'}}>
                            <p className='input-title'>User Email</p>
                            <TextField
                                className='text-field'
                                required
                                id="outlined-required"
                                label="Required"
                                helperText="Enter user main email address"
                                name="userEmail"
                                value={userEmail}
                                onChange={handleChange}
                                sx={{
                                    marginTop: '-10px',
                                    width: '100%',

                                }}
                            />
                        </div>
                        <div className='input-field' style={{ width: '100%'}}>
                            <p className='input-title'>User Role</p>
                            <Select
                                variant='filled'
                                className='text-field'
                                labelId="demo-multiple-name-label"
                                id="demo-multiple-name"
                                value={userRole}
                                onChange={handleChange}
                                name="userRole"
                                sx={{
                                    marginTop: '-10px',
                                    width: '100%',

                                }}
                            >
                                <MenuItem value={'admin'}>Admin</MenuItem>
                                <MenuItem value={'admin-customer'}>Admin Customer</MenuItem>
                                <MenuItem value={'customer'}>Customer</MenuItem>
                            </Select>
                            <LoadingButton loading={isLoading} className='sumbit-btn' onClick={handleAddUser} variant="contained" sx={{ marginTop: '110px', width: '50%', marginLeft: '25%' }}>
                                Submit
                            </LoadingButton>
                        </div>
                        <h3 className='form-title' style={{ marginTop: '10px'}}> Delete User </h3>
                        <div className='delete-user-form' style={{ width: '100%'}}>
                            <p className='input-title'>Select User</p>
                            <Select
                                className='text-field'
                                variant='filled'
                                labelId="demo-multiple-name-label"
                                id="demo-multiple-name"
                                value={selectedUser}
                                onChange={(event) => setSelectedUser(event.target.value)}
                                label='User'
                                sx={{
                                    marginTop: '-10px',
                                    width: '100%',

                                }}
                            >
                                {isDetailLoading ? (
                                    <MenuItem value={'skeleton'}>
                                        <Skeleton variant="text" sx={{ fontSize: '1rem', width: '100%' }} />
                                    </MenuItem>
                                ) : (
                                    users.map((item, index) => (
                                        <MenuItem
                                            key={index}
                                            value={item.user_id}
                                        >
                                            {item.user_name}
                                        </MenuItem>
                                    ))
                                )}
                            </Select>
                            <LoadingButton loading={isLoading} variant="contained" color="error" onClick={handleDeleteUser} sx={{ marginTop: '25px', width: '50%', marginLeft: '25%' }}>
                                Delete
                            </LoadingButton>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DashboardAddCompany
