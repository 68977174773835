import React, {useState, useEffect} from 'react'
import MenuItem from '@mui/material/MenuItem';
import { Select } from '@mui/material';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import LanguageIcon from '@mui/icons-material/Language';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';


function UrlCompare(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (event) => {
        setAnchorEl(null);
        console.log(event.target.textContent)
    };
    const [ selection, setSelection ] = useState({
        selection_one: {
            val: '',
            prefix: "start"
        },
        selection_two: {
            val: '',
            prefix: "exact"
        }
    })

    const handleDone = (close) => {
        props.setFinalSelection(selection)
        close()
    }
    useEffect(() => {
        console.log(selection.selection_one.val)
    }, [selection.selection_one.val]);

    return (
        <div>
            <Popup contentStyle={{ width: '630px', borderRadius: '5px', paddingTop: '10px' }} trigger={
                <button
                    className='date-range-display'
                    onClick={handleClick}
                    style={{ display: 'flex'}}
                >
                    <LanguageIcon style={{ marginRight: '13px' }} />
                        {props.finalSelection.selection_one.val === '' || props.finalSelection.selection_two.val === '' ? (
                        <div>
                            Please Choose URL's To Compare
                        </div>
                        ) : (
                        <div>
                            {`Compare ${props.finalSelection.selection_one.val} To ${props.finalSelection.selection_two.val}`}
                        </div>
                        )}
    
                </button>
            } modal nested>
                {close => (
                    <div className='date-range-popup' >
                        <div className='title'>
                            Compare By URL
                        </div>
                        <div className='title' style={{ marginLeft: '330px', fontSize: '15px', color: 'gray'}}> Compare </div>
                        <div className='field-type' style={{ display: 'flex', marginLeft: '30px'}}>
                            <Select
                                name='include'
                                value={selection.selection_one.prefix}
                                onChange={(event) => setSelection((prevState) => ({
                                    ...prevState,
                                    selection_one: {
                                        ...prevState.selection_one,
                                        prefix: event.target.value
                                    }
                                    
                                }))}
                                displayEmpty
                                sx={{ width: '50%', marginTop: '10.5px', marginRight: '5px', width: '150px', maxWidth: '140px', height: '40px' }}
                            >
                                <MenuItem value={'start'}>Starts With</MenuItem>
                                <MenuItem value={'end'}>Ends With</MenuItem>
                                <MenuItem value={'contain'}>Contains</MenuItem>
                                <MenuItem value={'exact'}>Exact Match</MenuItem>
                                <MenuItem value={'notequal'}>Does Not Equal</MenuItem>
                            </Select>
                            <TextField
                                id="include"
                                variant="outlined"
                                placeholder='Enter URL without prefix, /for/example/'
                                value={selection.selection_one.val}
                                onChange={(event) => setSelection((prevState) => ({
                                    ...prevState,
                                    selection_one: {
                                        ...prevState.selection_one,
                                        val: event.target.value
                                    }
                                    
                                }))}
                                label={'URL'}
                                name={'url_one'}
                                size='small'
                                sx={{ marginTop: '10px', width: '400px' }}
                            />
                        </div>
                        <div className='title' style={{ marginLeft: '354px', fontSize: '15px', color: 'gray'}}> To </div>
                        <div className='field-type' style={{ display: 'flex', marginLeft: '30px' }}>
                            <Select
                                name='include'
                                value={selection.selection_two.prefix}
                                onChange={(event) => setSelection((prevState) => ({
                                    ...prevState,
                                    selection_two: {
                                        ...prevState.selection_two,
                                        prefix: event.target.value
                                    }
                                    
                                }))}
                                displayEmpty
                                sx={{ width: '50%', marginTop: '10.5px', marginRight: '5px', width: '150px', maxWidth: '140px', height: '40px' }}
                            >
                                <MenuItem value={'start'}>Starts With</MenuItem>
                                <MenuItem value={'end'}>Ends With</MenuItem>
                                <MenuItem value={'contain'}>Contains</MenuItem>
                                <MenuItem value={'exact'}>Exact Match</MenuItem>
                                <MenuItem value={'notequal'}>Does Not Equal</MenuItem>
                            </Select>
                            <TextField
                                id="include"
                                variant="outlined"
                                value={selection.selection_two.val}
                                placeholder='Enter URL without prefix, /for/example/'
                                onChange={(event) => setSelection((prevState) => ({
                                    ...prevState,
                                    selection_two: {
                                        ...prevState.selection_two,
                                        val: event.target.value
                                    }
                                    
                                }))}
                                label={'URL'}
                                name={'url_one'}
                                size='small'
                                sx={{ marginTop: '10px',width: '400px' }}
                            />
                        </div>
                        <button className='pop-up-apply' onClick={() => handleDone(close)} style={{ float: 'right', marginTop: '25px' }}> Done </button>
                    </div>
                )}
            </Popup>
        </div>
    )
}

export default UrlCompare


{/* <div className='field-type' style={{ display: 'flex' }}>
<Select
    name='include'
    displayEmpty
    sx={{ width: '50%', marginTop: '10.5px', marginRight: '5px', width: '150px', maxWidth: '140px', height: '40px' }}
>
    <MenuItem value={'start'}>Starts With</MenuItem>
    <MenuItem value={'end'}>Ends With</MenuItem>
    <MenuItem value={'contain'}>Contains</MenuItem>
    <MenuItem value={'exact'}>Exact Match</MenuItem>
    <MenuItem value={'notequal'}>Does Not Equal</MenuItem>
</Select>
<TextField
    id="include"
    variant="outlined"
    size='small'
    sx={{ marginTop: '10px' }}
/>
</div> */}