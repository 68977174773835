import React from 'react'
import { useState, useEffect } from 'react'
import { DateRangePicker, defaultStaticRanges } from 'react-date-range';
import Popup from 'reactjs-popup';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import 'reactjs-popup/dist/index.css';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { DefinedRange } from 'react-date-range';
import { addYears, startOfYear, endOfYear, addDays } from 'date-fns';



function CompareDateRange(props) {

    const [state, setState] = useState({
        selection1: {
          startDate: addDays(new Date(), -30),
          endDate: new Date(),
          key: 'selection1'
        },
        selection2: {
          startDate: addDays(new Date(), -31),
          endDate: addDays(new Date(), -60),
          key: 'selection2',
        }
      });



    function formatDate(inputDate) {
        const date = new Date(inputDate);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is 0-based
        const day = String(date.getDate()).padStart(2, '0');

        const dateWithYear = `${year}-${month}-${day}`
        const dateNoYear = `${month}-${day}`
      
        return [dateWithYear,dateNoYear];

      }

    const updateDateRange = (selectionOne,selectionTwo) => {
        props.setCompareDates({
            selection1: selectionOne,
            selection2: selectionTwo
        });
      };


    const handleDoneClick = (close) => {
    updateDateRange({
        startDate: formatDate(state.selection1.startDate)[0],
        endDate: formatDate(state.selection1.endDate)[0],
    },{
        startDate: formatDate(state.selection2.startDate)[0],
        endDate: formatDate(state.selection2.endDate)[0],
    })

    close();

    }


      useEffect(() => {
        console.log(props.compareDates)
      }, [props.compareDates]);

      useEffect(() => {
        if (defaultStaticRanges.length === 6) {
            defaultStaticRanges.push(
                {
                    label: 'This Year',
                    range: function range() {
                      const startDate = startOfYear(addYears(new Date(), 0));
                      const endDate = new Date();
              
                      return {
                        startDate,
                        endDate,
                      };
                    },
                    isSelected: function isSelected() {
                      return false;
                    },
                  },
                {
                label: 'Last Year',
                range: function range() {
                  const startDate = startOfYear(addYears(new Date(), -1));
                  const endDate = endOfYear(addYears(new Date(), -1));
          
                  return {
                    startDate,
                    endDate,
                  };
                },
                isSelected: function isSelected() {
                  return false;
                },
              }
              );
        }
      }, []);


      return (
        <div style={{ display: 'flex', gap: '10px'}}>
            <div style={{ display: 'flex', gap: '10px'}}>
                <div style={{ alignSelf: 'center'}}>
                    From
                </div>
            <Popup contentStyle={{width: '900px', borderRadius: '5px', paddingTop: '10px'}} trigger={
            <button className='date-range-display'>
                <CalendarTodayIcon style={{ marginRight: '13px' }}/>{`${props.compareDates.selection1.endDate} - ${props.compareDates.selection1.startDate}`}
            </button>
        } modal nested>
                {close => (
                    <div className='date-range-popup' >
                        <DateRangePicker
                            onChange={item => setState({ ...state, ...item })}
                            showSelectionPreview={true}
                            moveRangeOnFirstSelection={false}
                            months={2}
                            ranges={[state.selection1, state.selection2]}
                            preventSnapRefocus={true}
                            direction="horizontal"
                            staticRanges={defaultStaticRanges}
                            ariaLabels={{
                                dateInput: {
                                selection1: { startDate: "start date input of selction 1", endDate: "end date input of selction 1" },
                                selection2: { startDate: "start date input of selction 2", endDate: "end date input of selction 2" }
                                },
                                monthPicker: "month picker",
                                yearPicker: "year picker",
                                prevButton: "previous month button",
                                nextButton: "next month button",
                            }}
                            />
                        <button className='pop-up-apply' onClick={() => handleDoneClick(close)} style={{float: 'right'}}> Done </button>
                    </div>
                )}
            </Popup>
            </div>
            <div style={{ display: 'flex', gap: '10px'}}>
            <div style={{ alignSelf: 'center'}}>
                    To
                </div>
            <Popup contentStyle={{width: '900px', borderRadius: '5px', paddingTop: '10px'}} trigger={
            <button className='date-range-display'>
                <CalendarTodayIcon style={{ marginRight: '13px' }}/>{`${props.compareDates.selection2.startDate} - ${props.compareDates.selection2.endDate}`}
            </button>
        } modal nested>
                {close => (
                    <div className='date-range-popup' >
                        <DateRangePicker
                            onChange={item => setState({ ...state, ...item })}
                            showSelectionPreview={true}
                            moveRangeOnFirstSelection={false}
                            preventSnapRefocus={true}
                            months={2}
                            ranges={[state.selection1, state.selection2]}
                            direction="horizontal"
                            ariaLabels={{
                                dateInput: {
                                selection1: { startDate: "start date input of selction 1", endDate: "end date input of selction 1" },
                                selection2: { startDate: "start date input of selction 2", endDate: "end date input of selction 2" }
                                },
                                monthPicker: "month picker",
                                yearPicker: "year picker",
                                prevButton: "previous month button",
                                nextButton: "next month button",
                            }}
                            />
                        <button className='pop-up-apply' onClick={() => handleDoneClick(close)} style={{float: 'right'}}> Done </button>
                    </div>
                )}
            </Popup>
            </div>
        </div>
    )
}

export default CompareDateRange

