import React from 'react'
import { Outlet, useOutletContext, } from 'react-router-dom'


function CompanyCompareRoot() {

    const [dashboardDetails, setDashboardDetails] = useOutletContext()

  return (
    <div className='body' >
        <Outlet context={[dashboardDetails, setDashboardDetails]} />
    </div>
  )
}

export default CompanyCompareRoot
