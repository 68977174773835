import React, { useState, useEffect, useContext } from 'react'
import TopBar from '../CompanyDashboardComponents/TopBar';
import PeopleCount from '../CompanyDashboardComponents/PeopleCount';
import { Form, useOutletContext, useNavigate } from "react-router-dom";
import DateRange from '../CompanyDashboardComponents/DateRange';
import PopularChart from '../CompanyDashboardComponents/PopularChart';
import ByPrecent from '../CompanyDashboardComponents/ByPrecent';
import MapChart from '../CompanyDashboardComponents/MapChart';
import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import axios from 'axios';
import StateContext from '../StateContext';
import { useParams } from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ViewListIcon from '@mui/icons-material/ViewList';
import LinkIcon from '@mui/icons-material/Link';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import IncomeBreakdown from '../CompanyDashboardComponents/IncomeBreakdown';
import GaugeChart from '../CompanyDashboardComponents/GaugeChart';

function CompanySegDashboard() {
  const segmentID = useParams().segmentID
  const companyID = useParams().companyId
  const [token, setToken_] = useState(localStorage.getItem("token"));
  const [content, setContent] = useState("");
  const { sideBarArray, setSideBarArray } = useContext(StateContext)
  const [dashboardDetails, setDashboardDetails] = useOutletContext()
  const [inPage, setInPage] = useState(false)
  const [segmentDetails, setSegmentDetails] = useState({
    company_id: dashboardDetails.company_id,
    start_date: undefined,
    end_date: undefined,
    byPrecent: {
      items: [],
      counts: [0]
    },
    popularChart: {
      popular_array: [],
      popular_count_array: [0],
    },
    popularStateChart: {
      popular_array: [],
      popular_count_array: [0],
    },
    incomeBreakdown: {
      popular_array: [],
      popular_count_array: [0],
    },
    segment: JSON.parse(localStorage.getItem('segment'))
  })

  const menuDictionary = [
    {
      text: 'Dashboard',
      icon: <DashboardIcon style={{ color: '#5cbad1' }} />,
      route: `companies/${companyID}/segment/${segmentID}`,
    },
    {
      text: 'Segment Data',
      icon: <ViewListIcon style={{ color: '#5cbad1' }} />,
      route: `companies/${companyID}/segment/${segmentID}/data`,
    },
    {
      text: 'Intergrations',
      icon: <LinkIcon style={{ color: '#5cbad1' }} />,
      route: `companies/${companyID}/segment/${segmentID}/integrations`,
    },
    {
      text: 'Compare',
      icon: <ImportExportIcon style={{ color: '#5cbad1' }} />,
      route: `companies/${companyID}/segment/${segmentID}/compare`,
    }
    // Add more items to your dictionary as needed
  ];

  const getCounts = async () => {
    try {
      const response = await axios.post(`/api/get-company-counts`, {
        company_id: dashboardDetails.company_id,
        start_date: segmentDetails.start_date,
        end_date: segmentDetails.end_date,
        filters: segmentDetails.segment.filters,
        segment_id: segmentDetails.segment.segmentID
      }, {
        headers: { Authorization: token }
      });
      if (response.status === 200) {
        const result = response.data;
        setSegmentDetails((prevState) => ({
          ...prevState,
          people_count: result.people_count,
          journey_count: result.journey_count,
        }));
        console.log(result)
      } else {
        console.error('Access denied:', response.statusText);

      }
    } catch (error) {
      console.log(error)
    }
  };

  useEffect(() => {
    setInPage(true)
  }, []);

  useEffect(() => {
    setSideBarArray(menuDictionary)
  }, [inPage]);


  useEffect(() => {
    console.log(dashboardDetails)
    const fetchData = async () => {
      getCounts()
    }

    fetchData()
  }, [dashboardDetails.start_date, dashboardDetails.end_date]);



  return (
    <div className='dashboard-page' style={{ display: 'flex', flexDirection: 'column'}}>
      <div className='top-bar' style={{ position: 'sticky', top: "0", zIndex: 2 }}>
        <TopBar
          elements={
            <div style={{ display: 'flex' }}>
              <DateRange
                currentStartDate={segmentDetails.start_date}
                currentEndDate={segmentDetails.end_date}
                setDashboardDetails={setSegmentDetails}

              />
            </div>
          }
          companyName={segmentDetails.segment.segmentName}
          backAmount={'..'}
        />
      </div>
      <div className='details'>
      <Box sx={{ flexGrow: 1, marginLeft: '4%', marginTop: '2%', height: '140vh'}}>         
       <Grid container spacing={2} style={{ gap: '20px' }}>
            <Grid item sx={{ backgroundColor: 'white', borderRadius: '10px', height: '350px', width: '70%' }}>
              <PopularChart dashboardDetails={segmentDetails} setDashboardDetails={setSegmentDetails} />
            </Grid>
            <Grid item sx={{ background: 'linear-gradient(11deg, rgba(61,145,255,1) 29%, rgba(0,209,255,1) 100%)', borderRadius: '10px', width: '27%', height: '350px' }}>
              <PeopleCount
                className='people-count'
                peopleCount={segmentDetails.people_count}
                journeyCount={segmentDetails.journey_count}
              />
            </Grid>
            <Grid item sx={{ backgroundColor: 'white', borderRadius: '10px', width: '35%', height: '350px' }}>
              <ByPrecent dashboardDetails={segmentDetails} setDashboardDetails={setSegmentDetails} />
            </Grid>
            <Grid item sx={{ backgroundColor: 'white', borderRadius: '10px', width: '62%', height: '350px' }}>
              <MapChart dashboardDetails={segmentDetails} setDashboardDetails={setSegmentDetails} setTooltipContent={setContent} toolTipContent={content} />
              <Tooltip id="map-tooltip" />
            </Grid>
            <Grid item sx={{ backgroundColor: 'white', borderRadius: '10px', width: '55%', }}>
          <IncomeBreakdown dashboardDetails={segmentDetails} setDashboardDetails={setSegmentDetails} />
        </Grid>
        <Grid item sx={{ backgroundColor: 'white', borderRadius: '10px', width: '42%', display: 'flex', flexDirection: 'column'}}>
                                <GaugeChart  dashboardDetails={segmentDetails} setDashboardDetails={setSegmentDetails} />
                            </Grid>
          </Grid>
        </Box>
      </div>
    </div>
  )
}

export default CompanySegDashboard
