
const checkBoxDict = {
    genders: ['Male', 'Female'],
    marrital_status: ['Married', 'Single'],
    household_income: [
        "Under $10,000",
        "$10,000-$19,999",
        "$20,000-$29,999",
        "$30,000-$39,999",
        "$40,000-$49,999",
        "$50,000-$59,999",
        "$60,000-$74,999",
        "$75,000-$99,999",
        "$100,000-$149,999",
        "$150,000-$174,999",
        "$175,000-$199,999",
        "$200,000-$249,999",
        "$250K +",
        "Unknown",
    ],
    income_levels: [
        "LT_30K",
        "30K_50K",
        "50K_74K",
        "75K_99K",
        "100K_150",
        "GT_150K",
        "Unknown",
    ],
    net_worth: [
        "Less than $1",
        "$1 - $4,999",
        "$5,000 - $9,999",
        "$10,000 - $24,999",
        "$25,000 - $49,999",
        "$50,000 - $99,999",
        "$100,000 - $249,999",
        "$250,000 - $499,999",
        "Greater than $499,999",
        "Unknown",
    ],
    credit_range: [
        "499 & Less",
        "500-549",
        "550-599",
        "600-649",
        "650-699",
        "700-749",
        "750-799",
        "800+",
        "Unknown",
    ],
    ethnic_group: [
        "White",
        "Jewish",
        "Other",
        "Hispanic",
        "Unknown",
        "Asian",
        "African American"
    ],
    education: [
        "Completed College",
        "Completed High School",
        "Completed Graduate School",
        "Some College",
        "Some High School",
        "Attended Vocational/Technical",
        "Doctorate/PhD or Above",
        "Unknown"
    ],
    generation: [
        "Millennials and Gen Z (1982 and after)",
        "Generation X (1961-1981)",
        "Baby Boomers (1943-1960)",
        "Post-WWII (1942 and before)",
        "Unknown",
    ],
    home_owner: [
        "Home Owner",
        "Renter",
        "Probable Home Owner",
        "Unknown"
    ],
    occupation_detail: [
        "Homemaker",
        "Manager",
        "Acct Executive",
        "Sales Clerk/Counterman",
        "Professional",
        "Health Care",
        "Sales",
        "President",
        "Self Employed",
        "Blue Collar Worker",
        "Retired",
        "Middle Management",
        "Construction",
        "Engineer",
        "Therapist",
        "Installer",
        "Auto Mechanic",
        "Administration/Management",
        "Medical Doctor/Physician",
        "Social Worker/Case Worker",
        "Nurse (Registered)",
        "Pilot",
        "Laborer",
        "Teller/Bank Teller",
        "Teacher",
        "Exec/Upper Mgmt",
        "Retired/Pensioner",
        "Armed Forces",
        "Civil Service/Government",
        "Bodyman",
        "Dental Assistant",
        "Chiropractor",
        "Optometrist",
        "Dentist",
        "Real Estate/Realtor",
        "Computer Operator",
        "Aide/Assistant",
        "Computer Programmer",
        "Nurse/LPN",
        "Medical/Paramedic",
        "Nurse",
        "Nurses Aide/Orderly",
        "Therapist/Physical",
        "Pharmacist/Pharmacy",
        "Director/Executive Director/Etc",
        "Vice President",
        "Insurance/Agent",
        "Broker/Stock/Trader",
        "Barber/Hairstylist/Beautician",
        "Cosmetologist",
        "Finance",
        "Legal/Attorney/Lawyer",
        "Cashier",
        "Electrician",
        "Conservation/Enviroment",
        "Secretary",
        "Plumber",
        "Farmer/Dairyman",
        "Student",
        "Lineman",
        "Owner",
        "Architect",
        "Mechanic",
        "Marketing",
        "Carpenter/Furniture/Woodworking",
        "Security",
        "Medical Technician",
        "Supervisor",
        "Ceo/Cfo/Chairman/Corp Officer",
        "Principal/Dean/Educator",
        "Dental Hygenist",
        "Dietician",
        "Manager/Traffic Manager",
        "Cook",
        "Clerk",
        "Driver/Bus Driver",
        "Actor/Entertainer/Announcer",
        "Air Traffic Control",
        "Professor",
        "Veterinarian",
        "Driver",
        "Director/Art Director",
        "Hostess/Host/Usher",
        "Aide/Assistant/School",
        "Mason/Brick/Etc",
        "Psychologist",
        "Technician/Lab",
        "Military Personnel",
        "Manager/Assistant Manager",
        "Comptroller",
        "Foreman/Crewleader",
        "Optician",
        "Buyer",
        "Technician",
        "Scientist",
        "Bookkeeper",
        "Data Entry/Key Punch",
        "Coordinator",
        "Toolmaker",
        "Machinist",
        "Inspector",
        "Operator",
        "Manager/Marketing Manager",
        "Counselor",
        "Computer/Systems Analyst",
        "Army Credit Union Trades",
        "Legal/Paralegal/Assistant/Law",
        "Legal/Secretary",
        "Designer",
        "Typist",
        "Housekeeper/Maid",
        "Technician/Xray",
        "Helper",
        "Shipping/Import/Export/Custom",
        "Politician/Legisaltor/Diplomat",
        "Artist",
        "Custodian",
        "Reporter",
        "Navy Credit Union Trades",
        "Draftsman",
        "Police/Trooper",
        "Estimator",
        "Firefighter",
        "Pastor",
        "Fisherman/Seaman",
        "Courier/Delivery/Messenger",
        "Maintenance",
        "Driver/Truck Driver",
        "Agent",
        "Manager/Office Manager",
        "Merchandiser",
        "Locksmith",
        "Waiter/Waitress",
        "Repairman",
        "Assembler",
        "Cleaner/Laundry",
        "Crewman",
        "Child Care/Day Care/Babysitter",
        "Consultant/Advisor",
        "Surveyor",
        "Accounting/Biller/Billing Clerk",
        "Manager/District Manager",
        "Seamstress/Tailor/Handicraft",
        "Collector",
        "Baker",
        "Planner",
        "Photography",
        "Manager/Property Manager",
        "Welder",
        "Aide/Assistant/Staff",
        "Operator/Machine Operator",
        "Pipefitter",
        "Clerk/File",
        "Chemist",
        "Cutter",
        "Manager/Store Manager",
        "Editor",
        "Instructor",
        "Bartender",
        "Janitor",
        "Dispatcher",
        "Painter",
        "Manager/Sales Manager",
        "Banker",
        "Telemarket/Telephone/Operator",
        "Unknown"
    ],
    political_party: [
        "Republican",
        "Democrat",
        "Conservative",
        "Independence",
        "Independent",
        "Libertarian",
        "Liberal",
        "NA/Unknown",
    ],
    religion: [
        "Catholic",
        "Protestant",
        "Jewish",
        "Christian",
        "Islamic / Muslim",
        "Buddhist",
        "Hindu",
        "Other",
        "Unknown",
    ],
    behaviors: [
        "Cat owner",
        "Dog owner",
        "Healthy living",
        "Luxury lifestyle",
        "Magazine Subscriber",
        "Pet owner",
        "SOHO business",
        "Travel - cruises",
        "Travel - foreign",
        "Travel - vacation",
        "Truck owner"
    ],
    interests: [
        "Aerobics",
        "Arts & crafts",
        "Auto / motorcycling racing",
        "Baseball",
        "Basketball",
        "Bird watching",
        "Boating / sailing",
        "Camping and hiking",
        "Car interest",
        "Casino gambling and sports betting",
        "Charitable donor",
        "Cigars",
        "Collects antiques",
        "Collects coins",
        "Collects fine arts",
        'Collects sports memorabilia',
        'Collects stamps',
        'Cooking',
        "Dieting / weight loss",
        "Do it yourself",
        "Fishing",
        "Fitness",
        "Football",
        "Gardening",
        "Golfing",
        "Gourmet cooking",
        "Healthy living",
        "Hockey",
        "Home decorators",
        "Home improvements",
        "Home study courses",
        "Hunting",
        "Knitting or needlework",
        "Likes NASCAR racing",
        'Motorcycle ownership',
        "Movies",
        'Music lifestyle',
        "Outdoors",
        "Photography",
        "Recreational vehicle ownership",
        "Running",
        "Scuba diving",
        "Self improvement",
        "Snow skiing",
        "Soccer",
        "Tennis",
        "Walking",
        "Woodworking"
    ],
    dwelling_type: [
        "Single Family",
        "Multi Family Dwelling/Apartment",
        "Unknown"
    ],
    language: [
        "English",
        "-",
        "Unknown",
        "Czech",
        "Spanish",
        "Arabic",
        "Hebrew",
        "Chinese (Mandarin, Cantonese and other dialects)",
        "Hindi",
        "Portuguese",
        "Vietnamese",
        "Polish",
        "Farsi",
        "German",
        "Korean",
        "Russian",
        "Bulgarian",
        "Amharic",
        "Italian",
        "Greek",
        "Japanese",
        "Serbo-Croatian",
        "Tagalog",
        "Romanian",
        "Swedish",
        "Hungarian",
        "Urdu",
        "Armenian",
        "French",
        "Laotian (including Hmong)",
        "Dutch",
        "Danish",
        "Oromo",
        "Slovenian",
        "Albanian",
        "Kazakh",
        "Lithuanian",
        "Sinhalese"
    ],
    mortgage_loan_type: [
        "NA/Unknown",
        "VA Loan",
        "Conventional",
        "FHA",
        "Private Party Lender"
    ],
    new_credit_offered_household: [
        "$0 - $100",
        "$101 - $300",
        "$501 - $1,000",
        "$1,001 - $3,000",
        "$3,001 - $5,000",
        "$5,001 - $9,999",
        "Greater than $9,999",
        "Unknown",
    ],
    premium_income_household: [
        "<$15K",
        "$15K-$20K",
        "$20K-$30K",
        "$30K-$40K",
        "$40K-$50K",
        "$50K-$60K",
        "$60K-$70K",
        "$70K-$80K",
        "$80K-$90K",
        "$90K-$100K",
        "$100K-$110K",
        "$110K-$120K",
        "$120K-$130K",
        "$130K-$140K",
        "$140K-$150K",
        "$150K-$175K",
        "$175K-$200K",
        "$200K-$225K",
        "$225K-$250K",
        "$250K-$275K",
        "$275K-$300K",
        "$300K-$400K",
        "$400K-$500K",
        "$500K-$600K",
        "$600K-$750K",
        "$750K-$1000K",
        "$1000K-$2000K",
        "$2000K+",
        "Unknown",
    ],
    urbanicity: [
        "Suburban",
        "Rural",
        "Urban"
    ]
}

export default checkBoxDict
