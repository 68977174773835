import React, { useState, useEffect } from 'react';
import Popup from 'reactjs-popup';
import EditNoteIcon from '@mui/icons-material/EditNote';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';

import axios from 'axios';

function CompanySegEdit(props) {
    const token = localStorage.getItem("token")
    const [segmentData, setSegmentData] = useState({
        segmentName: props.item?.segment_name || '',
    });

    const navigate = useNavigate()

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setSegmentData({ ...segmentData, [name]: value });
    };

    const handleDoneClick = (close, segmentID ) => {
        // Prepare the data to send to the API
        const updatedSegmentData = {
            segment_id: segmentID, // Replace with the correct key for the company ID
            new_segment_data: {
                segment_name: segmentData.segmentName,
            },
        };

        axios
            .post('/api/update-segment', updatedSegmentData, {
                headers: {
                    Authorization: token, // Replace with your JWT token
                },
            })
            .then((response) => {
                // Handle a successful response, e.g., show a success message or update the UI
                // You can also close the popup here
                console.log('Company updated successfully');
                close();
            })
            .catch((error) => {
                // Handle errors, e.g., show an error message
                console.error('Error updating company:', error);
            });

        console.log(updatedSegmentData)
        
        navigate(0)
        close()
    }

    const handleDeleteClick = (close , segmentID) => {
        axios
            .delete('/api/delete-segment', {
                params: {
                    segment_id: segmentID
                }
            })
            .then((response) => {
                // Handle a successful response, e.g., show a success message or update the UI
                // You can also close the popup here
                console.log('Segment deleted successfully');
                close();
            })
            .catch((error) => {
                // Handle errors, e.g., show an error message
                console.error('Error deleting segment:', error);
            });
        localStorage.setItem(props.companyID, JSON.stringify({
            ...JSON.parse(localStorage.getItem(props.companyID)),
            segment_list : []
        }))
        navigate(0)
        close()
    }

    useEffect(() => {
        // Update the companyData state once props are available
        setSegmentData({
            segmentID: props.item?.segment_id || '',
            segmentName: props.item?.segment_name || '',
        });
    }, [props.item]);



  return (
    <div>
    <Popup
        contentStyle={{ width: '300px', borderRadius: '5px', paddingTop: '10px', padding: '20px' }}
        trigger={
            <Tooltip title="Edit Segment">
                <IconButton className="segment-edit-display" sx={{ backgroundColor: 'white', borderRadius: '50px', padding: '11px' }}>
                    <EditNoteIcon style={{ color: 'gray' }} />
                </IconButton>
            </Tooltip>
        }
        modal
        nested
    >
        {(close) => (
            <div className="segment-edit-popup" style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                <p>Segment Details</p>
                <TextField
                    type="text"
                    label="Segment Name"
                    variant="outlined"
                    name="segmentName"
                    value={segmentData.segmentName}
                    onChange={handleInputChange}
                />
                <Button className="pop-up-apply" onClick={() => handleDoneClick(close, segmentData.segmentID)} sx={{float: 'right' }} variant="contained">Done</Button>
                <Button className="pop-up-apply" onClick={() => handleDeleteClick(close, segmentData.segmentID)} sx={{float: 'right' }} color='error' variant="contained">Delete</Button>

            </div>
        )}
    </Popup>
</div>
  )
}

export default CompanySegEdit
