import React, { useState, useEffect } from 'react';
import { useOutletContext, Link, useNavigate, Outlet } from "react-router-dom";
import TopBar from '../CompanyDashboardComponents/TopBar';
import OptionSelect from '../PaymentCenterComponents/OptionSelect';
import PlanSelect from '../PaymentCenterComponents/PlanSelect';
import { useParams } from 'react-router-dom';
import axios from 'axios';

function DashboardPaymentCenter() {

    const [token, setToken_] = useState(localStorage.getItem("token"));
    const [ transactionList, setTransactionList ] = useState([])
    const [option, setOption] = React.useState('plan');
    const [planDetails, setPlanDetails] = useState({
        data_plan: {
            plan: "email",
            plan_tier: 0,
            total_credits: 0
        },
        payment: {
            payment_method: "",
            payment_type: ""
        }
    })
    const [ prevPlanDetails , setPrevPlanDetails ] = useState({
        data_plan: {
            plan: "email",
            plan_tier: 0,
            total_credits: 0
        },
        payment: {
            payment_method: "",
            payment_type: ""
        }
    })
    const [paymenyDisabled, setPaymentDisabled ] = useState(true)

    const [additionalBoxText, setAdditionalBoxText] = useState(`Tier ${planDetails.data_plan && planDetails.data_plan.plan_tier} Selected`);


    const companyID = useParams().companyId


    const checkCompanyPlanStatus = async () => {
        try {
            const response = await axios.post(`/api/check-company-plan-status`, {
                company_id: companyID
            }, {
                headers: {
                    'Authorization' : token
                }
            });
            if (response.status === 200) {
                const result = response.data;
                setPlanDetails(result)
                setPrevPlanDetails(result)
                setAdditionalBoxText(`Tier ${result.data_plan["plan_tier"]} Selected`);
                console.log(result)

            } else {
                console.error('Access denied:', response.statusText);
            }
        } catch (error) {
            console.log(error)
        }
    };

    const getTransactionHistory = async () => {
        try {
            const response = await axios.post(`/api/get-transaction-history`, {
                company_id: companyID
            });
            if (response.status === 200) {
                const result = response.data;
                setTransactionList(result.transactions)
                console.log(result)

            } else {
                console.error('Access denied:', response.statusText);
            }
        } catch (error) {
            console.log(error)
        }
    };

    const handleChange = (event, newAlignment) => {
        setOption(newAlignment);
    };

    const handleTierChange = (event, newAlignment) => {
        setPlanDetails((prevState) => ({
            ...prevState,
            data_plan: {
                ...prevState.data_plan,
                plan_tier: newAlignment
            }
        }))
        // Set additional box text based on the selected tier
        setAdditionalBoxText(`Tier ${newAlignment} Selected`);
    };

    const handlePlanChange = (event, newAlignment) => {
        setPlanDetails((prevState) => ({
            ...prevState,
            data_plan: {
                ...prevState.data_plan,
                plan: newAlignment,
                plan_tier: 0
            }
        }))
    }

    useEffect(() => {
        checkCompanyPlanStatus()
        getTransactionHistory()
    }, [])

    useEffect(() => {
        if (planDetails.data_plan.plan === prevPlanDetails.data_plan.plan && planDetails.data_plan.plan_tier === prevPlanDetails.data_plan.plan_tier || planDetails.data_plan.plan_tier == 0) {
            setPaymentDisabled(true)
        } else if (planDetails.data_plan.plan && planDetails.data_plan.plan_tier ) {
            setPaymentDisabled(false)
        }
        console.log(planDetails)
    }, [planDetails, prevPlanDetails, option])


    return (
        <div style={{
            height: '100vh', backgroundColor: '#dcf2f6',
            display: 'flex',
            flexDirection: 'column',
        }}>
            <div style={{ position: 'sticky', top: "0", zIndex: 2 }}>
                <TopBar companyName={'Payment Center'} backAmount={'..'} />
            </div>
            <div style={{ display: 'flex', gap: '20px', marginTop: '30px', backgroundColor: '#dcf2f6', padding: '15px', width: '95%', alignSelf: 'center', borderRadius: '10px', justifyContent: 'center' }}>
                <OptionSelect transactionList={transactionList} option={option} handleChange={handleChange} planDetails={planDetails}/>
                <PlanSelect paymenyDisabled={paymenyDisabled} handlePlanChange={handlePlanChange} handleTierChange={handleTierChange}additionalBoxText={additionalBoxText} planDetails={planDetails}/>
            </div>
        </div>

    )
}

export default DashboardPaymentCenter
