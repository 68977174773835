import React, { useMemo, useState } from 'react'
import { MaterialReactTable } from 'material-react-table';


function CompanySegNewPeopleTable(props) {
  const pagination = props.pagination
  const setPagination = props.setPagination
  const showSkeletons = props.showSkeletons



  const columnNameList = ['date', 'first_name', 'last_name', 'url', 'facebook', 'linked_in', 'twitter', 'email', 'opt_in', 'opt_in_date', 'opt_in_ip', 'opt_in_url', 'pixel_first_hit_date', 'pixel_last_hit_date', 'bebacks', 'phone', 'dnc', 'age', 'gender', 'marital_status', 'address', 'city', 'state', 'zip', 'household_income', 'net_worth', 'income_levels', 'people_in_household', 'adults_in_household', 'children_in_household', 'veterans_in_household', 'education', 'credit_range', 'ethnic_group', 'generation', 'home_owner', 'occupation_detail', 'political_party', 'religion', 'children_between_ages0_3', 'children_between_ages4_6', 'children_between_ages7_9', 'children_between_ages10_12', 'children_between_ages13_18', 'behaviors', 'children_age_ranges', 'interests', 'owns_amex_card', 'owns_bank_card', 'dwelling_type', 'home_heat_type', 'home_price', 'home_purchased_years_ago', 'home_value', 'household_net_worth', 'language', 'mortgage_age', 'mortgage_amount', 'mortgage_loan_type', 'mortgage_refinance_age', 'mortgage_refinance_amount', 'mortgage_refinance_type', 'is_multilingual', 'new_credit_offered_household', 'number_of_vehicles_in_household', 'owns_investment', 'owns_premium_amex_card', 'owns_premium_card', 'owns_stocks_and_bonds', 'personality', 'is_political_contributor', 'is_voter', 'premium_income_household', 'urbanicity', 'maid', 'maid_os', 'hour']

  const columns = useMemo(() =>
    columnNameList.map((columnName) => ({
      accessorKey: columnName,
      header: columnName === 'first_name' ? 'First Name' : columnName === 'last_name' ? 'Last Name' : columnName,
  }))  ,[]);

  return (
    <div>
      <MaterialReactTable
        columns={columns}
        data={props.filteredData}
        enableGlobalFilter={false}
        enableFullScreenToggle={false}
        enableDensityToggle={false}
        enableTopToolbar={false}
        enableColumnActions={false}
        enableSorting={false}
        initialState={{ density: 'compact' }}
        manualPagination
        rowCount={100}
        muiPaginationProps={{ showFirstButton: false , showLastButton: false, showRowsPerPage: false, rowsPerPageOptions: false}}
        onPaginationChange={setPagination} //hoist pagination state to your state when it changes internally
        state={{ 
          pagination,
          showSkeletons,
          isLoading: showSkeletons,
         }}
         muiTableBodyProps={{
          sx: {
            //border: '2px solid black',
          },
        }}
        muiTopToolbarProps={{
          sx: {
            margin: 2,
            borderRadius: '5px',
            '.MuiCollapse-vertical' : {
              display: 'none'
            },
            '.MuiBox-root' : {}
          },
        }}
        muiTablePaginationProps={{
          showFirstButton: false,
          showLastButton: false,
          sx: {
            '.MuiInputBase-root': {
              display: 'none'
            },
            '.MuiTablePagination-selectLabel': {
              display:'none'
            },
            '.MuiTablePagination-displayedRows': {
              display: 'none'
            },
            '.MuiTablePagination-toolbar': {
              // width: '1255px',
              // color: 'rgb(41, 39, 39)',
              // height: '35px',
              // paddingRight: '1430px',
              // paddingTop: '15px',
              // gap: '430px'
            },
          }
        }}

      />
    </div>
  )
}

export default CompanySegNewPeopleTable
