import { useRef, useState, useEffect } from 'react';
import React from 'react';
import PeopleTable from '../CompanyDashboardComponents/PeopleTable';
import TopBar from '../CompanyDashboardComponents/TopBar';
import 'bootstrap/dist/css/bootstrap.css';
import { useOutletContext, Link, useParams } from "react-router-dom";
import DateRange from '../CompanyDashboardComponents/DateRange';
import axios from 'axios';
import ExportButton from '../CompanyDashboardComponents/ExportButton';


const GET_ALL_USERS_API_URL = '/api/get-data';
const DOWNLOAD_ALL_USERS_API_URL = '/api/download-users'

function CompanySegData() {
    const segmentIndex = localStorage.getItem('segmentIndex')
    const [userDetails, setUserDetails] = useState([]);
    const [filteredUserDetails, setFilteredUserDetails] = useState([]);
    const [searchTerm, setSearchTerm] = useState('false')
    const [dashboardDetails, setDashboardDetails] = useOutletContext()
    const [ filters , setFilters ] = useState(JSON.parse(localStorage.getItem(dashboardDetails.company_id)).segment_list[segmentIndex].filters)
    console.log(filters)
    const [pagination, setPagination] = useState({
      pageIndex: 0,
      pageSize: 13, //customize the default page size
    });
    const segmentID = useParams().segmentID


  return (
    <div className="company-data" style={{ height: '100vh'}}>
    <div className='data-page' style={{ display: 'flex', flexDirection: 'column'}}>
      <div className='top-bar' style={{ position: 'sticky', top: "0", zIndex: 2 }} >
                      <TopBar
                      className='search-bar'
                      elements = {
                      <div style={{ display: 'flex'}}>
                      
                      <DateRange 
                      currentStartDate = {dashboardDetails.start_date}
                      currentEndDate = {dashboardDetails.end_date} 
                      setDashboardDetails = {setDashboardDetails}
                      />
                      </div>
                    }
                      companyName = {JSON.parse(localStorage.getItem('segment')).segmentName}
                      backAmount = {'..'}
                      />
                  </div>
                  <div className='table' style={{ zIndex: '0', width: '80vw', marginTop: '1.3%', marginLeft: '3.2%'}}>
                    <PeopleTable
                    className='people-tabl' 
                    API_URL={GET_ALL_USERS_API_URL}
                    setData={setUserDetails}
                    setFilteredData={setFilteredUserDetails}
                    filteredData={filteredUserDetails}
                    companyID={dashboardDetails.company_id}
                    pageIndex={pagination.pageIndex}
                    pageSize={pagination.pageSize}
                    pagination={pagination}
                    setPagination={setPagination}
                    searchTerm={searchTerm}
                    currentStartDate = {dashboardDetails.start_date}
                    currentEndDate = {dashboardDetails.end_date}
                    filters={filters}
                    exportBtn={<ExportButton 
                      API_URL={DOWNLOAD_ALL_USERS_API_URL}
                      companyID={dashboardDetails.company_id}
                      currentStartDate = {dashboardDetails.start_date}
                      currentEndDate = {dashboardDetails.end_date} 
                      filters={filters}
                      segmentName={localStorage.getItem('segmentName')}
                      />}
                    />
                  </div>
                </div>
              </div>
  )
}

export default CompanySegData
