import React from 'react'
import { useState } from 'react';
import ReactLoading from 'react-loading';
import axios from 'axios';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useParams } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';




function ExportButton(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const segmentID = useParams().segmentID


  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };


  const [isLoading, setIsLoading] = useState(false)



  const handleDownload = async (download_type) => {
    setIsLoading(true);


    try {
      const companyName = localStorage.getItem('companyName')
      const requestBody = {
        id: props.companyID,
        start_date: props.currentStartDate,
        end_date: props.currentEndDate,
        download_type: download_type,
        segment_id: segmentID,
        segment_name: props.segmentName,
        company_name: companyName

      };
      const response = await axios.post(props.API_URL, requestBody, {
        responseType: 'text',  // Change the responseType to 'text'
      });

      console.log(response);
      let fileName = null
      if (props.segmentName != undefined) {
        fileName = `${companyName}-${props.segmentName}_segment-${download_type}.csv`
      } else {
        fileName = `${companyName}-${download_type}.csv`
      }
      const csvData = response.data; // Text data, not a blob
      const blob = new Blob([csvData], { type: 'text' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);


      setIsLoading(false);
    } catch (error) {
      console.error('An error occurred:', error);
      setIsLoading(false);
    }
  };

  const handleClose = (event) => {
    // const response = axios.get('/api/test').then((response) => console.log(response.data))
    // console.log(response.data)
    // console.log(event.target.textContent)
    let download_type = 'users'
    if (event.target.textContent == 'User Data'){
      download_type = 'users'
    } else {
      download_type = 'journey'
    }
    handleDownload(download_type)
    setAnchorEl(null);
  };


  return (
    <div>
      <div>
      <div>
            <LoadingButton
              id="basic-button"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
              variant='contained'
              loading={isLoading}
              sx={{
                backgroundColor: '#30acff'
              }}
            >
              Export
            </LoadingButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem defaultValue={'journey'}onClick={handleClose}>Journey Data</MenuItem>
              <MenuItem onClick={handleClose}>User Data</MenuItem>
            </Menu>
          </div>
      </div>
    </div>
  )
}

export default ExportButton
