import React, { useState, useEffect } from 'react';
import { useOutletContext, Link, useNavigate, Outlet } from "react-router-dom";
import TopBar from '../CompanyDashboardComponents/TopBar';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import EmailIcon from '@mui/icons-material/Email';
import BackupTableIcon from '@mui/icons-material/BackupTable';
import LoadingButton from '@mui/lab/LoadingButton';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Switch from '@mui/material/Switch';
import PaymentJson from '../PaymentPlans.json'

function PlanSelect(props) {

    const [token, setToken_] = useState(localStorage.getItem("token"));
    const [ paymentLoading , setPaymentLoading] = useState(false)
    const companyID = useParams().companyId

    console.log(PaymentJson)

    const createCheckoutSession = async () => {
        try {
            setPaymentLoading(true)
            const response = await axios.post(`/api/create-checkout-session`, {
                company_id: companyID,
                plan_details: props.planDetails
            }, {
                headers: {
                    "Authorization" : token
                }
            });
            if (response.status === 200) {
                const result = response.data;
                window.location.href = result.url
                console.log(result.data)

            } else {
                console.error('Access denied:', response.statusText);
            }
        } catch (error) {
            console.log(error)
        } finally {
            setPaymentLoading(false)
        }
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '20px', backgroundColor: 'white', padding: '15px', width: '1000px', alignSelf: 'center', borderRadius: '10px', border: '4px solid white', }}>
            <ToggleButtonGroup
                color="primary"
                value={props.planDetails.data_plan["plan"]}
                exclusive
                onChange={props.handlePlanChange}
                sx={{
                }}
            >
                <ToggleButton value="email" sx={{ gap: '10px' }}>
                    <EmailIcon />
                    Email Focused
                </ToggleButton>
                <ToggleButton value="extended" sx={{ gap: '10px' }}>
                    Extended Data
                    <BackupTableIcon />
                </ToggleButton>
            </ToggleButtonGroup>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
            }}>
                <div style={{ display: 'flex', justifyItems: 'center', flexDirection: 'column' }}>
                    <div style={{ width: '170px', height: '50px', backgroundColor: 'transparent'}}>
                    </div>
                    <div style={{ width: '170px', fontWeight: '550', fontSize: '12px', height: '50px', backgroundColor: 'gray', border: '1px solid #dcf2f6', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center', textTransform: 'none' }}>
                        Est. Monthly Website Visits
                    </div>
                    <div style={{ width: '170px', fontWeight: '550', fontSize: '12px', height: '50px', backgroundColor: 'gray', border: '1px solid #dcf2f6', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center', textTransform: 'none' }}>
                        Est. ID Resoultion rate
                    </div>
                    <div style={{ width: '170px', fontWeight: '550', fontSize: '12px', height: '50px', backgroundColor: 'gray', border: '1px solid #dcf2f6', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center', textTransform: 'none' }}>
                        Monthly Records (Up To)
                    </div>
                    <div style={{ width: '170px', fontWeight: '550', fontSize: '12px', height: '50px', backgroundColor: 'gray', border: '1px solid #dcf2f6', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center', textTransform: 'none' }}>
                        Annual Records
                    </div>
                    <div style={{ width: '170px', fontWeight: '550', fontSize: '12px', height: '50px', backgroundColor: 'gray', border: '1px solid #dcf2f6', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center', textTransform: 'none' }}>
                        Price Per Records
                    </div>
                    <div style={{ width: '170px', fontWeight: '550', fontSize: '12px', height: '50px', backgroundColor: 'gray', border: '1px solid #dcf2f6', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center', textTransform: 'none' }}>
                        Annual Investment
                    </div>
                </div>
                <ToggleButtonGroup
                    color="primary"
                    value={props.planDetails.data_plan["plan_tier"]}
                    exclusive
                    onChange={props.handleTierChange}
                    aria-label="Platform"
                >
                    {PaymentJson[props.planDetails.data_plan["plan"]].map((item, index) => (
                        <ToggleButton key={index} value={item.tier} sx={{ padding: '0px' }}>
                        <div style={{ display: 'flex', justifyItems: 'center', flexDirection: 'column' }}>
                            <div style={{ width: '125px', fontWeight: '550', fontSize: '18px', height: '50px', backgroundColor: item.color , border: `1px solid ${item.color}`, color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center', textTransform: 'none' }}>
                                Tier {item.tier}
                            </div>
                            <div style={{ width: '125px', fontWeight: '550', height: '50px', backgroundColor: 'white', border: '1px solid gainsboro', display: 'flex', alignItems: 'center', justifyContent: 'center', textTransform: 'none' }}>
                                {item.visits}
                            </div>
                            <div style={{ width: '125px', fontWeight: '550', height: '50px', backgroundColor: 'white', border: '1px solid gainsboro', display: 'flex', alignItems: 'center', justifyContent: 'center', textTransform: 'none' }}>
                                {item.res_rate}
                            </div>
                            <div style={{ width: '125px', fontWeight: '550', height: '50px', backgroundColor: 'white', border: '1px solid gainsboro', display: 'flex', alignItems: 'center', justifyContent: 'center', textTransform: 'none' }}>
                                {item.monthly_records}
                            </div>
                            <div style={{ width: '125px', fontWeight: '550', height: '50px', backgroundColor: 'white', border: '1px solid gainsboro', display: 'flex', alignItems: 'center', justifyContent: 'center', textTransform: 'none' }}>
                                {item.annual_records}
                            </div>
                            <div style={{ width: '125px', fontWeight: '550', height: '50px', backgroundColor: 'white', border: '1px solid gainsboro', display: 'flex', alignItems: 'center', justifyContent: 'center', textTransform: 'none' }}>
                                {item.price_per_record}
                            </div>
                            <div style={{ width: '125px', fontWeight: '550', height: '50px', backgroundColor: 'white', border: '1px solid gainsboro', display: 'flex', alignItems: 'center', justifyContent: 'center', textTransform: 'none' }}>
                                {item.annual_investment}
                            </div>
                        </div>
                    </ToggleButton>
                    ))}
                </ToggleButtonGroup>
            </div>
            {props.planDetails.data_plan["plan_tier"] !== 0 && props.planDetails.data_plan["plan_tier"] !== null ? (
                <div style={{ width: '150px', height: '50px', color: 'gray', border: '0.5px solid gray', borderRadius: '5px', display: 'flex', alignItems: 'center', justifyContent: 'center', fontWeight: '500', }}>
                    {props.additionalBoxText}
                </div>
            ) : (
                <div style={{ width: '150px', height: '50px', color: 'gray', border: '0.5px solid gray', borderRadius: '5px', display: 'flex', alignItems: 'center', justifyContent: 'center', fontWeight: '500', }}>
                    No Tier Selected
                </div>
            )}
            <LoadingButton disabled={props.paymenyDisabled} loading={paymentLoading} onClick={createCheckoutSession} variant='contained' > Continue to payment</LoadingButton>
        </div>
    )
}

export default PlanSelect
