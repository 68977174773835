import React, { useEffect } from 'react'
import TopBar from '../CompanyDashboardComponents/TopBar'
import { useOutletContext, useNavigate, Link } from 'react-router-dom'
import { Stack } from '@mui/material'
import { useState } from 'react'
import Button from '@mui/material/Button';
import axios from 'axios'
import CompanySegEdit from '../CompanyDashboardComponents/CompanySegEdit'
import Skeleton from '@mui/material/Skeleton';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
import Fade from '@mui/material/Fade';
import StateContext from '../StateContext';
import { useContext } from 'react'



function SlideTransition(props) {
    return <Slide {...props} direction="up" />;
}

function CompanySeg() {


    const navigate = useNavigate()

    const [token, setToken_] = useState(localStorage.getItem("token"));
    const [companyDetails, setCompanyDetails] = useOutletContext()
    const [segmentList, setSegmentlist] = useState(companyDetails.segment_list)
    const [segmentExist, setSegmentExist] = useState(true)
    const [isLoading, setIsloading] = useState(false)
    const { snackBar, setSnackBar } = useContext(StateContext)

    const handleClick = () => {
        setSnackBar(true)
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackBar(false);
    };

    const getSegmentList = async () => {
        if (segmentList.length == 0) {
            setIsloading(true)
        }
        try {
            const url = '/api/get-segment-list';
            const params = {
                company_id: companyDetails.company_id,
            };

            const response = await axios.post(url, params, {
                headers: { Authorization: token }
            });
            const result = await response.data;
            console.log(result)
            if (result === 'Not Found') {
                setSegmentExist(false)
            } else {
                setSegmentExist(true)
                setSegmentlist(result)
                localStorage.setItem(companyDetails.company_id, JSON.stringify({
                    ...JSON.parse(localStorage.getItem(companyDetails.company_id)),
                    company_id: companyDetails.company_id,
                    segment_list: result
                }))
            }
            setIsloading(false)
        } catch (error) {
            console.error('An error occurred:', error);
        }
    };

    const handleSegmentClick = (item, index) => {
        localStorage.setItem('segmentIndex', index)
        localStorage.setItem('segment', JSON.stringify({
            segmentID: item.segment_id,
            segmentName: item.segment_name,
            filters: item.filters
        }))
    }


    useEffect(() => {
        if (localStorage.getItem('segment')) {
            navigate(0)
            localStorage.removeItem('segment')
        }
        getSegmentList()
    }, []);


    return (
        <div className='page' style={{ height: '100vh' }}>
            <div className='top-bar'>
                <TopBar
                    elements={
                        <Button
                            variant="contained"
                            onClick={() => navigate("new")}
                            sx={{
                                backgroundColor: '#34a8ff',
                                paddingLeft: '8px',
                                paddingRight: '11px',
                                paddingBottom: '5px'
                            }}>
                            Add new Segment
                        </Button>
                    }
                    companyName={companyDetails.company_name}
                    backAmount={'..'}
                />
            </div>
            {isLoading ?
                (<div className='container-seg' style={{
                    display: 'flex',
                    flexDirection: 'column',
                    float: 'right',
                    width: '96%',
                }}>
                    <div>
                        <Stack spacing={2}>
                            <Skeleton variant="rectangular" width={'99%'} height={50} />
                            <Skeleton variant="rectangular" width={'99%'} height={50} />
                            <Skeleton variant="rectangular" width={'99%'} height={50} />
                            <Skeleton variant="rectangular" width={'99%'} height={50} />

                        </Stack>
                    </div>
                </div>
                )
                :
                (!segmentExist ?
                    (<div className='empty'>
                        <div className='empty-container'>
                            <div className='empty-text' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '10%' }}>
                                <h4>
                                    No segments yet
                                </h4>
                                <h5 style={{ color: 'gray' }}>
                                    Click on the button above to create new segment
                                </h5>
                            </div>
                        </div>
                    </div>)
                    :
                    (<div className='container-seg' style={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                        <Stack spacing={3} sx={{width: '97%'}}>
                            {segmentList.map((item, index) => (
                                <div style={{
                                    display: 'flex',
                                    backgroundColor: 'white',
                                    borderRadius: '5px',
                                    paddingRight: '5px',
                                    alignItems: 'center',
                                    marginTop: '20px',
                                    padding: '5px'

                                }}>
                                    <Link
                                        to={item && item.segment_id}
                                        key={index}
                                        style={{
                                            textDecoration: 'none',
                                            color: 'black',
                                            display: 'flex',
                                            width: '100%',
                                            paddingRight: '20px',
                                            borderRadius: '5px',
                                            padding: '1px'
                                        }}
                                        onClick={() => handleSegmentClick(item, index)}
                                    >
                                        <div className='segment-item' key={index} style={{
                                            display: 'flex',
                                            backgroundColor: 'white',
                                            paddingLeft: '10px',
                                            padding: '10px',
                                            width: '100%',
                                            borderRadius: '5px',
                                        }}>
                                            <div className='title-seg' style={{
                                                width: '25%',
                                                display: 'flex',
                                                flexDirection: 'column'
                                            }}>
                                                <div style={{ fontSize: '12px', fontWeight: '600', color: 'gray'}}>
                                                    Segment Name
                                                </div>
                                                {item.segment_name}
                                            </div>
                                            <div className='peoples-seg' style={{
                                                marginLeft: '25%',
                                                display: 'flex',
                                                flexDirection: 'column'
                                            }}>
                                                <div style={{ fontSize: '12px', fontWeight: '600', color: 'gray'}}>
                                                Users Captured
                                                </div>
                                                {item.people_count}
                                            </div>
                                            <div className='created-by-seg' style={{
                                                marginLeft: '25%',
                                                display: 'flex',
                                                flexDirection: 'column'
                                            }}>
                                                <div style={{ fontSize: '12px', fontWeight: '600', color: 'gray'}}>
                                                    Created By
                                                </div>
                                                {item.created_by}
                                            </div>

                                        </div>
                                    </Link>
                                    <div>
                                        <CompanySegEdit
                                            item={item}
                                            companyID={companyDetails.company_id}
                                        />
                                    </div>
                                </div>

                            ))}
                        </Stack>
                    </div>)
                )}
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={snackBar}
                onClose={handleClose}
                TransitionComponent={snackBar.Transition}
                message="Segment Created"
                autoHideDuration={3000}
            />
        </div>
    )
}

export default CompanySeg
