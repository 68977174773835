import React, { useState, useEffect,useReducer } from 'react'
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Chart from "react-apexcharts";
import axios from 'axios';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';

function CompareByPercent(props) {

    const [objName, setObjName] = useState(props.obj_name)
    const [ percentList , setPercentList ] = useState(props.percent_change)
    const [ state, setState ] = useState({
        series: props.item_count_list,
        options: {
          plotOptions: {
            pie: {
              dataLabels: {
                minAngleToShowLabel: 15
            },
            }
          },
            chart: {
              width: 380,
              type: 'pie',
            },
            theme: {
                monochrome: {
                  enabled: true
                }
            },
            legend: {
                show: false,
              },
            dataLabels: {
                formatter(val, opts) {
                  const name = opts.w.globals.labels[opts.seriesIndex]
                  return [name, val.toFixed(1) + '%']
                }
            },
            labels: props.item_list,
            responsive: [{
              breakpoint: 480,
              options: {
                chart: {
                  width: 200
                },
              }
            }]
          },
        
    })

useEffect(() => {
    setState((prevState) => {
        return {
            ...prevState,
            options: {
                ...prevState.options,
                labels: props.item_list,
            },
            series: props.item_count_list
        };
    });
}, [props.item_list, props.item_count_list, props.percent_change]);



    return (
        <div className='by-precent-details' style={{ width: '610px',height: '300px', paddingBottom: '20px', backgroundColor: 'white', borderRadius: '10px', paddingLeft: '15px'}}>
            <div className='title' style={{ paddingTop: '10px', fontWeight: '500'}} >{objName}</div>
            <div style={{ display: 'flex', gap:'30px'}}>
            <div className='pie-chart-by-precent' style={{ marginTop: '5px', height: '220px'}}>
                <Chart 
                options={state.options} 
                series={state.series}
                type="pie"
                width='100%'
                height='120%'
                />
            </div>
            <div className='pie-chart-legand' style={{ marginTop: '10%', display: 'flex', gap:'40px'}}>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '5px'}}>
                {props.item_list.map((item,index) => (
                    <div style={{ display: 'flex', gap: '5px'}}>
                        <div style={{fontSize: '15px', fontWeight: '500', whiteSpace: 'nowrap'}}>
                        {item} 
                        </div> 
                    </div>
                ))}
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '5px'}} >
                {props.item_list.map((item,index) => (
                    <div>
                    {props.percent_change[index] && props.percent_change[index].includes('-') ? 
                    (
                        <div style={{ display: 'flex',alignSelf: 'center',fontSize: '13px', justifyContent:'space-between', backgroundColor: '#FBADAE', color: '#9A0507', textAlign: 'center', borderRadius: '10px' }}>
                        <div style={{ marginLeft: '4px'}}>
                            {props.percent_change[index]}
                        </div>
                        <ArrowDownwardIcon sx={{ transform: 'scale(0.65)', backgroundColor: '#9A0507', color: 'white', borderRadius: '100px'}} />
                    </div>
                    ) 
                    : props.percent_change[index] == undefined ? (
                        <div style={{ display: 'flex',alignSelf: 'center', justifyContent:'space-between' ,fontSize: '13px', backgroundColor: 'gainsboro', color: 'gray', textAlign: 'center', borderRadius: '10px' }}>
                        <div style={{ marginLeft: '4px'}}>
                        {'0.00%'}
                        </div>
                        <HorizontalRuleIcon sx={{ transform: 'scale(0.65)', backgroundColor: 'gray', color: 'white', borderRadius: '100px'}} />
                    </div>
                    ) :
                    (
                        <div style={{ display: 'flex',alignSelf: 'center', justifyContent:'space-between' ,fontSize: '13px', backgroundColor: '#D9F9D9', color: '#06A606', textAlign: 'center', borderRadius: '10px' }}>
                        <div style={{ marginLeft: '10px'}}>
                        {props.percent_change[index]}
                        </div>
                        <ArrowUpwardIcon sx={{ transform: 'scale(0.65)', backgroundColor: '#06A606', color: 'white', borderRadius: '100px'}} />
                    </div>
                    )}
                </div>
                ))}
                </div>
            </div>
            </div>
        </div>
    )
}

export default CompareByPercent

