import React, { useState, useEffect } from 'react'
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { InputLabel, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import Popup from 'reactjs-popup';
import './CompanySegSideBarSyle.css'
import axios from 'axios';
import { useOutletContext } from 'react-router-dom';
import Contains from '../CompanySegPopups/Contains';
import Range from '../CompanySegPopups/Range';
import CheckboxSeg from '../CompanySegPopups/CheckboxSeg';
import LinkIcon from '@mui/icons-material/Link';
import checkBoxDict from './CheckBoxDictProp';
import DateRangeIcon from '@mui/icons-material/DateRange';
import WcIcon from '@mui/icons-material/Wc';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import PaymentsIcon from '@mui/icons-material/Payments';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import PaidIcon from '@mui/icons-material/Paid';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import Person3Icon from '@mui/icons-material/Person3';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import EscalatorWarningIcon from '@mui/icons-material/EscalatorWarning';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import Groups2Icon from '@mui/icons-material/Groups2';
import SchoolIcon from '@mui/icons-material/School';
import LanIcon from '@mui/icons-material/Lan';
import GiteIcon from '@mui/icons-material/Gite';
import SensorOccupiedIcon from '@mui/icons-material/SensorOccupied';
import BallotIcon from '@mui/icons-material/Ballot';
import SynagogueIcon from '@mui/icons-material/Synagogue';
import PsychologyAltIcon from '@mui/icons-material/PsychologyAlt';
import InterestsIcon from '@mui/icons-material/Interests';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import ChaletIcon from '@mui/icons-material/Chalet';
import TranslateIcon from '@mui/icons-material/Translate';
import EventNoteIcon from '@mui/icons-material/EventNote';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import MergeTypeIcon from '@mui/icons-material/MergeType';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ScaleIcon from '@mui/icons-material/Scale';
import BackupTableIcon from '@mui/icons-material/BackupTable';
import AddCardIcon from '@mui/icons-material/AddCard';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';

const GET_ALL_USERS_API_URL = '/api/get-data';


function CompanySegSideBar(props) {

    const [ contains, setContains] = useState({
        include_name: '',
        include_array: [{}],
        exclude_name: '',
        exclude_array: [{}],
    })
    const [ exclude , setExclude ] = useState()
    const [checkBox, setCheckBox] = useState({
        name: '',
        value: []
    });
    const [companyDetails, setCompanyDetails] = useOutletContext()
    const columnFilters = props.columnFilters
    const setColumnFilters= props.setColumnFilters
    const [range, setRange] = useState({
        name: '',
        minVal: '',
        maxVal: ''
    })
    const [sorting, setSorting] = useState([]);



    const getUsers = async () => {
        try {
            const url = GET_ALL_USERS_API_URL;
            const params = {
                id: companyDetails.company_id,
                search: '',
                'start-date': undefined,
                'end-date': undefined,
                page: props.pagination.pageIndex,
                filters: ['segnew',columnFilters],
                sorting: sorting,
                excluded_users: JSON.parse(localStorage.getItem('excluded_users'))
            };

            const response = await axios.post(url, params);
            const result = await response.data;
            if (result.excluded_users) {
                localStorage.setItem('excluded_users', JSON.stringify(result.excluded_users))
            }
            props.setFilteredData(result);
            props.setShowSkeletons(false);

        } catch (error) {
            console.error('An error occurred:', error);
        }
    };


    useEffect(() => {
        props.setShowSkeletons(true)
        getUsers()
        console.log(columnFilters)

    }, [props.pagination,props.companyID,columnFilters]);


    return (
        <div style={{ direction: 'rtl', position: 'sticky', overflowX: 'hidden' }}>
            <Sidebar rtl={true} width='95%' rootStyles={{ backgroundColor: 'white' ,height: '93vh', overflowY: 'auto' }}>
                <Menu rootStyles={{ fontSize: '14px', fontWeight: '500', padding: '5px' }}>
                    <div className={'sub-menu-item'}>
                        <Contains
                            menuItemName={'URL'}
                            label={'URL'}
                            name={'url'}
                            contains={contains}
                            setContains={setContains}
                            exclude={exclude}
                            setExclude={setExclude}
                            setColumnFilters={setColumnFilters}
                            icon={<LinkIcon />}
                        />
                    </div>
                    <div className={'sub-menu-item'}>
                        <Range
                            menuItemName={'Age'}
                            startLabel={'Start Age'}
                            endLabel={'End Age'}
                            name={'age'}
                            range={range}
                            setRange={setRange}
                            setColumnFilters={setColumnFilters}
                            icon={<DateRangeIcon/>}
                        />
                    </div>
                    <div className={'sub-menu-item'}>
                        <CheckboxSeg
                            menuItemName={'Gender'}
                            name={'gender'}
                            checkBox={checkBox}
                            setCheckBox={setCheckBox}
                            setColumnFilters={setColumnFilters}
                            columnFilters={columnFilters}
                            items={checkBoxDict.genders}
                            icon={<WcIcon/>}
                        />
                    </div>
                    <div className={'sub-menu-item'}>
                        <CheckboxSeg
                            menuItemName={'Marital Status'}
                            name={'marital_status'}
                            checkBox={checkBox}
                            setCheckBox={setCheckBox}
                            setColumnFilters={setColumnFilters}
                            columnFilters={columnFilters}
                            items={checkBoxDict.marrital_status}
                            icon={<VolunteerActivismIcon />}
                        />
                    </div>
                    <div className={'sub-menu-item'}>
                        <CheckboxSeg
                            menuItemName={'Household Income'}
                            name={'household_income'}
                            checkBox={checkBox}
                            setCheckBox={setCheckBox}
                            setColumnFilters={setColumnFilters}
                            columnFilters={columnFilters}
                            items={checkBoxDict.household_income}
                            icon={<PaymentsIcon/>}
                        />
                    </div>
                    <div className={'sub-menu-item'}>
                        <CheckboxSeg
                            menuItemName={'Income Level'}
                            name={'income_levels'}
                            checkBox={checkBox}
                            setCheckBox={setCheckBox}
                            setColumnFilters={setColumnFilters}
                            columnFilters={columnFilters}
                            items={checkBoxDict.income_levels}
                            icon={<SignalCellularAltIcon/>}
                        />
                    </div>
                    <div className={'sub-menu-item'}>
                        <CheckboxSeg
                            menuItemName={'Net Worth'}
                            name={'net_worth'}
                            checkBox={checkBox}
                            setCheckBox={setCheckBox}
                            setColumnFilters={setColumnFilters}
                            columnFilters={columnFilters}
                            items={checkBoxDict.net_worth}
                            icon={<PaidIcon />}
                        />
                    </div>
                    <div className={'sub-menu-item'}>
                        <Range
                            menuItemName={'People In Household'}
                            startLabel={'Start'}
                            endLabel={'End'}
                            name={'people_in_household'}
                            range={range}
                            setRange={setRange}
                            setColumnFilters={setColumnFilters}
                            icon={<PeopleAltIcon />}
                        />
                    </div>
                    <div className={'sub-menu-item'}>
                        <Range
                            menuItemName={'Adults In Household'}
                            startLabel={'Start'}
                            endLabel={'End'}
                            name={'adults_in_household'}
                            range={range}
                            setRange={setRange}
                            setColumnFilters={setColumnFilters}
                            icon={<Person3Icon />}
                        />
                    </div>
                    <div className={'sub-menu-item'}>
                        <Range
                            menuItemName={'Children In Household'}
                            startLabel={'Start'}
                            endLabel={'End'}
                            name={'children_in_household'}
                            range={range}
                            setRange={setRange}
                            setColumnFilters={setColumnFilters}
                            icon={<EscalatorWarningIcon/>}
                        />
                    </div>
                    <div className={'sub-menu-item'}>
                        <Range
                            menuItemName={'Veterans In Household'}
                            startLabel={'Start'}
                            endLabel={'End'}
                            name={'veterans_in_household'}
                            range={range}
                            setRange={setRange}
                            setColumnFilters={setColumnFilters}
                            icon={<MilitaryTechIcon/>}
                        />
                    </div>
                    <div className={'sub-menu-item'}>
                        <CheckboxSeg
                            menuItemName={'Credit Range'}
                            name={'credit_range'}
                            checkBox={checkBox}
                            setCheckBox={setCheckBox}
                            setColumnFilters={setColumnFilters}
                            columnFilters={columnFilters}
                            items={checkBoxDict.credit_range}
                            icon={<CreditScoreIcon/>}
                        />
                    </div>
                    <div className={'sub-menu-item'}>
                        <CheckboxSeg
                            menuItemName={'Ethnic Group'}
                            name={'ethnic_group'}
                            checkBox={checkBox}
                            setCheckBox={setCheckBox}
                            setColumnFilters={setColumnFilters}
                            columnFilters={columnFilters}
                            items={checkBoxDict.ethnic_group}
                            icon={<Groups2Icon/>}
                        />
                    </div>
                    <div className={'sub-menu-item'}>
                        <CheckboxSeg
                            menuItemName={'Education'}
                            name={'education'}
                            checkBox={checkBox}
                            setCheckBox={setCheckBox}
                            setColumnFilters={setColumnFilters}
                            columnFilters={columnFilters}
                            items={checkBoxDict.education}
                            icon={<SchoolIcon />}
                        />
                    </div>
                    <div className={'sub-menu-item'}>
                        <CheckboxSeg
                            menuItemName={'Generation'}
                            name={'generation'}
                            checkBox={checkBox}
                            setCheckBox={setCheckBox}
                            setColumnFilters={setColumnFilters}
                            columnFilters={columnFilters}
                            items={checkBoxDict.generation}
                            icon={<LanIcon />}
                        />
                    </div>
                    <div className={'sub-menu-item'}>
                        <CheckboxSeg
                            menuItemName={'Home Owner'}
                            name={'home_owner'}
                            checkBox={checkBox}
                            setCheckBox={setCheckBox}
                            setColumnFilters={setColumnFilters}
                            columnFilters={columnFilters}
                            items={checkBoxDict.home_owner}
                            icon={<GiteIcon />}
                        />
                    </div>
                    <div className={'sub-menu-item'}>
                        <CheckboxSeg
                            menuItemName={'Occupation Detail'}
                            name={'occupation_detail'}
                            checkBox={checkBox}
                            setCheckBox={setCheckBox}
                            setColumnFilters={setColumnFilters}
                            columnFilters={columnFilters}
                            items={checkBoxDict.occupation_detail}
                            icon={<SensorOccupiedIcon />}
                        />
                    </div>
                    <div className={'sub-menu-item'}>
                        <CheckboxSeg
                            menuItemName={'Political Party'}
                            name={'political_party'}
                            checkBox={checkBox}
                            setCheckBox={setCheckBox}
                            setColumnFilters={setColumnFilters}
                            columnFilters={columnFilters}
                            items={checkBoxDict.political_party}
                            icon={<BallotIcon />}
                        />
                    </div>
                    <div className={'sub-menu-item'}>
                        <CheckboxSeg
                            menuItemName={'Religion'}
                            name={'religion'}
                            checkBox={checkBox}
                            setCheckBox={setCheckBox}
                            setColumnFilters={setColumnFilters}
                            columnFilters={columnFilters}
                            items={checkBoxDict.religion}
                            icon={<SynagogueIcon />}
                        />
                    </div>
                    <div className={'sub-menu-item'}>
                        <CheckboxSeg
                            menuItemName={'Behaviors'}
                            name={'behaviors'}
                            checkBox={checkBox}
                            setCheckBox={setCheckBox}
                            setColumnFilters={setColumnFilters}
                            columnFilters={columnFilters}
                            items={checkBoxDict.behaviors}
                            icon={<PsychologyAltIcon />}
                        />
                    </div>
                    <div className={'sub-menu-item'}>
                        <CheckboxSeg
                            menuItemName={'Interests'}
                            name={'interests'}
                            checkBox={checkBox}
                            setCheckBox={setCheckBox}
                            setColumnFilters={setColumnFilters}
                            columnFilters={columnFilters}
                            items={checkBoxDict.interests}
                            icon={<InterestsIcon/>}
                        />
                    </div>
                    <div className={'sub-menu-item'}>
                        <CheckboxSeg
                            menuItemName={'Home Dwelling Type'}
                            name={'dwelling_type'}
                            checkBox={checkBox}
                            setCheckBox={setCheckBox}
                            setColumnFilters={setColumnFilters}
                            columnFilters={columnFilters}
                            items={checkBoxDict.dwelling_type}
                            icon={<HomeWorkIcon />}
                        />
                    </div>
                    <div className={'sub-menu-item'}>
                        <Range
                            menuItemName={'Home Price'}
                            startLabel={'Start'}
                            endLabel={'End'}
                            name={'home_price'}
                            range={range}
                            setRange={setRange}
                            setColumnFilters={setColumnFilters}
                            icon={<PriceChangeIcon />}
                        />
                    </div>
                    <div className={'sub-menu-item'}>
                        <Range
                            menuItemName={'Home Value'}
                            startLabel={'Start'}
                            endLabel={'End'}
                            name={'home_value'}
                            range={range}
                            setRange={setRange}
                            setColumnFilters={setColumnFilters}
                            icon={<ChaletIcon />}
                        />
                    </div>
                    <div className={'sub-menu-item'}>
                        <CheckboxSeg
                            menuItemName={'Language'}
                            name={'language'}
                            checkBox={checkBox}
                            setCheckBox={setCheckBox}
                            setColumnFilters={setColumnFilters}
                            columnFilters={columnFilters}
                            items={checkBoxDict.language}
                            icon={<TranslateIcon />}
                        />
                    </div>
                    <div className={'sub-menu-item'}>
                        <Range
                            menuItemName={'Mortgage Age'}
                            startLabel={'Start'}
                            endLabel={'End'}
                            name={'mortgage_age'}
                            range={range}
                            setRange={setRange}
                            setColumnFilters={setColumnFilters}
                            icon={<EventNoteIcon/>}
                        />
                    </div>
                    <div className={'sub-menu-item'}>
                        <Range
                            menuItemName={'Mortgage Amount'}
                            startLabel={'Start'}
                            endLabel={'End'}
                            name={'mortgage_amount'}
                            range={range}
                            setRange={setRange}
                            setColumnFilters={setColumnFilters}
                            icon={<PointOfSaleIcon />}
                        />
                    </div>
                    <div className={'sub-menu-item'}>
                        <CheckboxSeg
                            menuItemName={'Mortgage Loan Type'}
                            name={'mortgage_loan_type'}
                            checkBox={checkBox}
                            setCheckBox={setCheckBox}
                            setColumnFilters={setColumnFilters}
                            columnFilters={columnFilters}
                            items={checkBoxDict.mortgage_loan_type}
                            icon={<MergeTypeIcon />}
                        />
                    </div>
                    <div className={'sub-menu-item'}>
                        <Range
                            menuItemName={'Mortgage Refinance Age'}
                            startLabel={'Start'}
                            endLabel={'End'}
                            name={'mortgage_refinance_age'}
                            range={range}
                            setRange={setRange}
                            setColumnFilters={setColumnFilters}
                            icon={<CalendarMonthIcon />}
                        />
                    </div>
                    <div className={'sub-menu-item'}>
                        <Range
                            menuItemName={'Mortgage Refinance Amount'}
                            startLabel={'Start'}
                            endLabel={'End'}
                            name={'mortgage_amount'}
                            range={range}
                            setRange={setRange}
                            setColumnFilters={setColumnFilters}
                            icon={<ScaleIcon />}
                        />
                    </div>
                    <div className={'sub-menu-item'}>
                        <CheckboxSeg
                            menuItemName={'Mortgage Refinance Type'}
                            name={'mortgage_refinance_type'}
                            checkBox={checkBox}
                            setCheckBox={setCheckBox}
                            setColumnFilters={setColumnFilters}
                            columnFilters={columnFilters}
                            items={checkBoxDict.mortgage_loan_type}
                            icon={<BackupTableIcon />}
                        />
                    </div>
                    <div className={'sub-menu-item'}>
                        <CheckboxSeg
                            menuItemName={'Credit Offered In Household'}
                            name={'new_credit_offered_household'}
                            checkBox={checkBox}
                            setCheckBox={setCheckBox}
                            setColumnFilters={setColumnFilters}
                            columnFilters={columnFilters}
                            items={checkBoxDict.new_credit_offered_household}
                            icon={<AddCardIcon />}
                        />
                    </div>
                    <div className={'sub-menu-item'}>
                        <Range
                            menuItemName={'Vehicles In Household'}
                            startLabel={'Start'}
                            endLabel={'End'}
                            name={'number_of_vehicles_in_household'}
                            range={range}
                            setRange={setRange}
                            setColumnFilters={setColumnFilters}
                            icon={<DriveEtaIcon />}
                        />
                    </div>
                    <div className={'sub-menu-item'}>
                        <CheckboxSeg
                            menuItemName={'Premium Income In Household'}
                            name={'premium_income_household'}
                            checkBox={checkBox}
                            setCheckBox={setCheckBox}
                            setColumnFilters={setColumnFilters}
                            columnFilters={columnFilters}
                            items={checkBoxDict.premium_income_household}
                            icon={<AccountBalanceIcon />}
                        />
                    </div>
                    <div className={'sub-menu-item'}>
                        <CheckboxSeg
                            menuItemName={'Urbanicity'}
                            name={'urbanicity'}
                            checkBox={checkBox}
                            setCheckBox={setCheckBox}
                            setColumnFilters={setColumnFilters}
                            columnFilters={columnFilters}
                            items={checkBoxDict.urbanicity}
                            icon={<CorporateFareIcon />}
                        />
                    </div>
                </Menu>
            </Sidebar>
            <div className='container'>
                {/* Your content here */}
            </div>
        </div>
    )
}

export default CompanySegSideBar
