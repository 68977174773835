import React, {useState, useEffect} from 'react'
import TopBar from '../CompanyDashboardComponents/TopBar'
import { Outlet, useOutletContext, } from 'react-router-dom'
import axios from 'axios'


function CompanyIntegrationRoot() {
    const [integrations, setIntegrations] = useState([])

    const getIntegInfo = async () => {
        try {
            const response = await axios.get(`/api/get-integration-info`);
            if (response.status === 200) {
                const result = response.data
                console.log(result)
                setIntegrations(result)
            } else {
                console.error('Access denied:', response.statusText);
            }
        } catch (error) {
            console.log(error)
        } 
    };

    useEffect(() => {
        getIntegInfo()
    }, [])


  return (
    <div className='body' style={{ height: '100vh'}}>
        <Outlet context={[integrations, setIntegrations]} />
    </div>
  )
}

export default CompanyIntegrationRoot
