import React from 'react'
import { useState} from 'react'
import { useNavigate } from 'react-router-dom'
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton'
import { Alert } from '@mui/material'
import './LoginRootStyle.css'
import axios from 'axios';
import TextField from '@mui/material'




function LoginRoot() {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [emailError, setEmailError] = useState("")
    const [passwordError, setPasswordError] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState(false)

    const navigate = useNavigate();

    const handleLogin = async () => {
      try {
        const response = await axios.post('/api/login', {
          email: email,
          password: password,
        });
    
        if (response.status === 200) {
          const data = response.data;
    
          // Handle the response here, e.g., save the token in local storage
          const token = data.token;
          localStorage.setItem('token', token);
    
          navigate(`/app/companies/`);
        } else {
          // Handle login failure, e.g., display an error message
          console.error('Login failed:', response.statusText);
          setIsError(true);
          setIsLoading(false);
        }
      } catch (error) {
        // Handle network or other errors
        console.error('An error occurred:', error);
        setIsError(true);
        setIsLoading(false);
      }
    };
        
    const onButtonClick = () => {
        setIsLoading(true)
        
        // Set initial error values to empty
        setEmailError("")
        setPasswordError("")

        // Check if the user has entered both fields correctly
        if ("" === email) {
            setEmailError("Please enter your email")
            setIsLoading(false)
            return
        }

        if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
            setEmailError("Please enter a valid email")
            setIsLoading(false)
            return
        }

        if ("" === password) {
            setPasswordError("Please enter a password")
            setIsLoading(false)
            return
        }


        handleLogin()
    }
    // useEffect(() => {
    //     console.log(email)
    //     console.log(password)
    //   }, [email, password]);
  return (
    <div className={"mainContainer"}>
        <div className={"titleContainer"}>
            <div>Login</div>
        </div>
        <br />
        <div className={"inputContainer"}>
            <input
                value={email}
                placeholder="Enter your email here"
                onChange={ev => setEmail(ev.target.value)}
                className={"inputBox"} />
            <label className="errorLabel">{emailError}</label>
        </div>
        <br />
        <div className={"inputContainer"}>
            <input
                type='password'
                value={password}
                placeholder="Enter your password here"
                onChange={ev => setPassword(ev.target.value)}
                className={"inputBox"} />
            <label className="errorLabel">{passwordError}</label>
        </div>
        <br />
        <div className={"inputContainer"}>
            <LoadingButton
                loading={isLoading}
                className={"inputButton"}
                type="button"
                onClick={onButtonClick}
                value={"Log in"} >
                  Log In
                </LoadingButton>
        </div>
        {isError ? (
          <Alert severity="error" sx={{ marginTop: '15px'}}>User Not Found</Alert>
        ) : (
          <div/>
        )}

    </div>
  )
}

export default LoginRoot
