import React, { useState, useEffect, memo, useMemo } from "react";
import {
    ComposableMap,
    Geographies,
    Geography,
    Marker,
    Annotation
} from "react-simple-maps";
import { scaleQuantile } from "d3-scale";
import axios from "axios";
import { geoCentroid } from "d3-geo";
import allStates from "../allStates.json"
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: 'whitesmoke',
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));



const geoUrl =
    "https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json";

const offsets = {
    VT: [50, -8],
    NH: [34, 2],
    MA: [30, -1],
    RI: [28, 2],
    CT: [35, 10],
    NJ: [34, 1],
    DE: [33, 0],
    MD: [47, 10],
    DC: [49, 21],
    FL: [15, 21]
};

const stateAbbreviations = {
    AL: "Alabama",
    AK: "Alaska",
    AZ: "Arizona",
    AR: "Arkansas",
    CA: "California",
    CO: "Colorado",
    CT: "Connecticut",
    DE: "Delaware",
    FL: "Florida",
    GA: "Georgia",
    HI: "Hawaii",
    ID: "Idaho",
    IL: "Illinois",
    IN: "Indiana",
    IA: "Iowa",
    KS: "Kansas",
    KY: "Kentucky",
    LA: "Louisiana",
    ME: "Maine",
    MD: "Maryland",
    MA: "Massachusetts",
    MI: "Michigan",
    MN: "Minnesota",
    MS: "Mississippi",
    MO: "Missouri",
    MT: "Montana",
    NE: "Nebraska",
    NV: "Nevada",
    NH: "New Hampshire",
    NJ: "New Jersey",
    NM: "New Mexico",
    NY: "New York",
    NC: "North Carolina",
    ND: "North Dakota",
    OH: "Ohio",
    OK: "Oklahoma",
    OR: "Oregon",
    PA: "Pennsylvania",
    RI: "Rhode Island",
    SC: "South Carolina",
    SD: "South Dakota",
    TN: "Tennessee",
    TX: "Texas",
    UT: "Utah",
    VT: "Vermont",
    VA: "Virginia",
    WA: "Washington",
    WV: "West Virginia",
    WI: "Wisconsin",
    WY: "Wyoming",
};

function MapChart({ dashboardDetails, setDashboardDetails, setTooltipContent, toolTipContent }) {
    const [popularStates, setPopularStates] = useState(dashboardDetails.popularStateChart)

    const [ popularCities, setPopularCities ] = useState({
        popular_array: [],
        popular_count_array: [],
    })

    const [token, setToken_] = useState(localStorage.getItem("token"));

    const getCityDetails = async (state) => {
        console.log(state)
        try {
            const response = await axios.post(`/api/get-company-popular`, {
                company_id: dashboardDetails.company_id,
                start_date: dashboardDetails.start_date,
                end_date: dashboardDetails.end_date,
                type: 'city',
                filters: (() => {
                    if (dashboardDetails.segment && dashboardDetails.segment.filters) {
                      return dashboardDetails.segment.filters;
                    } else {
                      return undefined;
                    }
                  })(),
                segment_id: (() => {
                    // Check if segment and filters properties are present
                    if (dashboardDetails.segment && dashboardDetails.segment.segmentID) {
                      return dashboardDetails.segment.segmentID;
                    } else {
                      return undefined;
                    }
                  })(), // Call the function to get the actual filters
                state: state
            }, {
                headers: { Authorization: token }
            });

            if (response.status === 200) {
                const result = await response.data;
                setPopularCities((prevState) => ({
                    ...prevState,
                    popular_array: result.popular_items,
                    popular_count_array: result.popular_items_counts,
                }));
                console.log(result);
            } else {
                console.error('Access denied:', response.statusText);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        const getPopular = async () => {
            console.log(dashboardDetails.companyID);

            try {
                const response = await axios.post(`/api/get-company-popular`, {
                    company_id: dashboardDetails.company_id,
                    start_date: dashboardDetails.start_date,
                    end_date: dashboardDetails.end_date,
                    filters: (() => {
                        if (dashboardDetails.segment && dashboardDetails.segment.filters) {
                          return dashboardDetails.segment.filters;
                        } else {
                          return undefined;
                        }
                      })(),
                      segment_id: (() => {
                        // Check if segment and filters properties are present
                        if (dashboardDetails.segment && dashboardDetails.segment.segmentID) {
                          return dashboardDetails.segment.segmentID;
                        } else {
                          return undefined;
                        }
                      })(),  // Call the function to get the actual filters
                    type: 'state',
                }, {
                    headers: { Authorization: token }
                });

                if (response.status === 200) {
                    const result = await response.data;
                    console.log(result);

                    setPopularStates((prevState) => ({
                        ...prevState,
                        popular_array: result.popular_items,
                        popular_count_array: result.popular_items_counts,
                    }));

                    setPopularCities({
                        popular_array: [],
                        popular_count_array: [],
                    })

                    localStorage.setItem(dashboardDetails.company_id, JSON.stringify({
                        ...JSON.parse(localStorage.getItem(dashboardDetails.company_id)),
                        popularStateChart: {
                            popular_array: result.popular_items,
                            popular_count_array: result.popular_items_counts,
                        }
                    }));

                    setDashboardDetails((prevState) => ({
                        ...prevState,
                        popularStateChart: {
                            popular_array: result.popular_items,
                            popular_count_array: result.popular_items_counts,
                        }
                    }));

                    console.log(result);
                } else {
                    console.error('Access denied:', response.statusText);
                }
            } catch (error) {
                console.log(error);
            }
        };

        getPopular();
    }, [dashboardDetails.start_date, dashboardDetails.end_date]);

    const colorScale = scaleQuantile()
        .domain(popularStates.popular_count_array)
        .range([
            "#72D0FF",
            "#51C1FF",
            "#3BB2FF",
            "#2aa1fc",
            "#0E95E9",
            "#0087D4",
            "#0079BE",
            "#006AA8"
        ]);

    const mapGeographies = (geographies) => {
        const mapContent = geographies.map((geo) => {
            const curr = popularStates.popular_array.findIndex((s) => stateAbbreviations[s] === geo.properties.name);
            return (
                <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    data-tooltip-id="map-tooltip"
                    data-tooltip-content={toolTipContent}
                    fill={curr > -1 ? colorScale(popularStates.popular_count_array[curr]) : "#EEE"}
                    onMouseEnter={() => {
                        setTooltipContent(`Amount: ${popularStates.popular_count_array[curr] || 0}`);
                    }}
                    onMouseLeave={() => {
                        setTooltipContent("");
                    }}
                    onMouseDown={() => getCityDetails(popularStates.popular_array[curr])}
                    style={{
                        hover: {
                            fill: "#93cee1",
                            outline: "none",
                        },
                        pressed: {
                            fill: "#93cee1",
                            outline: "none",
                        },
                        
                    }}
                />
            );
        });

        const annotations = geographies.map((geo) => {
            const centroid = geoCentroid(geo);
            const cur = allStates.find((s) => s.val === geo.id);
            return (
                <g key={geo.rsmKey + "-name"}>
                    {cur &&
                        centroid[0] > -160 &&
                        centroid[0] < -67 &&
                        (Object.keys(offsets).indexOf(cur.id) === -1 ? (
                            <Marker coordinates={centroid}>
                                <text y="2" fontSize={16} textAnchor="middle">
                                    {cur.id}
                                </text>
                            </Marker>
                        ) : (
                            <Annotation subject={centroid} dx={offsets[cur.id][0]} dy={offsets[cur.id][1]}>
                                <text x={4} fontSize={14} alignmentBaseline="middle">
                                    {cur.id}
                                </text>
                            </Annotation>
                        ))}
                </g>
            );
        });

        return (
            <>
                {mapContent}
                {annotations}
            </>
        );
    };

    return (
        <div>
            <p className='by-precent-title' style={{ fontWeight: 500, marginTop: '5px', marginLeft: '10px' }} >Geolocalize data</p>
            <div style={{ display: 'flex', gap: '5%', justifyContent: 'space-between'}}>
            <ComposableMap preserveAspectRatio="xMidYMid meet" projection="geoAlbersUsa" style={{ width: '500', marginLeft: '15%', marginTop: '-4%'}}>
                <Geographies  geography={geoUrl}>{({ geographies }) => mapGeographies(geographies)}</Geographies>
            </ComposableMap>
            <Box sx={{ width: '35%' , marginBottom: '30px',overflowY: 'scroll', height: '250px'  }}>
                {popularCities.popular_array.length > 0 ? (<Stack spacing={1}>
                    {popularCities.popular_array.map((city, index) => {
                        const color = popularCities.popular_count_array[index] * 5
                        const amount = popularCities.popular_count_array[index]
                        console.log()
                        return (
                            <Item sx={{ backgroundColor: colorScale(color)}}>{`${city}: ${amount}`}</Item>
                        )
                    })}
                </Stack>): (
                    <Stack spacing={1}>
                    {popularStates.popular_array.map((state, index) => {
                        const color = Math.floor(popularStates.popular_count_array[index] / 5)
                        const amount = popularStates.popular_count_array[index]
                        console.log()
                        return (
                            <Item sx={{ backgroundColor: colorScale(color)}}>{`${stateAbbreviations[state]}: ${amount}`}</Item>
                        )
                    })}
                </Stack>
                )}
            </Box>
            </div>
        </div>
    );
}

export default memo(MapChart);