import React from 'react'
import { Outlet, Link, useNavigate } from "react-router-dom";
import SideBar from '../CompanyDashboardComponents/SideBar';
import { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import Skeleton from '@mui/material/Skeleton';
import jwt from 'jwt-decode' // import dependency
import StateContext from '../StateContext';

const USER_DETAIL_API = '/api/get-user-details'

function AppRoot() {
  const [token, setToken_] = useState(localStorage.getItem("token"));
  const { sideBarArray, setSideBarArray } = useContext(StateContext)
  const [avatarUrl, setAvatarUrl] = useState('');


  const navigate = useNavigate();

  const [userDetails, setUserDetails] = useState({
    userEmail: null,
    userName: null,
    userRole: null
  })

  function isTokenValid(token) {
    try {
      if (token == null) {
        return false
      }

      const decodedToken = jwt(token);
      const currentTimestamp = Math.floor(Date.now() / 1000);
      
      if (decodedToken.exp && decodedToken.exp < currentTimestamp) {
        // Token has expired
        return false;
      }
  
      return true;
    } catch (error) {
      // Handle any errors that may occur during token decoding
      return false;
    }
  }

  const getUserDetails = async () => {
    try {
      const response = await axios.post(`${USER_DETAIL_API}`,null, {
        headers: { Authorization: token }
      });
      if (response.status == 200) {
        const result = await response.data
        setUserDetails({
          userEmail: result.user_email,
          userName: result.user_name,
          userRole: result.user_role,
         })
         localStorage.setItem('userDetails', JSON.stringify({
          userEmail: result.user_email,
          userName: result.user_name,
         }))
      } else {
        console.error('Access denied:', response.statusText);
      }
    } catch (error) {
      console.error(error)
    }
  }

  const getProfilePicture = async () => {
    try {
      const response = await axios.get(`/api/get-profile-picture`, {
        headers: { Authorization: token },
        responseType: 'arraybuffer',  // Include this in the same object as headers
      });
  
      if (response.status === 200) {
        const imageBlob = new Blob([response.data], { type: response.headers['content-type'] });

        const imageUrl = URL.createObjectURL(imageBlob);
  
        setAvatarUrl(imageUrl);
        console.log(imageUrl);
      } else {
        console.error('Access denied:', response.statusText);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!isTokenValid(token)) {
      navigate('/login')
    } else {
      setToken_(localStorage.getItem("token"))
    }
    const fetchData = async () => {
      await getUserDetails();
      await getProfilePicture();
    }
    if (!localStorage.getItem('userDetails')){
      fetchData()
    }
  }, [token]);

  return (
    <div style={{ display: 'flex'}}>
      <div style={{ zIndex: '5'}}>
        {!localStorage.getItem('userDetails') ? (
          <SideBar 
          companyEmail = {<Skeleton variant="text" sx={{ fontSize: '1rem', width: '80%', marginLeft: '10px' }} />}
          userName={<Skeleton variant="text" sx={{ fontSize: '1rem', width: '50%', marginLeft: '35px' }} />}
          menuDictionary={sideBarArray}
          profilePicture={avatarUrl}
           />
        ) : (
          <SideBar companyEmail = {JSON.parse(localStorage.getItem('userDetails')).userEmail} userName={JSON.parse(localStorage.getItem('userDetails')).userName} menuDictionary={sideBarArray} profilePicture={avatarUrl}
          />
        )}

      </div>
      <div style={{ backgroundColor: '#dcf2f6', width: '100%', height: '100%', marginLeft: '220px'}} >
        <Outlet />
      </div>
    </div>
  )
}

export default AppRoot
