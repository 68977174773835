import React, { useState, useEffect } from 'react';
import Chart from "react-apexcharts";
import axios from 'axios';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';

function IncomeBreakdown(props) {
    const [token, setToken_] = useState(localStorage.getItem("token"));
    const [popularList, setPopularList] = useState(props.dashboardDetails.incomeBreakdown);
    const [ chartState , setChartState ] = useState({
        series: [{
          name: 'Income',
          data: popularList.popular_count_array
        }],
        options: {
          chart: {
            height: 300,
            type: 'bar',
          },
          plotOptions: {
            bar: {
            borderRadius: 0,
              dataLabels: {
                position: 'top', // top, center, bottom
              },
            }
          },
          dataLabels: {
            enabled: true,
            formatter: function (val) {
                return val + "%";
              },
            offsetY: -20,
            style: {
              fontSize: '12px',
              colors: ["#304758"]
            }
          },
          
          xaxis: {
            categories: popularList.popular_array,
            position: 'top',
            offsetY: -10,
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false
            },
            crosshairs: {
              fill: {
                type: 'gradient',
                gradient: {
                  colorFrom: '#D8E3F0',
                  colorTo: '#BED1E6',
                  stops: [0, 100],
                  opacityFrom: 0.4,
                  opacityTo: 0.5,
                }
              }
            },
            tooltip: {
              enabled: true,
            }
          },
          yaxis: {
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false,
            },
            labels: {
              show: false,
              formatter: function (val) {
                return val + '%';
              }
            }
          
          },
        },
      
      
      })


    const getPopular = async () => {
        try {
          const response = await axios.post(`/api/get-company-popular`, {
            company_id: props.dashboardDetails.company_id,
            start_date: props.dashboardDetails.start_date,
            end_date: props.dashboardDetails.end_date,
            filters: (() => {
              // Check if segment and filters properties are present
              if (props.dashboardDetails.segment && props.dashboardDetails.segment.filters) {
                return props.dashboardDetails.segment.filters;
              } else {
                return undefined;
              }
            })(),
            segment_id: (() => {
              // Check if segment and filters properties are present
              if (props.dashboardDetails.segment && props.dashboardDetails.segment.segmentID) {
                return props.dashboardDetails.segment.segmentID;
              } else {
                return undefined;
              }
            })(),  // Call the function to get the actual filters
            type: 'income_levels'
          }, {
            headers: { Authorization: token }
          });
          if (response.status === 200) {
            const result = await response.data;
            console.log(result)
            setPopularList((prevState) => ({
              ...prevState,
              popular_array: result.popular_items,
              popular_count_array: result.popular_items_counts,
            }));
            setChartState((prevState) => ({
                ...prevState,
                series: [{
                  name: 'Amount',
                  data: result.popular_items_counts
                }],
                options: {
                  ...prevState.options,
                  xaxis: {
                    ...prevState.options.xaxis,
                    categories: result.popular_items
                  },
                }
              }));
            localStorage.setItem(props.dashboardDetails.company_id, JSON.stringify({
                ...JSON.parse(localStorage.getItem(props.dashboardDetails.company_id)),
                incomeBreakdown: {
                popular_array: result.popular_items,
                popular_count_array: result.popular_items_counts,
                }
            }))
            props.setDashboardDetails((prevState) => ({
            ...prevState,
            incomeBreakdown: {
                popular_array: result.popular_items,
                popular_count_array: result.popular_items_counts,
            }
        }))
            console.log(result)
          } else {
            console.error('Access denied:', response.statusText);
    
          }
        } catch (error) {
          console.log(error)
        }
      };


      useEffect(() => {
        getPopular()
        setChartState((prevState) => {
            let newState = { ...prevState };
            newState.options.plotOptions.bar.borderRadius = 10
            return newState;
          })
      }, [props.dashboardDetails.start_date, props.dashboardDetails.end_date]);

      useEffect(() => {
        
      }, [])

  return (
    <div>
        <p className='title' style={{ fontWeight: '500'}}>
            Yearly Income Breakdown
        </p>
      <div style={{ marginTop: '-10px'}}>
      <Chart
              options={chartState.options}
              series={chartState.series}
              type="bar"
              width="100%"
              height="300"
            />
      </div>
    </div>
  )
}

export default IncomeBreakdown
