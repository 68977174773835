import React, {useEffect, useState} from 'react'
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import axios from 'axios';
import Snackbar from '@mui/material/Snackbar';


function GenerateApiKey() {
    const [token, setToken_] = useState(localStorage.getItem("token"));
    const [apiKey, setApiKey] = useState('No API Key Yet')
    const [open, setOpen] = useState(false);

      const handleSnackClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
      };

      const handleApiClick = (text) => {
        navigator.clipboard.writeText(text)
        setOpen(true);
      }
    const generateApiKey = async () => {
        try {
          const response = await axios.get(`/api/generate-api-key`,{
            headers: { Authorization: token }
          });
          if (response.status == 200) {
            const result = await response.data
            setApiKey(result)
          } else {
            console.error('Access denied:', response.statusText);
          }
        } catch (error) {
          console.error(error)
        }
      }

      const getApiKey = async () => {
        try {
          const response = await axios.get(`/api/get-api-key`,{
            headers: { Authorization: token }
          });
          if (response.status == 200) {
            const result = await response.data
            setApiKey(result)
          } else {
            console.error('Access denied:', response.statusText);
          }
        } catch (error) {
          console.error(error)
        }
      }

      useEffect(() => {
        getApiKey()
      }, [])
    return (
        <div className='new-container' style={{ width: '100%', borderRadius: '20px', padding: '15px 40px 40px 40px', height: '35vh' }}>
            <div style={{ display: 'flex', gap: '10px', marginTop: '10px' }}>
                <div className='titlee' style={{ fontSize: '18px', alignSelf: 'center' }}>
                    API Key Settings
                </div>
            </div>
            <Divider sx={{ marginTop: '20px', backgroundColor: 'gray', marginBottom: '20px' }} />
            <div style={{}}>
                <p style={{ padding: '10px 10px 10px 10px', backgroundColor: 'gainsboro', borderRadius: '10px' }}>
                    Do not share your API key, If you generate a new API key your old one will not work
                </p>
            </div>
            <div className='title' style={{ marginLeft: '20px', marginTop: '40px' }}>
                API Key
            </div>
            <div style={{ display: 'flex'}}>
            <TextField disabled onClick={() => handleApiClick(apiKey)} value={apiKey} sx={{width: '100%', fontSize: '0.875rem', fontWeight: '400', lineHeight: '1.5', padding: '8px 12px', borderRadius: '8px', paddingTop: '10px' }} />
            <Button onClick={generateApiKey} sx={{ height: '55px', alignSelf: 'center' }} variant="contained" >
                            Generate
            </Button>
            </div>
            <Snackbar
                open={open}
                autoHideDuration={2000}
                onClose={handleSnackClose}
                message="Copied To Clipboard"
            />

        </div>
    )
}

export default GenerateApiKey
