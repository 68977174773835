import React from 'react'
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Divider from '@mui/material/Divider';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Button } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';


function CompanyCompareUTMSelection(props) {
    return (
        <div className='new-container' style={{ width: '100%', borderRadius: '20px', padding: '15px 40px 40px 40px', height: '60vh' }}>
            <div style={{ display: 'flex', gap: '10px', marginTop: '10px' }}>
                <div className='titlee' style={{ fontSize: '18px', alignSelf: 'center' }}>
                    {props.title}
                </div>
            </div>
            <Divider sx={{ marginTop: '20px', backgroundColor: 'gray', marginBottom: '20px' }} />
            <div style={{}}>
                <p style={{ padding: '10px 10px 10px 10px', backgroundColor: 'gainsboro', borderRadius: '10px' }}>
                    Please select the relevent UTM's for your comparison, UTM's are auto generated based on the current URL pool we have
                </p>
            </div>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <ToggleButtonGroup
                    color="primary"
                    value={props.currentSelection}
                    exclusive
                    onChange={props.handleChange}
                    aria-label="Platform"
                >
                    <ToggleButton value="source">Campaign source</ToggleButton>
                    <ToggleButton value="medium">Campaign medium</ToggleButton>
                    <ToggleButton value="name">Campaign name</ToggleButton>
                </ToggleButtonGroup>
            </div>
            <div style={{ marginLeft: '3%', marginTop: '3%' }}>
                {props.parameterLoading ? (
                    <div style={{  display: 'flex', flexDirection: 'column'}}>
                        <CircularProgress color="inherit" sx={{ alignSelf: 'center',marginTop: '15%'}}/>
                    </div>
                ) : (
                    <FormGroup sx={{ height: '300px', overflowY: 'scroll' }}>
                    {props.parameterValues.map((item, index) => (
                        <FormControlLabel key={index} label={item} control={
                            <Checkbox
                                checked={props.handleChecked(item)}
                                onClick={() => props.handleValueClick(item)} />
                        } />
                    ))}
                </FormGroup>
                )}
            </div>

        </div>
    )
}

export default CompanyCompareUTMSelection
