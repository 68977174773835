import React from 'react'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';


function TwoFieldInteg(props) {
    return (
        <div className='new-container' style={{ backgroundColor: 'white', width: '50%', marginLeft: '20%', marginTop: '3%', height: '83%', borderRadius: '20px', padding: '15px 40px 40px 40px' }}>
            <div style={{ display: 'flex', gap: '10px', marginTop: '10px' }}>
                <Avatar alt={props.integrationName} src={props.iconDict[props.integrationName]} />
                <div className='titlee' style={{ fontSize: '18px', alignSelf: 'center' }}>
                    New {props.integrationName} Integration
                </div>
            </div>
            <Divider sx={{ marginTop: '20px', backgroundColor: 'gray', marginBottom: '20px' }} />

            <div style={{}}>
                <p style={{ padding: '10px 10px 10px 10px', backgroundColor: 'gainsboro', borderRadius: '10px' }}>
                    This integration will trigger every time new users are added, It will add those new users to {props.integrationName}, For existing users, before turning the integration on, please export the data and upload it manually,
                    If you experience any difficulties please reach out to support@iceweb.io as this feature is currently in beta
                </p>
            </div>

            <div className='title' style={{ marginLeft: '20px', marginTop: '40px' }}>
                {props.integrationName} API Key
            </div>
            <TextField onChange={(event) => props.setApiKey(event.target.value)} placeholder='API Key' sx={{ width: '100%', fontSize: '0.875rem', fontWeight: '400', lineHeight: '1.5', padding: '8px 12px', borderRadius: '8px', paddingTop: '10px' }} />
            {props.currentIntegration.type == 2 ? (
                <div>
                    <div className='title' style={{ marginLeft: '20px', marginTop: '10px' }}>
                        {props.integrationName} List ID
                    </div>
                    <TextField onChange={(event) => props.setListID(event.target.value)} placeholder='List ID' sx={{ width: '100%', fontSize: '0.875rem', fontWeight: '400', lineHeight: '1.5', padding: '8px 12px', borderRadius: '8px', paddingTop: '10px' }} />
                </div>
            ) : (
            <div> 
                <div className='title' style={{ marginLeft: '20px', marginTop: '10px' }}>
                        {props.integrationName} Site ID
                    </div>
                    <TextField onChange={(event) => props.setSiteID(event.target.value)} placeholder='Site ID' sx={{ width: '100%', fontSize: '0.875rem', fontWeight: '400', lineHeight: '1.5', padding: '8px 12px', borderRadius: '8px', paddingTop: '10px' }} />
            </div>
            )}
            <div className='title' style={{ marginLeft: '20px', marginTop: '10px' }}>
                Test Integration
            </div>
            <div className='title' style={{ marginLeft: '20px', marginTop: '-0px', color: 'GrayText' }}>
                Check if the integration is running properly.

            </div>
            <LoadingButton
                id="basic-button"
                aria-controls={props.open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={props.open ? 'true' : undefined}
                onClick={props.handleClick}
                variant='contained'
                loading={props.isLoading}
                sx={{
                    backgroundColor: '#30acff',
                    marginLeft: '20px',
                    marginTop: '15px',
                    width: '40%'
                }}
            >
                Test integration
            </LoadingButton>
            {props.resultStatusCode == 200 ? (
            <Alert sx={{ marginTop: '30px'}} severity="success">Integration Test Successful, Please Check {props.integrationName}</Alert>
            ) : props.resultStatusCode === null ? (
                <Alert sx={{ marginTop: '30px'}} severity="warning">You have to test your integration before creating it</Alert>
            ) : (
                <Alert sx={{ marginTop: '30px'}} severity="error">Integration Test Failed, Please check fields and try again</Alert>
            )}
        </div>
    )
}

export default TwoFieldInteg
