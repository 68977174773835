import React, { useState, useEffect } from 'react';
import Chart from "react-apexcharts";
import axios from 'axios';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';

function PopulerChart(props) {
  const nav = useNavigate()
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [popularType, setPopularType] = useState('hour')
  const [popularList, setPopularList] = useState(props.dashboardDetails.popularChart);
  const [chartState, setChartState] = useState({
    options: {
      chart: {
        height: 300,
        type: 'bar',
      },
      plotOptions: {
        bar: {
          dataLabels: {
            position: 'top', // top, center, bottom
          },
        }
      },
      dataLabels: {
        enabled: true,
        offsetY: -20,
        style: {
          fontSize: '12px',
          colors: ["#304758"]
        }
      },

      xaxis: {
        categories: popularList.popular_array,
        position: 'top',
        offsetY: -12,
        labels: {
          show: false
        },
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        crosshairs: {
          fill: {
            type: 'gradient',
            gradient: {
              colorFrom: '#D8E3F0',
              colorTo: '#BED1E6',
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            }
          }
        },
        tooltip: {
          enabled: true,
        }
      },
      yaxis: {
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
        }

      },
      // responsive : [
      //   {
      //     breakpoint: 2000,
      //     options: {
      //       chart : {
      //         width : 850
      //       }
      //     }
      //   },
      //   {
      //     breakpoint: 2500,
      //     options: {
      //       chart : {
      //         width : '100%'
      //       }
      //     }
      //   }
      // ],
    },
    series: [{
      name: 'Amount',
      data: popularList.popular_count_array
    }]


  })


  const handlePopularTypeChange = (event) => {
    const value = event.target.value

    setPopularType(value)
  }

  const getPopular = async () => {

    try {
      const response = await axios.post(`/api/get-company-popular`, {
        company_id: props.dashboardDetails.company_id,
        start_date: props.dashboardDetails.start_date,
        end_date: props.dashboardDetails.end_date,
        filters: (() => {
          console.log(props.dashboardDetails);

          // Check if segment and filters properties are present
          if (props.dashboardDetails.segment && props.dashboardDetails.segment.filters) {
            return props.dashboardDetails.segment.filters;
          } else {
            return undefined;
          }
        })(),
        segment_id: (() => {
          // Check if segment and filters properties are present
          if (props.dashboardDetails.segment && props.dashboardDetails.segment.segmentID) {
            return props.dashboardDetails.segment.segmentID;
          } else {
            return undefined;
          }
        })(),  // Call the function to get the actual filters
        type: popularType
      }, {
        headers: { Authorization: token }
      });
      if (response.status === 200) {
        const result = await response.data;
        console.log(result)
        setPopularList((prevState) => ({
          ...prevState,
          popular_array: result.popular_items,
          popular_count_array: result.popular_items_counts,
        }));
        setChartState((prevState) => ({
          ...prevState,
          series: [{
            name: 'Amount',
            data: result.popular_items_counts
          }],
          options: {
            ...prevState.options,
            xaxis: {
              ...prevState.options.xaxis,
              categories: result.popular_items
            },
          }
        }));
        if (popularType == 'hour' && props.dashboardDetails.start_date == undefined) {
          localStorage.setItem(props.dashboardDetails.company_id, JSON.stringify({
            ...JSON.parse(localStorage.getItem(props.dashboardDetails.company_id)),
            popularChart: {
              popular_array: result.popular_items,
              popular_count_array: result.popular_items_counts,
            }
          }))
          props.setDashboardDetails((prevState) => ({
            ...prevState,
            popularChart: {
              popular_array: result.popular_items,
              popular_count_array: result.popular_items_counts,
            }
          }))
        }
        console.log(result)
      } else {
        console.error('Access denied:', response.statusText);

      }
    } catch (error) {
      if (error.request.status === 445) {
        nav(-1)
      } else {
        console.error(error)
      }
      console.log(error.request.status)
    }
  };





  useEffect(() => {
    getPopular()
    setChartState((prevState) => {
      let newState = { ...prevState };

      if (popularType === 'hour') {
        newState.options.xaxis.labels.show = true;
        newState.options.plotOptions.bar.borderRadius = 10
      } else {
        newState.options.xaxis.labels.show = false;
        newState.options.plotOptions.bar.borderRadius = 10
      }

      return newState;
    })
  }, [props.dashboardDetails.start_date, props.dashboardDetails.end_date, popularType]);





  return (
    <div className='most-popular'>
      <div style={{ display: 'flex', flexDirection: 'row', gap: '0.5%' }}>
        <div style={{ alignItems: 'center', marginTop: '3px', fontWeight: '500'}}> Most popular</div>
        <FormControl variant='standard' sx={{ alignItems: 'center' }}>
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            value={popularType}
            onChange={handlePopularTypeChange}
            label="Age"
          >
            <MenuItem value={'url'}>URLs</MenuItem>
            <MenuItem value={'hour'}>Hours</MenuItem>
          </Select>
        </FormControl>
      </div>
      {popularType === 'hour' ? (
      <div style={{ marginTop: '10px', fontSize: '13px', color: 'gray'}}>
        Timezone: UTC
      </div>) : (<div style={{ marginTop: '10px', fontSize: '13px', color: 'white'}}>
        .
      </div>)}
      <div className='most-popular-content'>
        <div className="row">
          <div className="mixed-chart" style={{ marginTop: '20px' }}>
            <Chart
              options={chartState.options}
              series={chartState.series}
              type="bar"
              height="255"
            />
          </div>
        </div>
      </div>
    </div>

  );
}

export default PopulerChart;
