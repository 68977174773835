import React, { useEffect, useRef, useState } from 'react';
import { Outlet, useParams, useNavigate, useOutletContext, useNavigation } from "react-router-dom";
import TopBar from '../CompanyDashboardComponents/TopBar';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import klayviyoIcon from '../images/klayviyoIcon.jpeg'
import CustomerIOIcon from '../images/CustomerIOIcon.png'
import mailChimpIcon from '../images/mailChimpIcon.jpeg'
import sendGridIcon from '../images/sendGridIcon.png'
import hubSpotIcon from '../images/hubSpotIcon.jpeg'
import omniSendIcon from '../images/omniSendIcon.png'
import emailOctopusIcon from '../images/emailOctopusIcon.png'
import bayEngageIcon from '../images/bayEngageIcon.png'
import axios from 'axios';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

function CompanyIntegrations() {
    const navigate = useNavigate()
    const [token, setToken_] = useState(localStorage.getItem("token"));
    const [userIntegNames, setuserIntegNames] = useState([])
    const [userInteg, setUserInteg] = useState([])
    const [integrations, setIntegrations] = useOutletContext()
    const [open, setOpen] = React.useState(false);

    const companyID = useParams().companyId
    const segmentID = useParams().segmentID


    const iconDict = {
        'Klaviyo': klayviyoIcon,
        'Customer.io': CustomerIOIcon,
        'Mailchimp': mailChimpIcon,
        'SendGrid': sendGridIcon,
        'HubSpot': hubSpotIcon,
        'OmniSend': omniSendIcon,
        'EmailOctopus': emailOctopusIcon,
        'BayEngage': bayEngageIcon

    }

    const getCompanyIntegration = async () => {
        try {
            setOpen(true)
            const url = '/api/get-company-integrations';
            const params = {
                segment_id: segmentID,
                company_id: companyID
            };

            const response = await axios.post(url, params, {
                headers: { Authorization: token }
            });
            const result = response.data;
            setUserInteg(result)
            result.map((item) => (setuserIntegNames((prevState) => ([
                ...prevState,
                item.integration_name
            ]))))
            

        } catch (error) {
            console.error('An error occurred:', error);
        } finally {
            setOpen(false)
        }
    };

    const handleViewInteg = (integ,index) => {
        integ.index = index
        localStorage.setItem('integration', JSON.stringify(integ))
        navigate(`${integ.id}`)
    }


    useEffect(() => {
        getCompanyIntegration()
    }, []);

    useEffect(() => {
        console.log(userIntegNames)
        console.log(userInteg)
    }, [userInteg, userIntegNames]);



    return (
        <div className='body'>
            <Backdrop
                sx={{ backgroundColor: '#dcf2f6', zIndex: 1 }}
                open={open}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <div className='top-bar' style={{ zIndex: 3 }} >
                <TopBar
                    className='search-bar'
                    companyName={'Integrations'}
                    backAmount={'../..'}
                />
            </div>
            <div className='active-integrations'>
                <div className='title' style={{ fontWeight: '600', marginLeft: '2%', marginTop: '2%'}}>
                    Active integrations
                </div>
                <Box sx={{ flexGrow: 1, marginLeft: '15px',marginLeft: '1%', marginTop: '10px' }}>
                    <Grid container spacing={2}>
                        {userIntegNames.length > 0 ? (
                            userInteg.map((item, index) => (
                                userIntegNames.includes(item.integration_name) ? 
                                (<Grid item sx={{ borderRadius: '10px' }}>
                                    <div className='card' style={{ maxWidth: "275px" }}>
                                        <Card sx={{ minWidth: 275, borderRadius: '10px' }}>
                                            <CardContent>
                                                <Typography sx={{ marginTop: '5px', display: 'flex', gap: '10px' }} variant="h6" component="div">
                                                    <Avatar alt={item.integration_name} src={iconDict[item.integration_name]} />
                                                    <div style={{ alignSelf: 'center' }}>
                                                        {item.integration_name}
                                                    </div>
                                                </Typography>
                                                <Typography sx={{ marginTop: '10px' }} color="text.secondary">
                                                    {integrations
                                                        .filter(obj => obj.name === item.integration_name)
                                                        .map(match => match.description)[0]}
                                                </Typography>
                                            </CardContent>
                                            <CardActions>
                                                <Button onClick={() => handleViewInteg(item,index)}size="small">View integration</Button>
                                            </CardActions>
                                        </Card>
                                    </div>
                                </Grid>) : (<div>
                                    
                                </div>)
                            ))
                        ) : (
                            <div style={{ width: '100%',paddingBottom:'80px'}}>
                                    <p style={{ textAlign: 'center', fontSize: '20px', fontWeight: '500', marginTop: '70px'}}>
                                        No Integrations Yet!
                                    </p>
                                </div>
                        )}

                    </Grid>
                </Box>
            </div>
            <div className='available-integrations'>
                <div className='title' style={{ fontWeight: '600', marginLeft: '2%', marginTop: '2%' }}>
                    Available integrations
                </div>
                <Box sx={{ flexGrow: 1, marginLeft: '1%', marginTop: '20px' }}>
                    <Grid container spacing={2}>
                        {integrations.map((item, index) => (
                            !userIntegNames.includes(item.name) ? (<Grid item sx={{ borderRadius: '10px' }}>
                                <div className='card' style={{ maxWidth: "275px" }}>
                                    <Card sx={{ minWidth: 275, borderRadius: '10px' }}>
                                        <CardContent>
                                            <Typography sx={{ marginTop: '5px', display: 'flex', gap: '10px' }} variant="h6" component="div">
                                                <Avatar alt={item.name} src={iconDict[item.name]} />
                                                <div style={{ alignSelf: 'center' }}>
                                                    {item.name}
                                                </div>
                                            </Typography>
                                            <Typography sx={{ marginTop: '10px' }} color="text.secondary">
                                                {item.description}
                                            </Typography>
                                        </CardContent>
                                        <CardActions>
                                            <Button onClick={() => navigate(`new/${item.name}`)} size="small">Add integration</Button>
                                        </CardActions>
                                    </Card>
                                </div>
                            </Grid>) : (<div></div>)
                        ))}
                    </Grid>
                </Box>
            </div>
        </div>
    )
}

export default CompanyIntegrations
