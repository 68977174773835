import React from 'react'
import { Outlet, useParams, useNavigate, useOutletContext, useLocation } from "react-router-dom";
import DashboardIcon from '@mui/icons-material/Dashboard';
import ViewListIcon from '@mui/icons-material/ViewList';
import { useState, useEffect, useContext } from 'react';
import Skeleton from '@mui/material/Skeleton';
import axios from 'axios';
import StateContext from '../StateContext';
import BackupTableIcon from '@mui/icons-material/BackupTable';
import LinkIcon from '@mui/icons-material/Link';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import toast, { Toaster } from 'react-hot-toast';
import { duration } from '@mui/material';



function  CompanyDashboardRoot() {
    const [token, setToken_] = useState(localStorage.getItem("token"));
    const { sideBarArray, setSideBarArray } = useContext(StateContext)
    const [ inPage, setInPage ] = useState(false)
    const [ companyAccess, setCompanyAccess ] = useState(555)
    const [ companyAccessLoading, setCompanyAccessLoading ] = useState(true)


    const companyID = useParams().companyId

    const segmentID = useParams().segmentID

    const location = useLocation();

    
    const [dashboardDetails, setDashboardDetails] = useState(JSON.parse(localStorage.getItem(companyID)))

    const navigate = useNavigate();

    const menuDictionary = [
      {
        text: 'Dashboard',
        icon: <DashboardIcon style={{ color: '#5cbad1' }} />,
        route: `companies/${companyID}`,
      },
      {
        text: 'Company Data',
        icon: <ViewListIcon style={{ color: '#5cbad1' }} />,
        route: `companies/${companyID}/data`,
      },
      {
        text: 'Segment',
        icon: <BackupTableIcon style={{ color: '#5cbad1' }} />,
        route: `companies/${companyID}/segment`,
      },
      {
        text: 'Integrations',
        icon: <LinkIcon style={{ color: '#5cbad1' }} />,
        route: `companies/${companyID}/integrations`,
      },
      {
        text: 'Compare',
        icon: <ImportExportIcon style={{ color: '#5cbad1' }} />,
        route: `companies/${companyID}/compare`,
      },
      // {
      //   text: 'Payment Center',
      //   icon: <AccountBalanceIcon style={{ color: '#5cbad1' }} />,
      //   route: `companies/${companyID}/payment/`,
      // }
      // Add more items to your dictionary as needed
    ];

    const segmentMenuDictionary = [
      {
        text: 'Dashboard',
        icon: <DashboardIcon style={{ color: '#5cbad1' }} />,
        route: `companies/${companyID}/segment/${segmentID}`,
      },
      {
        text: 'Segment Data',
        icon: <ViewListIcon style={{ color: '#5cbad1' }} />,
        route: `companies/${companyID}/segment/${segmentID}/data`,
      },
      // Add more items to your dictionary as needed
    ];

    const getCounts = async () => {
      try {
        const response = await axios.post(`/api/get-company-counts`, {
          company_id: companyID,
            start_date: dashboardDetails.start_date,
            end_date: dashboardDetails.end_date,
        }, {
          headers: { Authorization: token }
        });
        if (response.status === 200) {
          const result = response.data;
          setDashboardDetails((prevState) => ({
            ...prevState,
            people_count: result.people_count,
            journey_count: result.journey_count,
          }));
          console.log(result)
        } else {
          console.error('Access denied:', response.statusText);
  
        }
      } catch (error) {
        console.log(error)
      }
    };

    const getCompanyAccess = async () => {
        try {
          setCompanyAccessLoading(true)
          const response = await axios.post(`/api/company-access`, {
            company_id: companyID,
          }, {
            headers: { Authorization: token }
          });
          if (response.status === 200) {
            setCompanyAccess(response.status)
          } else {
            setCompanyAccess(response.status)
          }
        } catch (error) {
          setCompanyAccess(555)
        } finally {
          setCompanyAccessLoading(false)
        }
    };


    useEffect(() => {
      setInPage(true)
      getCompanyAccess()
    }, []);

    useEffect(() => {
      if (companyAccess !== 200 && !companyAccessLoading) {
        toast("You can purchase credits through Payment Center", { duration: 5000})
        toast("Hey There! 👋  \n The company current credit balance is insufficient and as a result, data updates will be temporarily stopped until additional credits are purchased", { duration: 5000})
      }
    }, [companyAccess, companyAccessLoading]);

    useEffect(() => {
      if (segmentID) {
        setSideBarArray(segmentMenuDictionary)
      } else {
        setSideBarArray(menuDictionary)
      }
    }, [inPage]);

    useEffect(() => {
      console.log(dashboardDetails)
      const fetchData = async () => {
        getCounts()
      }

      fetchData()
    }, [dashboardDetails.start_date,dashboardDetails.end_date]);

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      // If not authenticated, redirect to the login page
      navigate('/login');
      console.log('test')

    }
  }, [location])
    
  return (
      <div>
        <Toaster />
        <Outlet context={[dashboardDetails, setDashboardDetails]}/>
      </div>
  )
}

export default CompanyDashboardRoot
