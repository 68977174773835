import React from 'react'
import { Outlet, useOutletContext } from 'react-router-dom'

function CompanySegRoot() {

    const [dashboardDetails, setDashboardDetails] = useOutletContext()

  return (
    <div>
      <Outlet context={[dashboardDetails, setDashboardDetails]} />
    </div>
  )
}

export default CompanySegRoot
