import React, { useState } from 'react'
import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';
import iceWebLogo from '../images/iceweb-logo.png'
import { Avatar } from '@mui/material';
import 'bootstrap/dist/css/bootstrap.css';
import { Link, useNavigate } from "react-router-dom";
import Uploady from "@rpldy/uploady";
import { asUploadButton } from "@rpldy/upload-button";
import Popup from 'reactjs-popup';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import KeyIcon from '@mui/icons-material/Key';
import GenerateApiKey from './GenerateApiKey';
import { useLocation } from 'react-router-dom';



function SideBar(props) {

  const [token, setToken_] = useState(localStorage.getItem("token"));
  const navigate = useNavigate()
  const location = useLocation().pathname.split('/').pop()

  console.log(location)

  const DivUploadButton = asUploadButton((props) => {
    return <div {...props} style={{ borderWidth: '1px 1px 1px 1px', borderStyle: 'solid', borderColor: 'gainsboro' }}>
      <MenuItem icon={<AddPhotoAlternateIcon sx={{ color: 'wh' }} />} >
        <div style={{ fontSize: '15px', color: 'black' }}>
          Update Profile
        </div>
        <div style={{ fontSize: '15px', color: 'black' }}>
          Picture
        </div>
      </MenuItem>
    </div>
  });

  const handleLogout = () => {
    localStorage.clear()
    navigate('/login')
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: '#a3e5ea',
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
  }
  return (
    <div style={{ position: 'fixed', height: '100%', zIndex: '5' }}>
      <Sidebar width='220px' rootStyles={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Link to='/app/companies'>
          <img src={iceWebLogo} alt='Iceweb Logo' style={{ width: '150px', marginLeft: '15px' }}></img>
        </Link>
        <Menu rootStyles={{ fontSize: '14px', fontWeight: '500', padding: '5px', height: '440px' }} menuItemStyles={{
          button: ({ active }) => {
            return {
              background: active ? 'linear-gradient(11deg, rgba(61,145,255,1) 29%, rgba(0,209,255,1) 100%)' : undefined,
              color: active ? 'white' :  'black',
              width: '100%',
              "&:hover": {
                background: active ? 'linear-gradient(11deg, rgba(61,145,255,1) 29%, rgba(0,209,255,1) 100%)' : 'gainsboro'
              },
              borderRadius: '10px'
            }
          }
        }}>
          {props.menuDictionary.map((menuItem, index) => (
            <MenuItem
              key={index}
              icon={menuItem.icon}
              component={<Link to={menuItem.route} />}
              active={menuItem.route.split('/').pop() === location ? true : false}
            >
              {menuItem.text}
            </MenuItem>
          ))}
        </Menu>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', alignSelf: 'flex-end' }}>
          <div style={{ marginLeft: '15px', fontSize: '14px', color: 'gray', fontWeight: '500', textAlign: 'center', width: '90%' }}>
            If you experience any issues, Please contact support@iceweb.io or call us at (786) 248-1606
          </div>
          <div className='login-card' style={{ display: 'flex', flexDirection: 'column', background: 'linear-gradient(11deg, rgba(61,145,255,1) 29%, rgba(0,209,255,1) 100%)', borderRadius: '5px', gap: '10px', padding: '20px', width: '90%', alignSelf: 'center' }}>
            <Popup trigger={
              props.profilePicture ?
                (<Avatar src={props.profilePicture} style={{ alignSelf: 'center', cursor: "pointer" }} />)
                :
                (<Avatar {...stringAvatar('N A')} style={{ alignSelf: 'center', cursor: "pointer" }} />)
            }
              position="top center"
              nested
              contentStyle={{ marginTop: '-20px', height: '115px', position: 'fixed' }} >
              <div style={{}}>
                <Menu >
                  <Uploady destination={{ url: "/api/upload-profile-picture", headers: { Authorization: token } }}>
                    <DivUploadButton />
                  </Uploady>
                  <Popup trigger={<MenuItem style={{ borderWidth: '1px 1px 1px 1px', borderStyle: 'solid', borderColor: 'gainsboro' }} icon={<KeyIcon sx={{ color: 'black', }} />}>
                    <div style={{ fontSize: '15px' }}>
                      API Key
                    </div>
                  </MenuItem>} modal nested contentStyle={{ borderRadius: '20px', paddingTop: '10px' }}>
                    <GenerateApiKey />
                  </Popup>
                </Menu>
              </div>
            </Popup>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ color: 'white', textAlign: 'center', fontWeight: '600', }}>{props.userName}</div>
              <div style={{ color: 'white', textAlign: 'center', fontWeight: '600', fontSize: '13px' }}>{props.companyEmail}</div>
            </div>
            <button onClick={() => handleLogout()} style={{ backgroundColor: 'transparent', border: '1px solid white', color: 'white', borderRadius: '5px', width: '80%', alignSelf: 'center' }} >
              Logout
            </button>
          </div >
          <a href='https://documenter.getpostman.com/view/31875151/2s9YkuXxgG' target='_blank' style={{ alignSelf: 'center', color: 'gray', fontWeight: '500', fontSize: '13', textDecoration: 'none' }}>
            <div>
              API Documentation
            </div>
          </a>
        </div>
      </Sidebar>
    </div>
  )
}

export default SideBar