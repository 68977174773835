import React from 'react'
import { Outlet, useLocation } from "react-router-dom";
import { useState, useEffect, useContext } from 'react';
import BusinessIcon from '@mui/icons-material/Business';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import axios from 'axios';
import jwt from 'jwt-decode' // import dependency
import StateContext from '../StateContext';



function DashboardRoot() {
  const [token, setToken_] = useState(localStorage.getItem("token"));
  const [role, setRole] = useState(jwt(token).user_role);
  const [ companiesList, setCompaniesList ]  = useState(JSON.parse(localStorage.getItem('companies-list')) || []);
  const { sideBarArray, setSideBarArray } = useContext(StateContext)
  const [isLoading, setIsLoading] = useState(); // State to track loading

  const location = useLocation();
  
  const menuDictionary = [
    {
      text: 'Companies',
      icon: <BusinessIcon style={{ color: '#5cbad1' }} />,
      route: 'companies',
    },
    // Add more items to your dictionary as needed
  ];

  const AdminMenuDict = [
    {
      text: 'Admin',
      icon: <AddBusinessIcon style={{ color: '#5cbad1' }} />,
      route: 'companies/admin',
    },
  ];

  const getCompaniesList = async () => {
    try {
      const response = await axios.get(`/api/get-company-list`, {
        headers: { Authorization: token }
      });
      if (response.status === 200) {
        const result = response.data;
        setCompaniesList(result);
        localStorage.setItem('companies-list',JSON.stringify(result))
      } else {
        console.error('Access denied:', response.statusText);

      }
    } catch (error) {
      console.log(error)
    }
  };

  useEffect(() => {
    getCompaniesList()
  }, []);

  useEffect(() => {
    // Perform action (delete from localStorage) when the user is not on a specific page
    if (!location.pathname.startsWith('/app/companies/xs529/segment/')) {
     // Delete the item from localStorage
     localStorage.removeItem('excluded_users');
   }
   console.log(location)
 }, [location]);


  useEffect(() => {
    if (role === 'admin') {
      AdminMenuDict.forEach((item) => menuDictionary.push(item));
    }
    setSideBarArray(menuDictionary);
  }, [role]);

  return (
        <Outlet context={[companiesList, setCompaniesList]} />
  )
}

export default DashboardRoot
