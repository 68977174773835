import 'bootstrap/dist/css/bootstrap.css';
import React, { useEffect, useRef, useState } from 'react';
import { useOutletContext } from "react-router-dom";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import DateRange from '../CompanyDashboardComponents/DateRange';
import ExportButton from '../CompanyDashboardComponents/ExportButton';
import PeopleTable from '../CompanyDashboardComponents/PeopleTable';
import TopBar from '../CompanyDashboardComponents/TopBar';


const GET_ALL_USERS_API_URL = '/api/get-data';
const DOWNLOAD_ALL_USERS_API_URL = '/api/download-users'

function CompanyData(props) {
  const [userDetails, setUserDetails] = useState([]);
  const [filteredUserDetails, setFilteredUserDetails] = useState([]);
  const [searchTerm, setSearchTerm] = useState('false')
  const [dashboardDetails, setDashboardDetails] = useOutletContext()
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 13, //customize the default page size
  });

  const updateDateRange = () => {
    setDashboardDetails((prevState) => ({
      ...prevState, // Spread the previous state
      start_date: undefined, // Modify the 'age' field
      end_date: undefined
    }));
  };

  const currentSearchTerm = useRef('false')

  useEffect(() => {
    updateDateRange()
  }, []);


  return (
          <div className="company-data" style={{ height: '100vh'}}>
                <div className='data-page' style={{ display: 'flex', flexDirection: 'column'}}>
                  <div className='top-bar' style={{ position: 'sticky', top: "0", zIndex: 2 }} >
                      <TopBar
                      className='search-bar'
                      elements = {
                      <div style={{ display: 'flex'}}>
                      <DateRange 
                      currentStartDate = {undefined}
                      currentEndDate = {undefined} 
                      setDashboardDetails = {setDashboardDetails}
                      />
                      </div>
                    }
                      companyName = {dashboardDetails.company_name}
                      backAmount = {'../..'}
                      />
                  </div>
                  <div className='table' style={{ zIndex: '0', width: '80vw', marginTop: '1.3%', marginLeft: '3.2%'}}>
                    <PeopleTable
                    className='people-tabl' 
                    API_URL={GET_ALL_USERS_API_URL}
                    setData={setUserDetails}
                    setFilteredData={setFilteredUserDetails}
                    filteredData={filteredUserDetails}
                    companyID={dashboardDetails.company_id}
                    pageIndex={pagination.pageIndex}
                    pageSize={pagination.pageSize}
                    pagination={pagination}
                    setPagination={setPagination}
                    searchTerm={searchTerm}
                    currentSearchTerm={currentSearchTerm}
                    currentStartDate = {dashboardDetails.start_date}
                    currentEndDate = {dashboardDetails.end_date} 
                    exportBtn={<ExportButton 
                      API_URL={DOWNLOAD_ALL_USERS_API_URL}
                      companyID={dashboardDetails.company_id}
                      currentStartDate = {dashboardDetails.start_date}
                      currentEndDate = {dashboardDetails.end_date} 
                      />}
                    />
                  </div>
                </div>
              </div>
  );
}

export default CompanyData;


    //  YOU PUT THIS IN THE COMPONENT FUNCTION BODY
    //   function convertArrayOfObjectsToCSV(array) {
    //     let result;
    
    //     const columnDelimiter = ',';
    //     const lineDelimiter = '\n';
    //     const keys = Object.keys(array[0]);
    
    //     result = '';
    //     result += keys.join(columnDelimiter);
    //     result += lineDelimiter;
    
    //     array.forEach(item => {
    //         let ctr = 0;
    //         keys.forEach(key => {
    //             if (ctr > 0) result += columnDelimiter;
    
    //             result += item[key];
    //             // eslint-disable-next-line no-plusplus
    //             ctr++;
    //         });
    //         result += lineDelimiter;
    //     });
    
    //     return result;
    // }
    
    
    // function downloadCSV() {
    //     const link = document.createElement('a');
    //     let csv = convertArrayOfObjectsToCSV(filteredUserDetails);
    //     if (csv == null) return;
    
    //     const filename = 'export.csv';
    
    //     if (!csv.match(/^data:text\/csv/i)) {
    //         csv = `data:text/csv;charset=utf-8,${csv}`;
    //     }
    
    //     link.setAttribute('href', encodeURI(csv));
    //     link.setAttribute('download', filename);
    //     link.click();
    // }
    //
    // YOU PUT THE CODE BELOW IN THE RETURN JSX (HTML)
    // <button onClick={() => downloadCSV()}>Export</button>