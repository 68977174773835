import React, { useState, useEffect } from 'react'
import TopBar from '../CompanyDashboardComponents/TopBar'
import CompanyCompareCard from '../CompanyDashboardComponents/CompanyCompareCard';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CompareDateRange from '../CompanyDashboardComponents/CompareDateRange';
import { Outlet, useOutletContext, useParams } from 'react-router-dom'
import { addDays } from 'date-fns';
import axios from 'axios';
import CompareByPrecent from '../CompanyDashboardComponents/CompareByPercent';
import ByPrecent from '../CompanyDashboardComponents/ByPrecent';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import UrlCompare from '../CompanyDashboardComponents/UrlCompare';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import CompanyCompareUTM from './CompanyCompareUTM';

function CompanyCompare() {
    const segmentID = useParams().segmentID
    const [backDropOpen, setBackDropOpen] = React.useState(false);
    const [ segmentFilters , setSegmentFilters ] = useState(segmentID && JSON.parse(localStorage.getItem('segment')).filters)
    const [dashboardDetails, setDashboardDetails] = useOutletContext()
    const [cardDetails, setCardDetails] = useState([])
    const [byPrecentCardDetails, setByPrecentCardDetails] = useState([])
    const [compareType, setCompareType] = useState("Date")
    const [utmDone, setUtmDone] = useState(false)
    const [ finalSelection, setFinalSelection ] = useState({
        selection_one: {
            val: '',
            prefix: "start"
        },
        selection_two: {
            val: '',
            prefix: "exact"
        }
    })
    const [ utmSelection, setUtmSelection] = useState({
        selection_one: {
            selection: 'source',
            values: []
        },
        selection_two:  {
            selection: 'source',
            values: []
        }
    })
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (event) => {
        setAnchorEl(null);
        console.log(event.target.textContent)
        setCompareType(event.target.textContent)
    };


    function formatDate(inputDate) {
        const date = new Date(inputDate);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is 0-based
        const day = String(date.getDate()).padStart(2, '0');

        const dateWithYear = `${year}-${month}-${day}`
        const dateNoYear = `${month}-${day}`

        return [dateWithYear, dateNoYear];

    }

    const [compareDates, setCompareDates] = useState({
        selection1: {
            startDate: formatDate(addDays(new Date(), -30))[0],
            endDate: formatDate(new Date())[0],
        },
        selection2: {
            startDate: formatDate(addDays(new Date(), -60))[0],
            endDate: formatDate(addDays(new Date(), -31))[0],

        }
    })

    const getCompareData = async () => {
        setBackDropOpen(true)
        try {
            const response = await axios.post(`/api/get-compare-data`, {
                company_id: dashboardDetails.company_id,
                selection_one: compareDates.selection1,
                selection_two: compareDates.selection2,
                url_selection: finalSelection,
                compare_type: compareType,
                filters: segmentFilters,
                utm_selection: utmSelection
            });
            if (response.status === 200) {
                const result = response.data;
                setCardDetails(result.average_list)
                setByPrecentCardDetails(result.by_percent_list)
            } else {
                console.error('Access denied:', response.statusText);

            }
        } catch (error) {
            console.log(error)
        } finally {
            setBackDropOpen(false)
        }
    };

    useEffect(() => {
        getCompareData()
    }, [compareDates,finalSelection]);

    useEffect(() => {
        if (utmDone) {
            getCompareData()
        }
    }, [utmDone]);

    useEffect(() => {
        console.log(utmDone)
    }, [utmDone])


    return (
        <div style={{ height: '150vh'}}>
            <Backdrop
                sx={{ backgroundColor: '#dcf2f6', zIndex: 1 }}
                open={backDropOpen}
            >
                <div style={{ display: 'flex', flexDirection: 'column', gap: '20px'}}>
                <CircularProgress color="inherit" sx={{ alignSelf: 'center'}}/>
                <div style={{ fontWeight: '500'}}>
                    Generating Report
                </div>
                </div>
            </Backdrop>
            <div className='top-bar' style={{ position: 'sticky', top: "0",zIndex: 2 }} >
                <TopBar
                    className='compare-topbar'
                    companyName={'Compare'}
                    backAmount={'..'}
                    elements={
                        <div className='compare-topbar-div' style={{ display: 'flex' }}>
                            {compareType === 'Date' ? (
                                <CompareDateRange
                                    dashboardDetails={dashboardDetails}
                                    setDashboardDetails={setDashboardDetails}
                                    compareDates={compareDates}
                                    setCompareDates={setCompareDates}
                                />
                            ) : (
                                <UrlCompare finalSelection={finalSelection} setFinalSelection={setFinalSelection}/>
                            )}
                            <div style={{ paddingLeft: '7px'}}>
                            <CompanyCompareUTM setUtmSelection={setUtmSelection} utmSelection={utmSelection} setUtmDone={setUtmDone} segmentFilters={segmentFilters}/>
                            </div>
                            <div style={{ paddingLeft: '7px'}}>
                                <Button
                                    id="basic-button"
                                    aria-controls={open ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                    onClick={handleClick}
                                    variant='outlined'
                                >
                                    Compare Type
                                </Button>
                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                    }}
                                >
                                    <MenuItem onClick={(event) => handleClose(event)}>Date</MenuItem>
                                    <MenuItem onClick={(event) => handleClose(event)}>URL</MenuItem>
                                </Menu>
                            </div>
                        </div>
                    }
                />
            </div>
             <div className='compare-container' style={{ height: '90vh'}}>
                <p className='title' style={{ fontWeight: '500', marginLeft: '1.5%', marginTop: '1%'}}>
                Comparison Overview</p>
                <Box sx={{ flexGrow: 1, marginLeft: '20px', marginTop: '10px' }}>
                    <Grid container spacing={2}>
                        {cardDetails.map((item, index) => (
                            <CompanyCompareCard key={index} cardValue={item.card_value} cardName={item.card_name} cardComparePrecent={item.card_compare_percent} />
                        ))}

                    </Grid>
                </Box>
                <Box sx={{ flexGrow: 1, marginLeft: '20px', marginTop: '10px'}}>
                    {byPrecentCardDetails.map((item, index) => (
                        <CompareByPrecent
                            key={index}
                            dashboardDetails={dashboardDetails}
                            setDashboardDetails={setDashboardDetails}
                            item_list={item.item_list}
                            item_count_list={item.item_count_list}
                            obj_name={item.obj_name}
                            percent_change={item.percent_change}
                        />
                    ))}
                </Box>
            </div>
        </div>
    )
}

export default CompanyCompare
