import React, { useEffect, useMemo, useState } from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import axios from 'axios';
import { MaterialReactTable } from 'material-react-table';
import { MenuItem, Button, ListItemIcon, IconButton } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import TimelineIcon from '@mui/icons-material/Timeline';
import SlidingPane from "react-sliding-pane";
import SidePanelUserJourney from './SidePanelUserJourney';
import Skeleton from '@mui/material/Skeleton';



function PeopleTable(props) {


  const [pagination, setPagination] = useState(props.pagination);
  const [showSkeletons, setShowSkeletons] = useState(false)
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [rowCount, setRowCount] = useState(0)
  const [sorting, setSorting] = useState([]);
  const [dataType, setDataType] = useState('users')
  const [alignment, setAlignment] = useState('users');
  const [isPaneOpen, setIsPaneOpen] = useState({
    isPaneOpen: false,
    isPaneOpenLeft: false,
  });
  const [ currentUserJourney, setCurrentUserJourney ] = useState({
    userName: '',
    userJourney: []
  })
  const [enableActions, setEnableActions ] = useState(true)


  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
    setDataType(newAlignment)
    console.log(newAlignment)
    if (newAlignment == 'journeys') {
      setEnableActions(false)
    } else {
      setEnableActions(true)
    }
  };

  const getUserJourney = async (fullName) => {
    try {
      setCurrentUserJourney((prevState) => ({
        ...prevState,
        userName: fullName,
        userJourney: [
          {
            date: <Skeleton variant="text" sx={{ fontSize: '1rem' }} />,
            documents: [
              {
                full_name: <Skeleton variant="text" sx={{ fontSize: '1rem' }} />,
                hour: <Skeleton variant="text" sx={{ fontSize: '1rem' }} />,
                opt_in_ip: <Skeleton variant="text" sx={{ fontSize: '1rem' }} />,
                parameters_list: [
                <div style={{ padding: '4px' , paddingLeft: '95px'}}/>,
                <div style={{ padding: '4px' , paddingLeft: '65px'}}/>,
                <div style={{ padding: '4px' , paddingLeft: '45px'}}/>,
                <div style={{ padding: '4px' , paddingLeft: '95px'}}/>,
                <div style={{ padding: '4px' , paddingLeft: '45px'}}/>,
                <div style={{ padding: '4px' , paddingLeft: '75px'}}/>,
                <div style={{ padding: '4px' , paddingLeft: '25px'}}/>,
                <div style={{ padding: '4px' , paddingLeft: '55px'}}/>,
                <div style={{ padding: '4px' , paddingLeft: '15px'}}/>,
                <div style={{ padding: '4px' , paddingLeft: '95px'}}/>,
                <div style={{ padding: '4px' , paddingLeft: '65px'}}/>,
                <div style={{ padding: '4px' , paddingLeft: '45px'}}/>,
                <div style={{ padding: '4px' , paddingLeft: '95px'}}/>,
                <div style={{ padding: '4px' , paddingLeft: '45px'}}/>,
                <div style={{ padding: '4px' , paddingLeft: '75px'}}/>,
                <div style={{ padding: '4px' , paddingLeft: '25px'}}/>,
                <div style={{ padding: '4px' , paddingLeft: '55px'}}/>,
                <div style={{ padding: '4px' , paddingLeft: '15px'}}/>,
              ],
                url: <Skeleton variant="text" sx={{ fontSize: '1rem' }} />,
                url_without_params: <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
              }
            ]
          }
        ]
      }))
      const url = '/api/get-user-journey';
      const params = {
        id: props.companyID,
        user_name: fullName,
        'start-date': props.currentStartDate,
        'end-date': props.currentEndDate,
        filters: props.filters,
      };

      const response = await axios.post(url, params);
      const result = await response.data;
      if (result == 'ignore') {
        console.log('ignore')
      } else {
        setCurrentUserJourney((prevState) => ({
          ...prevState,
          userJourney: result
        }))
        console.log(currentUserJourney)
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  const getUsersCount = async () => {
    try {
      const url = '/api/get-data-rows-count';
      const params = {
        id: props.companyID,
        'start-date': props.currentStartDate,
        'end-date': props.currentEndDate,
        page: pagination.pageIndex,
        search: props.searchTerm,
        column_filter: columnFilters,
        sorting: sorting,
        filters: props.filters,
        data_type: dataType
      };

      const response = await axios.post(url, params);
      const result = await response.data;
      if (result == 'ignore') {
        console.log('ignore')
      } else {
        setRowCount(result)
        console.log(result)
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };



  useEffect(() => {
    const getUsers = async () => {
      try {

        const url = props.API_URL;
        const params = {
          id: props.companyID,
          'start-date': props.currentStartDate,
          'end-date': props.currentEndDate,
          page: pagination.pageIndex,
          search: props.searchTerm,
          column_filter: columnFilters,
          sorting: sorting,
          filters: props.filters,
          data_type: dataType
        };

        const response = await axios.post(url, params);
        const result = await response.data;
        if (result == 'ignore') {
          console.log('ignore')
        } else {
          console.log(result)
          props.setFilteredData(result);
          setShowSkeletons(false);
        }
      } catch (error) {
        console.error('An error occurred:', error);
      }
    };
    setShowSkeletons(true)
    getUsers();
    getUsersCount();
  }, [pagination.pageIndex, props.searchTerm, props.companyID, props.currentStartDate, props.currentEndDate, columnFilters, sorting, props.filters, dataType]);

  const journeyColumnNameList = ['date', 'first_name', 'last_name', 'url', 'facebook', 'linked_in', 'twitter', 'email', 'opt_in', 'opt_in_date', 'opt_in_ip', 'opt_in_url', 'pixel_first_hit_date', 'pixel_last_hit_date', 'bebacks', 'phone', 'dnc', 'age', 'gender', 'marital_status', 'address', 'city', 'state', 'zip', 'household_income', 'net_worth', 'income_levels', 'people_in_household', 'adults_in_household', 'children_in_household', 'veterans_in_household', 'education', 'credit_range', 'ethnic_group', 'generation', 'home_owner', 'occupation_detail', 'political_party', 'religion', 'children_between_ages0_3', 'children_between_ages4_6', 'children_between_ages7_9', 'children_between_ages10_12', 'children_between_ages13_18', 'behaviors', 'children_age_ranges', 'interests', 'owns_amex_card', 'owns_bank_card', 'dwelling_type', 'home_heat_type', 'home_price', 'home_purchased_years_ago', 'home_value', 'household_net_worth', 'language', 'mortgage_age', 'mortgage_amount', 'mortgage_loan_type', 'mortgage_refinance_age', 'mortgage_refinance_amount', 'mortgage_refinance_type', 'is_multilingual', 'new_credit_offered_household', 'number_of_vehicles_in_household', 'owns_investment', 'owns_premium_amex_card', 'owns_premium_card', 'owns_stocks_and_bonds', 'personality', 'is_political_contributor', 'is_voter', 'premium_income_household', 'urbanicity', 'maid', 'maid_os', 'hour']

  const userColumnNameList = ['date', 'first_name', 'last_name', 'email', 'facebook', 'linked_in', 'twitter', 'opt_in', 'opt_in_date', 'opt_in_ip', 'opt_in_url', 'pixel_first_hit_date', 'pixel_last_hit_date', 'bebacks', 'phone', 'dnc', 'age', 'gender', 'marital_status', 'address', 'city', 'state', 'zip', 'household_income', 'net_worth', 'income_levels', 'people_in_household', 'adults_in_household', 'children_in_household', 'veterans_in_household', 'education', 'credit_range', 'ethnic_group', 'generation', 'home_owner', 'occupation_detail', 'political_party', 'religion', 'children_between_ages0_3', 'children_between_ages4_6', 'children_between_ages7_9', 'children_between_ages10_12', 'children_between_ages13_18', 'behaviors', 'children_age_ranges', 'interests', 'owns_amex_card', 'owns_bank_card', 'dwelling_type', 'home_heat_type', 'home_price', 'home_purchased_years_ago', 'home_value', 'household_net_worth', 'language', 'mortgage_age', 'mortgage_amount', 'mortgage_loan_type', 'mortgage_refinance_age', 'mortgage_refinance_amount', 'mortgage_refinance_type', 'is_multilingual', 'new_credit_offered_household', 'number_of_vehicles_in_household', 'owns_investment', 'owns_premium_amex_card', 'owns_premium_card', 'owns_stocks_and_bonds', 'personality', 'is_political_contributor', 'is_voter', 'premium_income_household', 'urbanicity', 'maid', 'maid_os', 'hour']



  useEffect(() => {
    setPagination((prevState) => ({
      ...prevState,
      pageIndex: 0
    }))
  }, [columnFilters]);


  const columns = useMemo(() => {
    let columnList;
    if (dataType === 'users') {
      columnList = userColumnNameList;
    } else if (dataType === 'journeys') {
      columnList = journeyColumnNameList;
    }

    return columnList.map((columnName) => ({
      accessorKey: columnName,
      header: columnName === 'first_name' ? 'First Name' : columnName === 'last_name' ? 'Last Name' : columnName,
    }));
  }, [dataType]);

  return (
    <>
      <SlidingPane
        className="pane-main"
        overlayClassName="pane-overlay"
        isOpen={isPaneOpen.isPaneOpen}
        title="Hey, it is optional pane title.  I can be React component too."
        subtitle="Optional subtitle."
        width='500px'
        onRequestClose={() => {
          // triggered on "<" on left top click or on outside click
          setIsPaneOpen({ isPaneOpen: false });
        }}
      >
       <SidePanelUserJourney currentUserJourney={currentUserJourney} />
      </SlidingPane>
      <MaterialReactTable
        columns={columns}
        data={props.filteredData}
        enableGlobalFilter={false}
        enableFullScreenToggle={false}
        enableDensityToggle={false}
        initialState={{ density: 'compact' }}
        enableSorting={false}
        manualPagination
        manualFiltering
        enableRowActions={enableActions}
        rowCount={rowCount}
        onPaginationChange={setPagination} //hoist pagination state to your state when it changes internally
        onColumnFiltersChange={setColumnFilters}
        onGlobalFilterChange={setGlobalFilter}
        onSortingChange={setSorting}
        muiSkeletonProps={{ animation: 'wave'}}
        muiPaginationProps={{ showFirstButton: false , showLastButton: false, showRowsPerPage: false, rowsPerPageOptions: false}}
        state={{
          pagination,
          showSkeletons,
          showProgressBars: showSkeletons,
          columnFilters,
          globalFilter,
          sorting,
          showLoadingOverlay: false
        }} //pass the pagination state to the table
        muiTableProps={{
          sx: {
            //border: '2px solid black',
          },
        }}
        muiTopToolbarProps={{
          sx: {
            margin: 2,
            borderRadius: '5px',
            '.MuiCollapse-vertical': {
              display: 'none'
            },
            '.MuiBox-root': {}
          },
        }}
        muiTablePaginationProps={{
          showFirstButton: false,
          showLastButton: false,
          sx: {
            '.MuiInputBase-root': {
              display: 'none'
            },
            '.MuiTablePagination-selectLabel': {
              display: 'none'
            },
            '.MuiTablePagination-displayedRows': {
              display: 'none'
            },
            '.MuiTablePagination-toolbar': {
              // width: '50%',
              // color: 'rgb(41, 39, 39)',
              // height: '35px',
              // paddingRight: '50%',
              // paddingTop: '15px',
              // gap: '50%'
            },
          }
        }}
        renderRowActions={({ row }) => {
          return (
            <div>
              <IconButton sx={{ height: '3px' }} onClick={() => {
                // console.log(row.original.full_name) // get user full name
                getUserJourney(row.original.full_name)
                setIsPaneOpen({ isPaneOpen: true })
              }}>
                <TimelineIcon />
              </IconButton>
            </div>

          )
        }}
        displayColumnDefOptions = { {'mrt-row-actions': { header: '' }} }
        renderTopToolbarCustomActions={({ table }) => {
          return (
            <div style={{ display: 'flex', gap: '10px', marginTop: '0px' }}>
              <div >
                {props.exportBtn}
              </div>
            </div>
          );
        }}
      />

    </>
  );
}

export default PeopleTable;


