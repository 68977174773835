import React from 'react'
import Popup from 'reactjs-popup';
import Button from '@mui/material/Button';
import { InputLabel, TextField } from '@mui/material';
import { MenuItem } from 'react-pro-sidebar';
import { useState } from 'react';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import { Form } from 'react-router-dom';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';




function Contains(props) {

    const [selectedValue, setSelectedValue] = useState()
    const [includeType, setIncludeType] = useState('start')
    const [excludeType, setExcludeType] = useState('start')
    const [numOfTextFields, setOfTextFields] = useState(2)
    const [ includeDict, setIncludeDict] = useState({
        0:'start',
    })
    const [ excludeDict, setExcludeDict] = useState({
        0:'start',
    })
    const [ fieldValueIncludeDict ,  setFieldValueIncludeDict ] = useState({
        0: '',
    })

    const [ fieldValueExcludeDict ,  setFieldValueExcludeDict ] = useState({
        0: '',
    })

    const handleContainsChange = (event, index) => {
        const { id , value } = event.target;
        const name = id

        console.log(name)
        if (name === 'include') {
            let items = props.contains.include_array
            let item = {...items[index]}
            item.type = includeDict[index]
            item.value = value
            items[index] = item

            setFieldValueIncludeDict((prevState) => ({
                ...prevState,
                [index] : value
            }))
    
            props.setContains((prevState) => ({
                ...prevState,
                include_array: items
        }))
        } else if (name === 'exclude') {
            let items = props.contains.exclude_array
            let item = {...items[index]}
            item.type = excludeDict[index]
            item.value = value
            items[index] = item

            setFieldValueExcludeDict((prevState) => ({
                ...prevState,
                [index] : value
            }))
    
    
            props.setContains((prevState) => ({
                ...prevState,
                exclude_array: items
        }))
        }


    }

    const handleNewFieldClick = (event) => {
        const name  = event.target.id

        console.log( name )


        if (name === 'include') {
            const numOfFields = Object.keys(includeDict).length

            if (numOfFields === 5) {
                console.log('too much!!')
            } else {
                setIncludeDict((prevState) => ({
                    ...prevState,
                    [numOfFields]: 'start'
                }))
            }
        } else if (name === 'exclude') {
            const numOfFields = Object.keys(excludeDict).length

            if (numOfFields === 5) {
                console.log('too much!!')
            } else {
                setExcludeDict((prevState) => ({
                    ...prevState,
                    [numOfFields]: 'start'
                }))
            }
        }

    }

    const handleDeleteFieldClick = (event) => {
        const name  = event.target.id

        console.log( name )

        if (name === 'include') {
            const numOfFields = Object.keys(includeDict).length
        
            if (numOfFields === 1) {
                console.log('no!')
            } else {
                setFieldValueIncludeDict((prevState) => {
                    const newState = {...prevState}
                    delete newState[numOfFields-1]
                    return newState
                })
                setIncludeDict((prevState) => {
                    const newState = { ...prevState };
                    delete newState[numOfFields - 1];
                    return newState;
                });
            }
        } else if (name === 'exclude') {
            const numOfFields = Object.keys(excludeDict).length
        
            if (numOfFields === 1) {
                console.log('no!')
            } else {
                setFieldValueExcludeDict((prevState) => {
                    const newState = {...prevState}
                    delete newState[numOfFields-1]
                    return newState
                })
                setExcludeDict((prevState) => {
                    const newState = { ...prevState };
                    delete newState[numOfFields - 1];
                    return newState;
                });
            }
        }
        
    }

    const handleFieldTypeChange = (event, index) => {

        console.log(event.target)

        if (event.target.name == 'include') {
            let items = props.contains.include_array
        
            let item = {...items[index]}

            item.type = event.target.value

            items[index] = item

            props.setContains((prevState) => ({
                ...prevState,
                include_array: items
        }))

            console.log(props.contains)

            setIncludeDict((prevState) => ({
                ...prevState,
                [index] : event.target.value
            }))
        } else if (event.target.name == 'exclude') {
            let items = props.contains.exclude_array
        
            let item = {...items[index]}

            item.type = event.target.value

            items[index] = item

            props.setContains((prevState) => ({
                ...prevState,
                exclude_array: items
        }))

            console.log(props.contains)

            setExcludeDict((prevState) => ({
                ...prevState,
                [index] : event.target.value
            }))
        }
    };

    const handleContainsDoneClick = (close) => {
        const id = 'contains';
        const end_value = props.contains;
        



        props.setColumnFilters((prevState) => {
            // Find the index of the object with the same id in the array
            const index = prevState.findIndex((filter) => filter.id === id && filter.value.name === end_value.name);

            // If an object with the same id is found, update it
            if (index !== -1) {
                if (end_value.include_array.length === 0) {
                    // If the end_value is empty, remove the filter by filtering out the one with the matching id
                    return prevState.filter((filter) => filter.value.name !== end_value.name);
                }

                // If an object with the same id and name is found and the end_value is not empty, update it
                return prevState.map((filter, i) =>
                    i === index ? { ...filter, value: end_value } : filter
                );
            } else {
                if (end_value.include_array.length > 0 || end_value.exclude_value.length > 0) {
                    // If not found and end_value is not empty, add a new object to the array
                    return [...prevState, { id, value: end_value }];
                } else {
                    // If not found and end_value is empty, return the previous state (no change)
                    console.log('asd')
                    return prevState;
                }
            }
        })

        localStorage.removeItem('users_list')
        setSelectedValue(props.contains.value)
        close()
    }


    return (
        <Popup
            trigger={<MenuItem icon={props.icon} style={{ display: 'flex', flexDirection: 'row', borderWidth: '0px 0px 1px 0px', borderStyle: 'solid', borderColor: 'gainsboro' }}>
                <div className='item-name'>
                    {props.menuItemName}
                </div>
                <div className='selected-value' style={{ float: 'left', color: 'gray' }}>
                    {selectedValue}
                </div>
            </MenuItem>}
            position="buttom center"
            nested
            contentStyle={{ width: '350px', overflowY: 'auto', marginLeft: '-80px', marginTop: '130px' }}
        >
            {close => (
                <div className='popup-url-container'>
                    <div className={`popup-${props.name}`}>
                            <p style={{ marginLeft: '10px' }}>Include</p>
                            {Array.from({ length: Object.keys(includeDict).length }).map((_, index) => (
                                    <div className='field-type' style={{ display: 'flex' }}>
                                        <Select
                                            value={includeDict[index]}
                                            name='include'
                                            onChange={(event) => handleFieldTypeChange(event,index)}
                                            displayEmpty
                                            sx={{ width: '50%', marginTop: '10.5px', marginRight: '5px', width: '150px', maxWidth: '140px', height: '40px' }}
                                        >
                                            <MenuItem value={'start'}>Starts With</MenuItem>
                                            <MenuItem value={'end'}>Ends With</MenuItem>
                                            <MenuItem value={'contain'}>Contains</MenuItem>
                                            <MenuItem value={'exact'}>Exact Match</MenuItem>
                                            <MenuItem value={'notequal'}>Does Not Equal</MenuItem>
                                        </Select>
                                        <TextField
                                            id="include"
                                            value={fieldValueIncludeDict[index]}
                                            label={props.label}
                                            name={props.name}
                                            onChange={(event) => handleContainsChange(event,index)}
                                            variant="outlined"
                                            size='small'
                                            sx={{ marginTop: '10px' }}
                                        />
                                    </div>

                            ))}
                            <div style={{ display: 'flex', gap: '5px', marginTop: '5px' }}>
                                        <Skeleton id = 'include' onClick={(event) => handleDeleteFieldClick(event)} variant="rounded" width={245} height={30} sx={{ color: '#1876d2', textAlign: 'center', paddingTop: '2px', cursor: 'pointer' }}> Delete</Skeleton>
                                        <Skeleton  id = 'include' onClick={(event) => handleNewFieldClick(event)} variant="rounded" width={338} height={30} sx={{ color: '#1876d2', textAlign: 'center', paddingTop: '2px', cursor: 'pointer' }}> Add New </Skeleton>
                                    </div>
                            <p style={{ marginLeft: '10px', marginTop: '15px' }}>Exclude</p>
                            {Array.from({ length: Object.keys(excludeDict).length }).map((_, index) => (
                                <div style={{ display: 'flex' }}>
                                <Select
                                    value={excludeDict[index]}
                                    onChange={(event) => handleFieldTypeChange(event,index)}
                                    displayEmpty
                                    name='exclude'
                                    sx={{ width: '50%', marginTop: '10.5px', marginRight: '5px', width: '140px', height: '40px', maxWidth: '140px' }}
                                >
                                    <MenuItem value={'start'}>Starts With</MenuItem>
                                    <MenuItem value={'end'}>Ends With</MenuItem>
                                    <MenuItem value={'contain'}>Contains</MenuItem>
                                    <MenuItem value={'exact'}>Exact Match</MenuItem>
                                </Select>
                                <TextField
                                    id="exclude"
                                    value={fieldValueExcludeDict[index]}
                                    label={'URL'}
                                    name={props.name}
                                    onChange={(event) => handleContainsChange(event,index)}
                                    variant="outlined"
                                    size='small'
                                    sx={{ marginTop: '10px' }}
                                />
                            </div>
                            ))}
                            <div style={{ display: 'flex', gap: '5px', marginTop: '5px' }}>
                                        <Skeleton id='exclude' onClick={(event) => handleDeleteFieldClick(event)} variant="rounded" width={245} height={30} sx={{ color: '#1876d2', textAlign: 'center', paddingTop: '2px', cursor: 'pointer' }}> Delete</Skeleton>
                                        <Skeleton id='exclude' onClick={(event) => handleNewFieldClick(event)} variant="rounded" width={338} height={30} sx={{ color: '#1876d2', textAlign: 'center', paddingTop: '2px', cursor: 'pointer' }}> Add New </Skeleton>
                                    </div>
                    </div>
                    <Button onClick={() => handleContainsDoneClick(close)}> Done </Button>
                </div>
            )}
        </Popup>
    )
}

export default Contains
