import React, { useState, useEffect } from 'react'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import RouteIcon from '@mui/icons-material/Route';


function PeopleCount(props) {
    const [count, setCount] = useState({
        peopleCount: 0,
        journeyCount: 0
    })
    useEffect(() => {
        setCount({
            peopleCount: props.peopleCount,
            journeyCount: props.journeyCount
        })
      }, [props.peopleCount, props.journeyCount]);
    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '80px', color: 'white'}}>
                <p className='title' >People Counts</p>
                <div className='people-count-data' style={{ display: 'flex', justifyContent: 'center', gap: '10%'}}>
                    <div className='peopel-count' style={{ display: 'flex', gap:'30px',flexDirection: 'column', textAlign: 'center'}}>
                        <p className='count-icon' style={{ transform: 'scale(2.5)'}}>
                            <AccountCircleIcon />
                        </p>
                        <p className='count-number' style={{ fontSize: '19px'}}>{count.peopleCount}</p>
                        <p className='count-label' style={{ fontSize: '15px', marginTop: '-40px'}}>Total Visitors Captured</p>
                    </div>
                    <div className='journey-count' style={{ display: 'flex', gap:'30px',flexDirection: 'column', textAlign: 'center'}}>
                    <p className='count-icon' style={{ transform: 'scale(2.5)'}}>
                            <RouteIcon />
                        </p>
                        <p className='count-number' style={{ fontSize: '19px'}}>{count.journeyCount}</p>
                        <p className='count-label' style={{ fontSize: '15px', marginTop: '-40px'}}>Total Journeys Captured</p>
                    </div>
                </div>
            </div>
    )
}

export default PeopleCount
