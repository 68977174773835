import React, { useState } from 'react'
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Skeleton from '@mui/material/Skeleton';


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: 'whitesmoke',
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));


function SidePanelUserJourney(props) {
    function formatDateString(inputDateString) {
        if (typeof inputDateString == 'string'){
            // Create a Date object from the input string
        const date = new Date(inputDateString);
      
        // Get day, month, and year
        const day = date.getDate();
        const month = date.toLocaleString('default', { month: 'long' });
        const year = date.getFullYear();
      
        // Add ordinal suffix to the day
        const dayWithSuffix = addOrdinalSuffix(day);
      
        // Assemble the formatted date string
        const formattedDateString = `${dayWithSuffix} ${month} ${year}`;
      
        return formattedDateString;
        }
      }
      
      // Function to add ordinal suffix to the day
      function addOrdinalSuffix(day) {
        if (day >= 11 && day <= 13) {
          return `${day}th`;
        }
      
        switch (day % 10) {
          case 1:
            return `${day}st`;
          case 2:
            return `${day}nd`;
          case 3:
            return `${day}rd`;
          default:
            return `${day}th`;
        }
      }


      function formatTimeString(inputTimeString) {
        if (typeof inputTimeString == 'string'){
                    // Split the input time string
        const [hours, minutes, seconds] = inputTimeString.split(':');
      
        // Convert hours to 12-hour format
        const formattedHours = (hours % 12) || 12;
      
        // Assemble the formatted time string
        const formattedTimeString = `${formattedHours}:${minutes} ${hours < 12 ? 'AM' : 'PM'}`;
      
        return formattedTimeString;
        }
      }

    console.log(props.currentUserJourney)
    return (
        <div className='side-panel'>
            <div className='side-panel-title'>
                <h2>
                    {props.currentUserJourney.userName}
                </h2>
                <p>
                    View all the details of this user journey.
                </p>
            </div>
            <div className='side-panel-top-container'>
                <p style={{ fontWeight: '500', fontSize: '18px' }}>
                    User Journey Details
                </p>
                {props.currentUserJourney.userJourney.map((mainJourney, index) => (
                <div className='side-panel-user-journey' style={{ padding: '13px', borderRadius: '5px', borderStyle: 'solid', borderColor: 'gainsboro', marginTop: '10px' }}>
                <div className='side-panel-user-journey-date' style={{ fontWeight: '500', fontSize: '15px' }}>
                <p style={{ fontSize: '12px', fontWeight: '600', color: 'GrayText', marginBottom: '3px' }}> DATE </p>

                    {typeof mainJourney.date == 'string' ? (
                        formatDateString(mainJourney.date)
                    ) : (
                        mainJourney.date
                    )}
                </div>
                <Divider sx={{ marginTop: '10px', background: 'gray' }} />
                {mainJourney.documents.map((journey, index) => (
                    <div className='side-panel-user-journey-body' style={{ marginTop: '2%' }}>
                        <div className='side-panel-user-journey-body-url' style={{ wordWrap: 'break-word' }}>
                            <p style={{ fontSize: '12px', fontWeight: '600', color: 'GrayText', marginBottom: '3px' }}> URL </p>
                            {journey.url_without_params}
                        </div>
                        {journey.url_without_params == journey.parameters_list[0] ? (
                            <div></div>
                        ) : (
                            <div className='side-panel-user-journey-body-parameters' style={{ marginTop: '3%', overflowX: 'auto' }}>
                                <p style={{ fontSize: '12px', fontWeight: '600', color: 'GrayText', marginBottom: '3px' }}> PARAMETERS </p>
                                <Box sx={{ flexGrow: 1 }}>
                                    <Grid container spacing={1}>
                                        {journey.parameters_list.map((param, index) => (
                                            <Grid item xs="auto">
                                                
                                                    <Item variant='outlined' sx={{ fontSize: '12px' }}>
                                                        {param}
                                                    </Item>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Box>
                            </div>
                        )}

                        <div className='side-panel-user-journey-body-ip' style={{ marginTop: '3%' }}>
                            <p style={{ fontSize: '12px', fontWeight: '600', color: 'GrayText', marginBottom: '3px' }}> IP ADDRESS </p>
                            <p style={{ fontWeight: '500', fontSize: '15px' }} > {journey.opt_in_ip} </p>
                        </div>
                        <div className='side-panel-user-journey-body-hour' style={{ marginTop: '3%' }}>
                            <p style={{ fontSize: '12px', fontWeight: '600', color: 'GrayText', marginBottom: '3px' }}> {typeof mainJourney.date == 'string' ? (
                                    formatTimeString(journey.hour)
                                ) : (
                                    journey.hour
                                )} </p>
                        </div>
                        <Divider sx={{ marginTop: '10px', background: 'gainsboro' }} />
                    </div>
                ))}
            </div>
                ))}
            </div>
        </div>
    )
}

export default SidePanelUserJourney
