import React from 'react'
import { useState, useEffect } from 'react'
import { DateRangePicker, defaultStaticRanges } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import './DateRangeStyle.css'
import { addYears, startOfYear, endOfYear, addDays } from 'date-fns';


const renderStaticRangeLabel = () => (
    <CustomStaticRangeLabelContent text={'This is a custom label content: '} />
  );

class CustomStaticRangeLabelContent extends React.Component {
    constructor(props) {
      super(props);
  
      this.state = {
        currentDateString: Date(),
      };
  
      this.intervalId = setInterval(() => {
        this.setState({
          currentDateString: Date(),
        });
      }, 1000);
    }
  
    componentWillUnmount() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
      }
    }
  
    render() {
      const { currentDateString } = this.state;
      const { text } = this.props;
  
      return (
        <span>
          <i>{text}</i>
          <span className={'random-date-string'}>
            <b>{currentDateString}</b>
          </span>
        </span>
      );
    }
  }

function DateRangePick(props) {
    const [state, setState] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection'
        }
    ]);

    const [dateString, setDateString] = useState(`Select A Range`)

    function formatDate(inputDate) {
        const date = new Date(inputDate);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is 0-based
        const day = String(date.getDate()).padStart(2, '0');

        const dateWithYear = `${year}-${month}-${day}`
        const dateNoYear = `${month}-${day}`
      
        return [dateWithYear,dateNoYear];

      }

      const updateDateRange = (startDate, endDate) => {
        props.setDashboardDetails((prevState) => ({
          ...prevState, // Spread the previous state
          start_date: startDate, // Modify the 'age' field
          end_date: endDate
        }));
      };

    const handleDoneClick = (close) => {
        updateDateRange(formatDate(state[0].startDate)[0],formatDate(state[0].endDate)[0])

        close();

    }
    useEffect(() => {
        if (props.currentStartDate === undefined ) {
            setDateString('Select A Range')
        } else {
            setDateString(`${props.currentStartDate} - ${props.currentEndDate}`)
        }
        console.log(defaultStaticRanges)
      }, [props.currentStartDate, props.currentEndDate]);

    useEffect(() => {
        if (defaultStaticRanges.length === 6) {
            defaultStaticRanges.push(
                {
                    label: 'This Year',
                    range: function range() {
                      const startDate = startOfYear(addYears(new Date(), 0));
                      const endDate = new Date();
              
                      return {
                        startDate,
                        endDate,
                      };
                    },
                    isSelected: function isSelected() {
                      return false;
                    },
                  },
                {
                label: 'Last Year',
                range: function range() {
                  const startDate = startOfYear(addYears(new Date(), -1));
                  const endDate = endOfYear(addYears(new Date(), -1));
          
                  return {
                    startDate,
                    endDate,
                  };
                },
                isSelected: function isSelected() {
                  return false;
                },
              }
              );
        }
    }, [])
    return (
        <div>
            <Popup contentStyle={{width: '900px', borderRadius: '5px', paddingTop: '10px'}} trigger={
            <button className='date-range-display'>
                <CalendarTodayIcon style={{ marginRight: '13px' }}/>{dateString}
            </button>
        } modal nested>
                {close => (
                    <div className='date-range-popup' >
                        <DateRangePicker
                            className='date-picker-comp'
                            onChange={item => setState([item.selection])}
                            showSelectionPreview={true}
                            moveRangeOnFirstSelection={false}
                            months={2}
                            ranges={state}
                            direction="horizontal"
                            color='#1abce8'
                            staticRanges={defaultStaticRanges}
                            />
                        <button className='pop-up-apply'onClick={() => handleDoneClick(close)} style={{float: 'right'}}> Done </button>
                    </div>
                )}
            </Popup>
        </div>
    )
}

export default DateRangePick
