import { createContext, useContext, useState } from 'react';

const StateContext = createContext();

export const StateProvider = ({ children }) => {
    const [sideBarArray, setSideBarArray] = useState([]);
    const [companiesList , setCompaniesList ] = useState([]);
    const [ snackBar , setSnackBar ] = useState(false)



return (
    <StateContext.Provider value={{ 
        sideBarArray, 
        setSideBarArray, 
        companiesList, 
        setCompaniesList,
        snackBar,
        setSnackBar
        }}>
      {children}
    </StateContext.Provider>
  );
}

export default StateContext;