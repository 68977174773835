import React, { useState, useEffect } from 'react'
import TopBar from '../CompanyDashboardComponents/TopBar'
import Popup from 'reactjs-popup';
import GradingIcon from '@mui/icons-material/Grading';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import CompanyCompareUTMSelection from '../CompanyDashboardComponents/CompanyCompareUTMSelection';
import { Button } from '@mui/material';


function CompanyCompareUTM(props) {
    const [ parameterValues, setParameterValues ] = useState([''])
    const [ selectioOneDone , setSelectOneDone ] = useState(false)
    const [ valueSelectedOne , setValueSelectedOne ] = useState(true)
    const [ valueSelectedTwo , setValueSelectedTwo ] = useState(true)
    const [currentSelection, setCurrentSelection] = useState("source")
    const [parameterLoading, setParameterLoading] = useState(false)
    const delay = ms => new Promise(res => setTimeout(res, ms));

    const companyID = useParams().companyId
    const segmentID = useParams().segmentID

    const handleChange = (event, newAlignment) => {
        if (!selectioOneDone) {
            props.setUtmSelection((prevState) => ({
                ...prevState,
                selection_one: {
                    selection: newAlignment,
                    values: [],
                }
            }))
        } else {
            props.setUtmSelection((prevState) => ({
                ...prevState,
                selection_two: {
                    selection: newAlignment,
                    values: [],
                }
            }))
        }
        setCurrentSelection(newAlignment)
    };

    const handleChecked = (value) => {
        if (!selectioOneDone) {
            if (props.utmSelection.selection_one.values.includes(value)) {
                return true
            } else {
                return false
            }
        } else {
            if (props.utmSelection.selection_two.values.includes(value)) {
                return true
            } else {
                return false
            }
        }
    }

    const handleValueClick = (value) => {
        let newArray = null;
        if (!selectioOneDone) {
            if (props.utmSelection.selection_one.values.includes(value)) {
                // If the value is already in the array, filter it out
                newArray = props.utmSelection.selection_one.values.filter(item => item !== value);
            } else {
                // If the value is not in the array, create a new array with the value
                newArray = [...props.utmSelection.selection_one.values, value];
            }
        
            props.setUtmSelection((prevState) => ({
                ...prevState,
                selection_one : {
                    ...prevState.selection_one,
                    values: newArray
                }
            }));
        } else {
            if (props.utmSelection.selection_two.values.includes(value)) {
                // If the value is already in the array, filter it out
                newArray = props.utmSelection.selection_two.values.filter(item => item !== value);
            } else {
                // If the value is not in the array, create a new array with the value
                newArray = [...props.utmSelection.selection_two.values, value];
            }
        
            props.setUtmSelection((prevState) => ({
                ...prevState,
                selection_two : {
                    ...prevState.selection_two,
                    values: newArray

                }
            }));
        }
        props.setUtmDone(false)
    };

    const handleDoneClick = (close) => {
        props.setUtmDone(true)
        close()
        setSelectOneDone(false)
    }

    const handleNextClick = async () => {
        setParameterLoading(true)
        await delay(1500)
        setParameterLoading(false)
        setSelectOneDone(true)
    }

    const getParameterValues = async () => {
        try {
            setParameterLoading(true)
            const url = '/api/get-company-utm';
            const params = {
                company_id: companyID,
                segment_id: segmentID,
                selection: currentSelection,
                filters: props.segmentFilters,
            };
            const response = await axios.post(url, params);
            const result = response.data;
            setParameterValues(result)
        } catch (error) {
            console.error('An error occurred:', error);
        } finally {
            setParameterLoading(false)
        }
    };

    useEffect(() => {
        getParameterValues()
    }, [props.utmSelection.selection_one.selection, props.utmSelection.selection_two.selection])


    useEffect(() => {
        if (props.utmSelection.selection_one.values.length !== 0) {
            setValueSelectedOne(false)
        } 
        if (props.utmSelection.selection_two.values.length !== 0) {
            setValueSelectedTwo(false)
        } 
        if (props.utmSelection.selection_one.values.length === 0) {
            setValueSelectedOne(true)
        } 
        if (props.utmSelection.selection_two.values.length === 0) {
            setValueSelectedTwo(true)
        }
        console.log(props.utmSelection.selection_two.values.length)
    }, [props.utmSelection])

    return (
        <div style={{ display: 'flex', gap: '20px' }}>
            <div style={{ display: 'flex', gap: '10px' }}>
                <Popup contentStyle={{ borderRadius: '20px', paddingTop: '10px' }} trigger={
                    <button className='date-range-display' >
                        <GradingIcon style={{ marginRight: '13px' }} />{`UTM Selection`}
                    </button>
                } modal nested>
                    {close => (
                        selectioOneDone === false ? (  
                        <>
                        <CompanyCompareUTMSelection 
                        utmSelection={props.utmSelection} 
                        handleChecked={handleChecked} 
                        handleValueClick={handleValueClick}
                        parameterValues={parameterValues}
                        handleChange={handleChange}
                        utmSelectionProp={props.utmSelection.selection_one}
                        currentSelection={currentSelection}
                        parameterLoading={parameterLoading}
                        title={'Selection One UTM'}
                        />
                        <Button variant="contained" disabled={valueSelectedOne} sx={{ float: 'right', marginTop: '7%',width: '30%',marginBottom: '5%'}} onClick={handleNextClick} >Next</Button>
                        </>                      
                    ) : (    
                        <>
                        <CompanyCompareUTMSelection 
                        utmSelection={props.utmSelection} 
                        handleChecked={handleChecked} 
                        handleValueClick={handleValueClick}
                        parameterValues={parameterValues}
                        handleChange={handleChange}
                        utmSelectionProp={props.utmSelection.selection_two}
                        currentSelection={currentSelection}
                        parameterLoading={parameterLoading}
                        title={'Selection Two UTM'}
                        />
                        <Button variant="contained" disabled={valueSelectedTwo} sx={{ float: 'right', marginTop: '7%',width: '30%',marginBottom: '5%'}} onClick={() => handleDoneClick(close)} >Done</Button>
                        </>                    
                        
                        )
                    )}
                </Popup>
            </div>
        </div>
    )
}

export default CompanyCompareUTM
