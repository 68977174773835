import React from 'react';
import { Link } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Tooltip from '@mui/material/Tooltip';

function TopBar(props) {



  return (

    <div>
      <div className='text-end'>
        <nav className="navbar bg-body-tertiary">
          <div className="container-fluid">
            <div className="navbar-brand" style={{ display: 'flex', gap: '10px'}}>
              <Tooltip title='Back'>
                <Link to={props.backAmount} onClick={() => props.func}>
                  <ArrowBackIosIcon/>
                </Link>
              </Tooltip>
              <div className='name' >
                {props.companyName}
              </div>
            </div>
            {props.elements}
          </div>
        </nav>
      </div>
    </div>
  )
}

export default TopBar

//   < Tooltip title = "Edit Company" >
//     <IconButton className="company-edit-display">
//       <EditNoteIcon style={{ color: 'gray' }} />
//     </IconButton>
//    </Tooltip >