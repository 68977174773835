import React, { useState, useEffect } from 'react';
import Popup from 'reactjs-popup';
import EditNoteIcon from '@mui/icons-material/EditNote';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import axios from 'axios';

function CompanyEdit(props) {
    const token = localStorage.getItem("token")
    const [companyData, setCompanyData] = useState({
        companyName: props.item.company_details?.company_name || '',
        companyEmail: props.item.company_details?.company_email || '',
        attachedUsers: props.item.company_details?.attached_users || '',
    });


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCompanyData({ ...companyData, [name]: value });
    };

    const handleDoneClick = (close, companyID, attached_users) => {
        // Prepare the data to send to the API
        const updatedCompanyData = {
            company_id: companyID, // Replace with the correct key for the company ID
            new_company_data: {
                company_name: companyData.companyName,
                company_email: companyData.companyEmail,
                attached_users: attached_users.split('\n'),
            },
        };

        axios
            .post('/api/update-company', updatedCompanyData, {
                headers: {
                    Authorization: token, // Replace with your JWT token
                },
            })
            .then((response) => {
                // Handle a successful response, e.g., show a success message or update the UI
                // You can also close the popup here
                console.log('Company updated successfully');
                close();
            })
            .catch((error) => {
                // Handle errors, e.g., show an error message
                console.error('Error updating company:', error);
            });

        console.log(updatedCompanyData)

        close()
    }



    useEffect(() => {
        // Update the companyData state once props are available
        setCompanyData({
            companyID: props.item.company_details?._id || '',
            companyName: props.item.company_details?.company_name || '',
            companyEmail: props.item.company_details?.company_email || '',
            attachedUsers: props.item.company_details?.attached_users || '',
        });
    }, [props.item]);

    const formattedAttachedUsers = String(companyData.attachedUsers).replace(/,/g, '\n');


    return (
        <div>
            <Popup
                contentStyle={{ width: '300px', borderRadius: '5px', paddingTop: '10px', padding: '20px' }}
                trigger={
                    <Tooltip title="Edit Company">
                        <IconButton className="company-edit-display">
                            <EditNoteIcon style={{ color: 'gray' }} />
                        </IconButton>
                    </Tooltip>
                }
                modal
                nested
            >
                {(close) => (
                    <div className="company-edit-popup" style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                        <p>Company Details</p>
                        <TextField
                            type="text"
                            label="Company Name"
                            variant="outlined"
                            name="companyName"
                            value={companyData.companyName}
                            onChange={handleInputChange}
                        />
                        <TextField
                            type="text"
                            label="Company Email"
                            variant="outlined"
                            name="companyEmail"
                            value={companyData.companyEmail}
                            onChange={handleInputChange}
                        />
                        <TextField
                            type="text"
                            label="Attached Users"
                            variant="outlined"
                            name="attachedUsers"
                            value={formattedAttachedUsers}
                            onChange={handleInputChange}
                            multiline
                        />
                        <button className="pop-up-apply" onClick={() => handleDoneClick(close, companyData.companyID, formattedAttachedUsers)} style={{ float: 'right' }}>
                            Done
                        </button>
                    </div>
                )}
            </Popup>
        </div>
    );
}

export default CompanyEdit;
