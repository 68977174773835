import React, { useState, useEffect } from 'react';
import TopBar from '../CompanyDashboardComponents/TopBar';
import Stack from '@mui/material/Stack';
import CompanyEdit from '../DashboardComponents/CompanyEdit';
import { useOutletContext, Link, useNavigate, Outlet } from "react-router-dom";
import Skeleton from '@mui/material/Skeleton';
import jwt from 'jwt-decode' // import dependency
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
const USER_DETAIL_API = '/api/get-company-list';

function DashboardCompanies() {
  const [token, setToken_] = useState(localStorage.getItem("token"));
  const [role, setRole] = useState(jwt(token).user_role);
  const [companiesList, setCompanieslist] = useOutletContext()
  const [isLoading, setIsLoading] = useState(); // State to track loading
  const [userClicked, setUserClicked] = useState(false)

  const nav = useNavigate()


  const handleCompanyClick = (companyID, companyObject) => {
    console.log(companyObject)
    if (!JSON.parse(localStorage.getItem(companyID))) {
      localStorage.setItem(companyID, JSON.stringify({
        company_id: companyID,
        company_name: companyObject['company_details'].company_name,
        people_count: companyObject['counts'].people_count,
        journey_count: companyObject['counts'].journey_count,
        start_date: undefined,
        end_date: undefined,
        byPrecent: {
          items: [],
          counts: [0]
      },
      compareByPrecent: {
        items: [],
        counts: [0]
    },
        popularChart: {
          popular_array: [],
          popular_count_array: [0],
        },
        incomeBreakdown: {
          popular_array: [],
          popular_count_array: [0],
        },
        popularStateChart : {
          popular_array: [],
          popular_count_array: [0],
        },
        creditByPrecent: {
          items: [],
          counts: [0]
      },
        segment_list: []
      }))
    }

    localStorage.setItem('companyName',companyObject['company_details'].company_name)
  };


  useEffect(() => {
    if (localStorage.getItem('companyName')) {
      setCompanieslist([])
      nav(0)
      localStorage.removeItem('companyName')
    }
  }, []);



  return (
    <div className='companies-dashboard-page' style={{ height: '100vh'}}>
      <div className='dashboard-page'>
        <div className='top-bar' style={{ position: 'sticky', top: "0",zIndex: 2}}>
          <TopBar companyName={'Dashboard'} />
        </div>
        <div className='details' style={{
          backgroundColor: '#dcf2f6',
          display: 'flex',
          justifyContent: 'center'
        }}>
          <div className='company-list-container' style={{
            width: '97%',
            alignContent: 'center',
            marginTop: '20px',
          }}>
            <Stack spacing={2} sx={{ 
              
            }}>
              {companiesList.length == 0 ? ( // Check if data is loading
                // Render skeleton loading element
                <div>
                  <Stack spacing={2}>
                  <Skeleton variant="rectangular" width={'99%'} height={65} />
                  <Skeleton variant="rectangular" width={'99%'} height={65} />
                  <Skeleton variant="rectangular" width={'99%'} height={65} />
                  <Skeleton variant="rectangular" width={'99%'} height={65} />

                  </Stack>
                </div>
              ) : (
                // Render company items when data is available
                companiesList.map((item, index) => (
                  <div className='company-item' key={index} style={{
                    display: 'flex',
                    backgroundColor: 'white',
                    borderRadius: '5px',
                    paddingRight: '5px',
                    alignItems: 'center',
                  }}>
                    <Link
                      to={item.company_details && item.company_details._id}
                      key={index}
                      style={{ 
                        textDecoration: 'none', 
                        color: 'black',
                        display: 'flex',
                        width: '100%',
                        paddingRight: '20px',
                        borderRadius: '5px',
                        padding: '15px'
                      }}
                      onClick={() => handleCompanyClick(item.company_details && item.company_details._id, item)}
                    >
                      <div className='company-name' style={{ width: '25%', display: 'flex', flexDirection: 'column'}}>
                          <div style={{ fontSize: '12px', fontWeight: '600', color: 'gray'}}>
                          Company Name
                          </div>
                          {item.company_details && item.company_details.company_name}
                      </div>
                      <div className='peoples' style={{ marginLeft: '25%',  display: 'flex', flexDirection: 'column'}}>
                      <div style={{ fontSize: '12px', fontWeight: '600', color: 'gray'}}>
                          Users Captured
                          </div>
                      {item.counts && item.counts.people_count}
                    </div>
                    <div className='peoples' style={{ marginLeft: '25%',  display: 'flex', flexDirection: 'column'}}>
                    <div style={{ fontSize: '12px', fontWeight: '600', color: 'gray'}}>
                          Journeys Captured
                          </div>
                      {item.counts && item.counts.journey_count}
                    </div>
                    </Link>
                    {role === 'admin' || role === 'admin_customer' ? (
                      <div className='edit-icon'>
                        <CompanyEdit item={item} />
                      </div>
                    ) : (
                      <div className='edit-icon' style={{ pointerEvents: 'none', opacity: 0.0 }}>
                        <CompanyEdit item={item} />
                      </div>
                    )}
                    
                  </div>
                ))
              )}
            </Stack>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardCompanies;


