import React, { useState, useEffect } from 'react'
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Chart from "react-apexcharts";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';


function ByPrecent(props) {
  const nav = useNavigate()
    const [token, setToken_] = useState(localStorage.getItem("token"));
    const [ byPrecentField, setByPrecentField] = useState('age')
    const [ byPrecentList, setByPrecentList ] = useState(props.dashboardDetails.byPrecent)

    const state = {
        series: byPrecentList.counts,
        options: {
          plotOptions: {
            pie: {
              dataLabels: {
                minAngleToShowLabel: 20
            },
            }
          },
            chart: {
              width: 380,
              type: 'pie',
            },
            theme: {
                monochrome: {
                  enabled: true
                }
            },
            legend: {
                show: true
              },
            dataLabels: {
                formatter(val, opts) {
                  const name = opts.w.globals.labels[opts.seriesIndex]
                  return [name, val.toFixed(1) + '%']
                }
            },
            labels: byPrecentList.items,
            responsive: [{
              breakpoint: 480,
              options: {
                chart: {
                  width: 200
                },
              }
            }]
          },
        
    }

    const getByPrecent = async () => {
        try {
          const response = await axios.post(`/api/get-by-precent-counts`, {
            company_id: props.dashboardDetails.company_id,
            start_date: props.dashboardDetails.start_date,
            end_date: props.dashboardDetails.end_date,
            filters: (() => {
              console.log(props.dashboardDetails);
              // Check if segment and filters properties are present
              if (props.dashboardDetails.segment && props.dashboardDetails.segment.filters) {
                return props.dashboardDetails.segment.filters;
              } else {
                return undefined;
              }
            })(),
            segment_id: (() => {
              // Check if segment and filters properties are present
              if (props.dashboardDetails.segment && props.dashboardDetails.segment.segmentID) {
                return props.dashboardDetails.segment.segmentID;
              } else {
                return undefined;
              }
            })(), // Call the function to get the actual filters
            field: byPrecentField
          }, {
            headers: { Authorization: token }
          });
          if (response.status === 200) {
            const result = await response.data;
            console.log(result)
            setByPrecentList((prevState) => ({
              ...prevState,
              items: result.items,
              counts: result.counts,
            }));
            if (byPrecentField == 'age' && props.dashboardDetails.start_date == undefined) {
                localStorage.setItem(props.dashboardDetails.company_id, JSON.stringify({
                    ...JSON.parse(localStorage.getItem(props.dashboardDetails.company_id)),
                    byPrecent: {
                        items: result.items,
                        counts: result.counts,
                    }
                }))
                props.setDashboardDetails((prevState) => ({
                    ...prevState,
                    byPrecent: {
                        items: result.items,
                        counts: result.counts,
                    }
                }))
            }
            console.log(result)
          } else {
            console.error('Access denied:', response.statusText);
    
          }
        } catch (error) {
          if (error.request.status === 444) {
            nav(-1)
          } else {
            console.error(error)
          }
          console.log(error.request.status)
        }
      };

    const handleByPrecentFieldChange = (event) => {
        const value = event.target.value
    
        setByPrecentField(value)
    }
    useEffect(() => {
        getByPrecent()
      }, [props.dashboardDetails.start_date, props.dashboardDetails.end_date, byPrecentField]);
    
    return (
        <div className='by-precent-details' >
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <FormControl variant='standard' sx={{ marginTop: '2px', marginLeft: '20px' }}>
                    <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={byPrecentField}
                        onChange={handleByPrecentFieldChange}
                        label="Age"
                    >
                        <MenuItem value={'age'}>Age</MenuItem>
                        <MenuItem value={'gender'}>Gender</MenuItem>
                        <MenuItem value={'income_levels'}>Income</MenuItem>
                    </Select>
                </FormControl>
                <p className='by-precent-title' style={{fontWeight: 500, marginTop: '5px', marginLeft: '5px'}} >By Precent</p>
            </div>
            <div className='pie-chart-by-precent' style={{ }}>
                <Chart 
                options={state.options} 
                series={state.series}
                type="pie"
                width='100%'
                height='180%'
                />
            </div>
        </div>
    )
}

export default ByPrecent
