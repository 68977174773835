import React from 'react'
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Popup from 'reactjs-popup';
import Button from '@mui/material/Button';
import { InputLabel, TextField } from '@mui/material';
import { MenuItem } from 'react-pro-sidebar';
import { useState } from 'react';


function CheckboxSeg(props) {
    const [selectedValue, setSelectedValue] = useState()
    const [checkedItems, setCheckedItems] = useState([])

    const handleCheckboxChange = (event) => {
        const { value } = event.target;

        if (event.target.checked) {
            // If the checkbox is checked, add the item to the checkedItems array
            setCheckedItems((prevCheckedItems) => [...prevCheckedItems, value]);
        } else {
            // If the checkbox is unchecked, remove the item from the checkedItems array
            setCheckedItems((prevCheckedItems) =>
                prevCheckedItems.filter((checkedItem) => checkedItem !== value)
            );
        }
    };

    const handleCheckBoxDoneClick = (close) => {
        const id = 'checkbox';

        // Get the latest checkedItems value
        const end_value = {
            name: props.name || '', // Provide a default value if props.name is undefined
            value: checkedItems,
        };

        // Update props.checkBox using a function that gets the latest state
        props.setCheckBox((prevCheckBox) => ({
            ...prevCheckBox,
            name: end_value.name,
            value: end_value.value,
        }));

        props.setColumnFilters((prevState) => {
            // Find the index of the object with the same id and name in the array
            const index = prevState.findIndex((filter) => filter.id === id && filter.value.name === end_value.name);

            if (index !== -1) {
                if (end_value.value.length === 0) {
                    // If the end_value is empty, remove the filter by filtering out the one with the matching id
                    return prevState.filter((filter) => filter.value.name !== end_value.name);
                }

                // If an object with the same id and name is found and the end_value is not empty, update it
                return prevState.map((filter, i) =>
                    i === index ? { ...filter, value: end_value } : filter
                );
            } else {
                if (end_value.value.length > 0) {
                    // If not found and end_value is not empty, add a new object to the array
                    return [...prevState, { id, value: end_value }];
                } else {
                    // If not found and end_value is empty, return the previous state (no change)
                    return prevState;
                }
            }
        });

        localStorage.removeItem('users_list')
        setSelectedValue(end_value.value.map((item,index) => <div key={index}> {item} </div>))
        close();
    };




    return (
        <Popup
            trigger={<MenuItem icon={props.icon} style={{ display: 'flex', flexDirection: 'row' , borderWidth: '0px 0px 1px 0px', borderStyle: 'solid', borderColor: 'gainsboro'}}>
                <div className='item-name'>
                    {props.menuItemName}
                </div>
                <div className='selected-value' style={{ display: 'flex', flexDirection: 'row', gap: '5px', float: 'left', color: 'gray' }}>
                    {selectedValue}
                </div>
            </MenuItem>}
            position="buttom center"
            nested
            contentStyle={{ maxHeight: '250px', overflowY: 'auto', margin: 'auto'}}
        >
            {close => (
                <div className='popup-url-container'>
                    <div className={`popup-${props.name}`}>
                        <InputLabel></InputLabel>
                        <FormGroup >
                            {props.items.map((item, index) =>
                                <FormControlLabel
                                    key={index}
                                    control={
                                        <Checkbox
                                            checked={checkedItems.includes(item)}
                                            onChange={handleCheckboxChange}
                                            name={props.name}
                                            value={item}
                                        />
                                    }
                                    label={item}
                                />
                            )}
                        </FormGroup>
                    </div>
                    <Button onClick={() => handleCheckBoxDoneClick(close)}> Done </Button>
                </div>
            )}
        </Popup>
    )
}

export default CheckboxSeg
