import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const GaugeChart = (props) => {

    const companyID = useParams().companyId
    const segmentID = useParams().segmentID

    const [creditScore, setCreditScore] = useState(300)

    const getAverage = async () => {
        try {
          const response = await axios.post(`/api/get-average`,{
            company_id: companyID,
            segment_id: segmentID,
            start_date: props.dashboardDetails.start_date,
            end_date: props.dashboardDetails.end_date,
            average_type: 'credit_range',
            filters: (() => {
                // Check if segment and filters properties are present
                if (props.dashboardDetails.segment && props.dashboardDetails.segment.filters) {
                  return props.dashboardDetails.segment.filters;
                } else {
                  return undefined;
                }
              })()
          });
          if (response.status == 200) {
            const result = await response.data
            if (result === 0) {
                result = 300
            }
            setCreditScore(result)
          } else {
            console.error('Access denied:', response.statusText);
          }
        } catch (error) {
          console.error(error)
        }
      }

  // Configuration for the gauge
  const size = 400;
  const clipWidth = 390;
  const clipHeight = 300;
  const ringInset = 20;
  const ringWidth = 70;

  // Pointer configuration
  const pointerWidth = 10;
  const pointerTailLength = 5;
  const pointerHeadLengthPercent = 0.7;

  // Scale configuration
  const minValue = 300;
  const maxValue = 850;
  const minAngle = -90;
  const maxAngle = 90;

  // Calculations for the pointer
  const r = size / 2;
  const pointerHeadLength = Math.round(r * pointerHeadLengthPercent);

  // Conversion function for degrees to radians
  const deg2rad = (deg) => deg * Math.PI / 180;

  // Color scale for the arcs
  const color = d3.scaleLinear()
    .domain([300, 579, 580, 669, 670, 739, 740, 799, 800, 850])
    .range(['#FF565F', '#FF565F', '#FF8446', '#FF8446', '#FAB11D', '#FAB11D', '#9ED872', '#9ED872', '#64CA64', '#64CA64']);

  // Linear scale for the gauge
  const scale = d3.scaleLinear()
    .range([0, 1])
    .domain([minValue, maxValue]);

  // Arc generator
  const arc = d3.arc()
    .innerRadius(r - ringWidth - ringInset)
    .outerRadius(r - ringInset)
    .startAngle((d) => deg2rad(minAngle + (scale(d.startValue) * (maxAngle - minAngle))))
    .endAngle((d) => deg2rad(minAngle + (scale(d.endValue) * (maxAngle - minAngle))));

  // Data for the arc segments
  const arcData = [
    { startValue: 300, endValue: 580 },
    { startValue: 580, endValue: 670 },
    { startValue: 670, endValue: 740 },
    { startValue: 740, endValue: 800 },
    { startValue: 800, endValue: 850 }
  ];

  // Labels for each segment
  const arcLabels = ['POOR', 'FAIR', 'GOOD', 'GREAT', 'BEST'];

  const svgRef = useRef();

  useEffect(() => {
    const svg = d3.select(svgRef.current);
    // Remove existing elements before rendering
    svg.selectAll('*').remove();

    // Group for arcs
    const arcs = svg.append('g')
      .attr('class', 'arc')
      .attr('transform', `translate(${r},${r})`);

    // Append arc paths
    arcs.selectAll('path')
      .data(arcData)
      .enter().append('path')
      .attr('fill', (d) => color(d.startValue))
      .attr('d', arc);

    // Append qualitative descriptors to the arcs
    arcs.selectAll('text')
      .data(arcData)
      .enter().append('text')
      .attr('transform', (d) => {
        const middleValue = (d.startValue + d.endValue) / 2;
        const ratio = scale(middleValue);
        const newAngle = minAngle + (ratio * (maxAngle - minAngle));
        const rotateAngle = (newAngle + 90) % 360;
        let result = `rotate(${newAngle}) translate(0,${-(r - ringWidth / 2)})`;
        if (rotateAngle > 180 && rotateAngle < 270) {
          result += ` rotate(180)`;
        }
        return result;
      })
      .attr("text-anchor", "middle")
      .text((d, i) => arcLabels[i])
      .style("fill", "#fff")
      .style("font-size", "14px")
      .style("alignment-baseline", "middle");

      const pointerLine = d3.line().curve(d3.curveMonotoneX);
      const pg = svg.append('g')
        .data([[
          [pointerWidth / 2, 0],
          [0, -pointerHeadLength],
          [-(pointerWidth / 2), 0],
          [0, pointerTailLength],
          [pointerWidth / 2, 0]
        ]])
        .attr('class', 'pointer')
        .attr('transform', `translate(${r},${r})`);
      
      const pointer = pg.append('path')
        .attr('d', pointerLine)
        .attr('transform', `rotate(${minAngle})`)
        .attr('fill', '#333');

    // Append a text element for the current value
    const currentValueText = svg.append("text")
      .attr("class", "current-value")
      .attr("x", r)
      .attr("y", r - -50)
      .attr("text-anchor", "middle")
      .style("font-size", "36px")
      .style("font-weight", "bold")
      .text("");

    // Function to update the pointer's position
    const updatePointer = (value) => {
      const newAngle = minAngle + (scale(value) * (maxAngle - minAngle));
      pointer.transition()
        .duration(400)
        .ease(d3.easeElastic)
        .attr('transform', `rotate(${newAngle})`);

      // Update the current value text
      currentValueText.text(value);
    };

    // Example: Update the pointer to a given value
    updatePointer(creditScore); // Update this value to move the pointer and display the current value
    return () => {
        // Clean up D3 elements when the component is unmounted or rerendered
        svg.selectAll('*').remove();
      };
  }, [creditScore]);

  useEffect(() => {
    getAverage()
  }, [props.dashboardDetails.start_date, props.dashboardDetails.end_date])

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px'}}>
        <div style={{ alignSelf: 'flex-start', fontWeight: '500'}}>
            Average Credit Score
        </div>
        <div  id="power-gauge" style={{ alignSelf: 'center', display: 'flex', flexDirection: 'column' }}>
        <svg
        ref={svgRef}
        className="gauge"
        width={clipWidth}
        height={clipHeight}
        viewBox={`0 0 ${clipWidth} ${clipHeight}`}
        preserveAspectRatio='xMinYMid meet'
        style={{  }}
      />
      <div style={{ alignSelf: 'center', fontWeight: '500', fontSize: '20px', marginTop: '-40px' }}>
        Credit Score
      </div>
        </div>
    </div>
  );
};

export default GaugeChart;
