import React, { useEffect, useRef, useState } from 'react';
import { Outlet, useParams, useNavigate, useOutletContext } from "react-router-dom";
import TextField from '@mui/material/TextField';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import klayviyoIcon from '../images/klayviyoIcon.jpeg'
import CustomerIOIcon from '../images/CustomerIOIcon.png'
import mailChimpIcon from '../images/mailChimpIcon.jpeg'
import sendGridIcon from '../images/sendGridIcon.png'
import hubSpotIcon from '../images/hubSpotIcon.jpeg'
import omniSendIcon from '../images/omniSendIcon.png'
import emailOctopusIcon from '../images/emailOctopusIcon.png'
import bayEngageIcon from '../images/bayEngageIcon.png'
import Button from '@mui/material/Button';
import TopBar from '../CompanyDashboardComponents/TopBar';
import axios from 'axios';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';


function LinearProgressWithLabel(props) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value,
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }

function ViewInteg() {
    const delay = ms => new Promise(res => setTimeout(res, ms));
    const [token, setToken_] = useState(localStorage.getItem("token"));
    const [syncProgress, setSyncProgress] = useState(0);  // State to hold the progress
    const [syncStarted, setSyncStarted] = useState(0)
    const [ syncButtonDisabled, setSyncButtonDisabled] = useState(false)
    const [ syncButtonLoading, setSyncButtonLoading] = useState(true)
    const [ syncJobId, setSyncJobId ] = useState(false)

    const navigate = useNavigate()
    const segmentID = useParams().segmentID
    const companyID = useParams().companyId


    const [integration, setIntegration] = useState(JSON.parse(localStorage.getItem('integration')))

    const iconDict = {
        'Klaviyo': klayviyoIcon,
        'Customer.io': CustomerIOIcon,
        'Mailchimp': mailChimpIcon,
        'SendGrid': sendGridIcon,
        'HubSpot': hubSpotIcon,
        'OmniSend': omniSendIcon,
        'EmailOctopus': emailOctopusIcon,
        'BayEngage': bayEngageIcon

    }

    const syncIntegration = async () => {
        setSyncButtonDisabled(true)
        try {
            const response = await axios.post(`/api/sync-company-integration`, {
                company_id: companyID,
                integration_name: integration.integration_name,
                api_key: integration.api_key,
                site_id: integration.site_id,
                list_id: integration.list_id,
                client_secret: integration.client_secret,
                public_id: integration.public_id,
                index: integration.index,
                segment_id: segmentID,
                filters: localStorage.getItem('segment') && JSON.parse(localStorage.getItem('segment')).filters
            }, {
                headers: { Authorization: token }
            });
            if (response.status === 200) {
            } else {
                console.error('Access denied:', response.statusText);

            }
        } catch (error) {
            console.log(error)
        }
    };

    const getSyncProgress = async () => {
        try {
            const response = await axios.post(`/api/get-sync-progress`, {
                company_id: companyID,
                index: integration.index,
                segment_id: segmentID,

            }, {
                headers: { Authorization: token }
            });
            if (response.status === 200) {
                const result = response.data
                setSyncStarted(result.sync_started)
                setSyncProgress(result.sync_progress)
                setSyncJobId(result.sync_job_id)
                if (result.sync_started !== 0) {
                    setSyncButtonDisabled(true)
                } else {
                    setSyncButtonDisabled(false)
                }
            } else {
                console.error('Access denied:', response.statusText);
            }
        } catch (error) {
            console.log(error)
        } finally {
            setSyncButtonLoading(false)
        }
    };

    const stopSyncProgress = async () => {
        try {
            const response = await axios.post(`/api/stop-sync-progress`, {
                company_id: companyID,
                index: integration.index,
                segment_id: segmentID
            }, {
                headers: { Authorization: token }
            });
            if (response.status === 200) {
                const result = response.data
                setSyncStarted(0)
                setSyncProgress(0)
                setSyncButtonDisabled(false)
            } else {
                console.error('Access denied:', response.statusText);
            }
        } catch (error) {
            console.log(error)
        } 
    };

    const deleteIntegration = async () => {
        try {
            const response = await axios.delete('/api/delete-integration', {
                params: {
                    integration_id: integration.id,
                    segment_id: segmentID
                }
            });
            const result = response.data;
        } catch (error) {
            console.error('An error occurred:', error);
        } finally {
        }
    };

    const handleDeleteClick = () => {
        stopSyncProgress()
        deleteIntegration()
        navigate('../.')
        navigate(0)
    }

    const handleSyncClick = async () => {
        syncIntegration()
        await delay(1500)
        getSyncProgress()
    }

    useEffect(() => {
        getSyncProgress()
    }, [])

    useEffect(() => {
        if (syncStarted !== 0) {
            const timer = setInterval(() => {
                getSyncProgress()
              }, 10000);
              return () => {
                clearInterval(timer);
              };
        }
      }, [syncStarted]);

    return (
        <div style={{ height: '130vh', backgroundColor: '#dcf2f6' }}>
            <TopBar
                className='search-bar'
                companyName={'Integrations'}
                backAmount={'..'}
            />
            <div className='new-container' style={{ backgroundColor: 'white', width: '50%', marginLeft: '20%', marginTop: '2%', height: '90%', borderRadius: '20px', padding: '15px 40px 40px 40px' }}>
                <div style={{ display: 'flex', gap: '10px', marginTop: '10px' }}>
                    <Avatar alt={integration.integration_name} src={iconDict[integration.integration_name]} />
                    <div className='titlee' style={{ fontSize: '18px', alignSelf: 'center' }}>
                        {integration.integration_name} Integration
                    </div>
                </div>
                <Divider sx={{ marginTop: '20px', backgroundColor: 'gray', marginBottom: '20px' }} />

                <div style={{}}>
                    <p style={{ padding: '10px 10px 10px 10px', backgroundColor: 'gainsboro', borderRadius: '10px' }}>
                        This integration will trigger every time new users are added, It will add those new users to {integration.integration_name}, For existing users, You can upload all captured users by using the sync button, or by exporting the data and uploading it manually,
                        If you experience any difficulties please reach out to support@iceweb.com as this feature is currently in beta
                    </p>
                </div>
                {integration.integration_name == 'BayEngage' ? (
                    <div>
                        <div className='title' style={{ marginLeft: '20px', marginTop: '40px' }}>
                            {integration.integration_name} Client Secret
                        </div>
                        <TextField disabled value={integration.client_secret} sx={{ width: '100%', fontSize: '0.875rem', fontWeight: '400', lineHeight: '1.5', padding: '8px 12px', borderRadius: '8px', paddingTop: '10px' }} />
                        <div className='title' style={{ marginLeft: '20px', marginTop: '10px' }}>
                            {integration.integration_name} Public ID
                        </div>
                        <TextField disabled value={integration.public_id} sx={{ width: '100%', fontSize: '0.875rem', fontWeight: '400', lineHeight: '1.5', padding: '8px 12px', borderRadius: '8px', paddingTop: '10px' }} />
                        <div className='title' style={{ marginLeft: '20px', marginTop: '10px' }}>
                            {integration.integration_name} List ID
                        </div>
                        <TextField disabled value={integration.list_id} placeholder='None' sx={{ width: '100%', fontSize: '0.875rem', fontWeight: '400', lineHeight: '1.5', padding: '8px 12px', borderRadius: '8px', paddingTop: '10px' }} />
                    </div>
                ) : (
                    <div>
                        <div className='title' style={{ marginLeft: '20px', marginTop: '40px' }}>
                            {integration.integration_name} API Key
                        </div>
                        <TextField disabled value={integration.api_key} sx={{ width: '100%', fontSize: '0.875rem', fontWeight: '400', lineHeight: '1.5', padding: '8px 12px', borderRadius: '8px', paddingTop: '10px' }} />
                        {integration.site_id.length < 1 && integration.list_id.length > 0 ? (
                            <div>
                                <div className='title' style={{ marginLeft: '20px', marginTop: '10px' }}>
                                    {integration.integration_name} List ID
                                </div>
                                <TextField disabled value={integration.list_id} sx={{ width: '100%', fontSize: '0.875rem', fontWeight: '400', lineHeight: '1.5', padding: '8px 12px', borderRadius: '8px', paddingTop: '10px' }} />
                            </div>
                        ) : integration.site_id.length < 1 && integration.list_id.length < 1 ? (
                            <div>
                            </div>
                        ) : (
                            <div>
                                <div className='title' style={{ marginLeft: '20px', marginTop: '10px' }}>
                                    {integration.integration_name} Site ID
                                </div>
                                <TextField disabled value={integration.site_id} sx={{ width: '100%', fontSize: '0.875rem', fontWeight: '400', lineHeight: '1.5', padding: '8px 12px', borderRadius: '8px', paddingTop: '10px' }} />
                            </div>
                        )}
                        <div className='title' style={{ marginLeft: '20px', marginTop: '10px' }}>
                            Delete Integration
                        </div>
                        
                    </div>
                )}
                                        <div className='button-group'>
                            <div className='sync' style={{ display: 'flex', flexDirection: 'column'}}>
                                <div className='title' style={{ marginLeft: '20px', marginTop: '10px' }}>
                                    Sync Integration
                                </div>
                                {syncStarted === 0 ? (<LoadingButton loading={syncButtonLoading} disabled={syncButtonDisabled} onClick={() => handleSyncClick()} sx={{ marginLeft: '20px', marginTop: '15px', width: '30%' }} variant="contained">
                                    Sync
                                </LoadingButton>) : (<LoadingButton loading={syncButtonLoading} onClick={() => stopSyncProgress()} sx={{ marginLeft: '20px', marginTop: '15px', width: '30%' }} variant="contained">
                                    Stop
                                </LoadingButton>)}
                                <div style={{ marginLeft: '20px', marginTop: '15px'}}>
                                <LinearProgressWithLabel value={syncProgress}/>
                                </div>
                                <Alert sx={{ marginTop: '15px' }} severity="warning">This action will sync all captured users to {integration.integration_name}, Please note that depending on the number of captured users, syncing may take a few hours</Alert>
                            </div>
                            <div className='delete' style={{ display: 'flex', flexDirection: 'column'}}>
                                <div className='title' style={{ marginLeft: '20px', marginTop: '5%' }}>
                                    Delete Integration
                                </div>
                                <Button onClick={() => handleDeleteClick()} sx={{ marginLeft: '20px', marginTop: '15px', width: '30%' }} variant="contained" color="error">
                                    Delete
                                </Button>
                                <Alert sx={{ marginTop: '15px' }} severity="warning"> To change any fields please delete integration and create it with the desired field values</Alert>
                            </div>
                        </div>
            </div>
        </div>
    )
}

export default ViewInteg
