import React from 'react'
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import ReceiptIcon from '@mui/icons-material/Receipt';
import Button from '@mui/material/Button';


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: 'whitesmoke',
    padding: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
    color: theme.palette.text.secondary,
}));

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

function TransactionHistory(props) {
    return (
        <div className='new-container' style={{display: 'flex', flexDirection: 'column', width: '100%', borderRadius: '20px', padding: '15px 40px 40px 40px', height: '60vh' }}>
            <div style={{ display: 'flex', gap: '10px', marginTop: '10px' }}>
                <div className='titlee' style={{ fontSize: '18px', alignSelf: 'center', fontWeight: '600' }}>
                    Transaction History
                </div>
            </div>
            <Divider sx={{ marginTop: '20px', backgroundColor: 'gray', marginBottom: '20px' }} />
            <Box sx={{ width: '100%', overflowY: 'scroll', height: '340px', '::-webkit-scrollbar': { display: 'none' }, border: '1px solid', borderRadius: '10px', padding: '10px', borderColor: 'gainsboro' }}>
                <Stack spacing={1}>
                    {props.transactionList.toReversed().map((item, index) => (
                        <Item key={index}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <div style={{ fontWeight: '600', color: 'gray', fontSize: '10px' }}>
                                    Date
                                </div>
                                <div style={{ fontSize: '11px' }}>
                                    {item.date}
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <div style={{ fontWeight: '600', color: 'gray', fontSize: '10px' }}>
                                    Amount
                                </div>
                                <div style={{ fontSize: '11px' }}>
                                    ${numberWithCommas(item.amount)}
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <div style={{ fontWeight: '600', color: 'gray', fontSize: '10px' }}>
                                    Status
                                </div>
                                <div style={{ fontSize: '11px' }}>
                                    {capitalizeFirstLetter(item.status)}
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column' }} onClick={() => openInNewTab(item.invoice_url)}>
                                <div style={{ fontSize: '11px', alignSelf: 'center', justifySelf: 'center' }}>
                                    <Tooltip title="Invoice">
                                        <IconButton className="company-edit-display" sx={{ width: '30px', height: '30px' }}>
                                            <ReceiptIcon style={{ color: 'gray', transform: 'scale(1)' }} />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </div>
                        </Item>
                    ))}
                </Stack>
            </Box>
            <Button variant="contained" onClick={props.close} sx={{ justifySelf: 'center', alignSelf: 'center', marginTop: '10px' }}>Close</Button>

        </div>
    )
}

export default TransactionHistory
