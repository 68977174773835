import React, { useState } from 'react'
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Popup from 'reactjs-popup';
import TransactionHistory from './TransactionHistory';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: 'whitesmoke',
    padding: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-around',
    color: theme.palette.text.secondary,
    WebkitOverflowScrolling: ''
}));

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function OptionSelect(props) {

    return (
        <div style={{ display: 'flex', gap: '20px', width: '200px', flexDirection: 'column', justifyContent: 'center' }}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', padding: '15px', textAlign: 'left', height: '100%', border: '4px solid white', borderRadius: '10px', backgroundColor: 'white' }}>
                <div style={{ fontSize: '20px', fontWeight: '600' }}>
                    Data Plan
                </div>
                <div style={{ padding: '5px', display: 'flex', flexDirection: 'column', gap: '20px' }}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ fontWeight: '600', color: 'gray', fontSize: '17px' }}>
                            Plan
                        </div>
                        <div style={{ fontSize: '15px' }}>
                            {props.planDetails.data_plan["plan"] === 'email' ? ('Email Focused') : props.planDetails.data_plan["plan"] === 'extended' ? ('Extented Data') : ('None')}
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ fontWeight: '600', color: 'gray', fontSize: '17px' }}>
                            Plan Tier
                        </div>
                        <div style={{ fontSize: '15px' }}>
                            {props.planDetails.data_plan["plan_tier"] ? (props.planDetails.data_plan["plan_tier"]) : ('None')}
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ fontWeight: '600', color: 'gray', fontSize: '17px' }}>
                            Total Credits
                        </div>
                        <div style={{ fontSize: '15px' }}>
                            {props.planDetails.data_plan["total_credits"]}
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', padding: '15px', textAlign: 'left', height: '100%', border: '4px solid white', borderRadius: '10px', backgroundColor: 'white' }}>
                <div style={{ fontSize: '20px', fontWeight: '600' }}>
                    Transaction History
                </div>
                <Box sx={{ width: '100%', overflowY: 'scroll', height: '150px', '::-webkit-scrollbar': { display: 'none' } }}>
                    <Stack spacing={1}>
                        {props.transactionList.toReversed().map((item, index) => (
                            <Item key={index}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <div style={{ fontWeight: '600', color: 'gray', fontSize: '10px' }}>
                                        Amount
                                    </div>
                                    <div style={{ fontSize: '11px' }}>
                                        ${numberWithCommas(item.amount)}
                                    </div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <div style={{ fontWeight: '600', color: 'gray', fontSize: '10px' }}>
                                        Status
                                    </div>
                                    <div style={{ fontSize: '11px' }}>
                                        {capitalizeFirstLetter(item.status)}
                                    </div>
                                </div>
                            </Item>
                        ))}
                    </Stack>
                </Box>
                <Popup trigger={
                   <Button variant="contained" sx={{alignSelf: 'center', fontSize: '12px', marginTop: '10px'}}>View details</Button>
                }
                modal
                nested
                contentStyle={{ height: '500px', borderRadius: '10px' }}
                >
                    { close => (
                            <>
                            <TransactionHistory transactionList={props.transactionList} close={close} />
                            </>
                    )}
                </Popup>
            </div>
        </div>
    )
}

export default OptionSelect
