import React, { useState, useEffect } from 'react'
import TopBar from '../CompanyDashboardComponents/TopBar'
import CompanySegSideBar from '../CompanyDashboardComponents/CompanySegSideBar'
import CompanySegNewPeopleTable from '../CompanyDashboardComponents/CompanySegNewPeopleTable'
import Button from '@mui/material/Button';
import { TextField } from '@mui/material';
import { useNavigate, useOutletContext } from 'react-router-dom';
import axios from 'axios';
import StateContext from '../StateContext';
import { useContext } from 'react'


function CompanySegNew() {

    const navigate = useNavigate()

    const [token, setToken_] = useState(localStorage.getItem("token"));
    const [companyDetails, setCompanyDetails] = useOutletContext()
    const [filteredData, setFilteredData] = useState({})
    const [buttonDisabled, setButtonDisabled] = useState(true)
    const [columnFilters, setColumnFilters] = useState([]);
    const [ segmentName , setSegmentName ] = useState()
    const [showSkeletons, setShowSkeletons] = useState(false)
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 13, //customize the default page size
    });
    const { snackBar, setSnackBar } = useContext(StateContext)


    const addSegment = async () => {
        try {
            const url = '/api/add-segment';
            const params = {
                company_id: companyDetails.company_id,
                segment_name: segmentName,
                filters: columnFilters,
                excluded_users: JSON.parse(localStorage.getItem('excluded_users'))
            };

            const response = await axios.post(url, params, {
                headers: { Authorization: token }
            });
            const result = response.data;
            console.log(result)
        } catch (error) {
            console.error('An error occurred:', error);
        }
    };

    const handleCreateClick = async () => {
        setButtonDisabled(true)
        await addSegment()
        setSnackBar(true)
        setButtonDisabled(false)
        navigate("..")
    }

    useEffect(() => {
        console.log(filteredData)

    }, [filteredData]);

    useEffect(() => {
        if (columnFilters.length == 0) {
            setButtonDisabled(true)
        } else {
            setButtonDisabled(false)
        }
    }, [columnFilters]);

    return (
        <div>
            <div className='bodyy' >
                <div className='top-bar'>
                    <TopBar
                        companyName='New Segment'
                        backAmount={'..'}
                        elements={
                            <div>
                                <TextField
                                    id="outlined-basic"
                                    label="Segment Name"
                                    variant="outlined"
                                    disabled={buttonDisabled}
                                    size='small'
                                    onChange={(event) => setSegmentName(event.target.value)}
                                    sx={{
                                        paddingRight: '11px',
                                    }}
                                />
                                <Button
                                    disabled={buttonDisabled}
                                    variant="contained"
                                    onClick={() => handleCreateClick()}
                                    sx={{
                                        backgroundColor: '#34a8ff',
                                        paddingLeft: '8px',
                                        paddingRight: '11px',
                                        paddingBottom: '5px'
                                    }}>
                                    Done
                                </Button>
                            </div>
                        }
                    />
                </div>
                <div className='pagee' style={{ display: 'flex', paddingLeft: '5%', justifyContent: 'space-between'}}>
                    <div className='inside-page' style={{ width: '50vw', marginTop: '5%', marginLeft: '-3%'}}>
                        <div className='people-table' style={{ width: '125%'}}>
                            <CompanySegNewPeopleTable
                                filteredData={filteredData}
                                pagination={pagination}
                                setPagination={setPagination}
                                showSkeletons={showSkeletons}
                            />
                        </div>
                    </div>
                    <div className='seg-side-bar' style={{ alignSelf: 'center' }}>
                        <CompanySegSideBar
                            filteredData={filteredData}
                            columnFilters={columnFilters}
                            setColumnFilters={setColumnFilters}
                            setFilteredData={setFilteredData}
                            pagination={pagination}
                            setPagination={setPagination}
                            showSkeletons={showSkeletons}
                            setShowSkeletons={setShowSkeletons}
                        />
                    </div>
                </div>
            </div>

        </div>
    )
}

export default CompanySegNew
