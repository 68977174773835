import React, { useEffect, useRef, useState } from 'react';
import { Outlet, useParams, useNavigate, useOutletContext } from "react-router-dom";
import TopBar from '../CompanyDashboardComponents/TopBar';
import klayviyoIcon from '../images/klayviyoIcon.jpeg'
import CustomerIOIcon from '../images/CustomerIOIcon.png'
import mailChimpIcon from '../images/mailChimpIcon.jpeg'
import sendGridIcon from '../images/sendGridIcon.png'
import hubSpotIcon from '../images/hubSpotIcon.jpeg'
import omniSendIcon from '../images/omniSendIcon.png'
import emailOctopusIcon from '../images/emailOctopusIcon.png'
import bayEngageIcon from '../images/bayEngageIcon.png'
import LoadingButton from '@mui/lab/LoadingButton';
import axios from 'axios';
import ApiOnlyInteg from '../CompanyDashboardComponents/ApiOnlyInteg';
import TwoFieldInteg from '../CompanyDashboardComponents/TwoFieldInteg';
import Button from '@mui/material/Button';
import SignInInteg from '../CompanyDashboardComponents/SignInInteg';
import ThreeFieldInteg from '../CompanyDashboardComponents/ThreeFieldInteg';


function CompanyIntegrationsNew() {
    const integrationName = useParams().integrationName
    const companyID = useParams().companyId
    const segmentID = useParams().segmentID

    const navigate = useNavigate()


    const [token, setToken_] = useState(localStorage.getItem("token"));
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        testIntegration()
    };
    const [isLoading, setIsLoading] = useState(false)

    const [integrations, setIntegrations] = useOutletContext()
    const [currentIntegration, setCurrentIntegration] = useState(integrations.filter(obj => {
        return obj.name == integrationName
    }))


    const [apiKey, setApiKey] = useState("")
    const [listID, setListID] = useState("")
    const [siteID, setSiteID] = useState("")
    const [clientSecret, setClientSecret] = useState("")
    const [publicId, setPublicId] = useState("")
    const [resultStatusCode, setResultStatusCode] = useState(null)
    const [buttonDisabled, setButtonDisabled] = useState(true)


    const testIntegration = async () => {
        try {
            setIsLoading(true)
            const url = '/api/test-integration';
            const params = {
                company_id: companyID,
                integration_name: integrationName,
                api_key: apiKey,
                site_id: siteID,
                list_id: listID,
                client_secret: clientSecret,
                public_id: publicId,
                segment_id: segmentID,
                filters: JSON.parse(localStorage.getItem('segment')) && JSON.parse(localStorage.getItem('segment')).filters
            };

            const response = await axios.post(url, params, {
                headers: { Authorization: token }
            });
            const result = response.data;
            setResultStatusCode(result)
            console.log(result)
            if (result == 200) {
                setButtonDisabled(false)
            }

        } catch (error) {
            console.error('An error occurred:', error);
        } finally {
            setIsLoading(false)
        }
    };

    const createIntegration = async () => {
        try {
            const url = '/api/add-integration';
            const params = {
                company_id: companyID,
                integration_name: integrationName,
                api_key: apiKey,
                site_id: siteID,
                list_id: listID,
                client_secret: clientSecret,
                public_id: publicId,
                segment_id: segmentID,
                filters: JSON.parse(localStorage.getItem('segment')) && JSON.parse(localStorage.getItem('segment')).filters
            };

            const response = await axios.post(url, params, {
                headers: { Authorization: token }
            });
            const result = response.data;
            console.log(result)

        } catch (error) {
            console.error('An error occurred:', error);
        } 
    };

    const handleAddClick = async () => {
        setButtonDisabled(true)
        await createIntegration()
        setButtonDisabled(false)
        navigate("..")
    }


    const iconDict = {
        'Klaviyo': klayviyoIcon,
        'Customer.io': CustomerIOIcon,
        'Mailchimp': mailChimpIcon,
        'SendGrid': sendGridIcon,
        'HubSpot': hubSpotIcon,
        'OmniSend': omniSendIcon,
        'EmailOctopus': emailOctopusIcon,
        'BayEngage': bayEngageIcon

    }

    useEffect(() => {
        console.log(clientSecret)
        console.log(publicId)
    }, [clientSecret,publicId]);

    return (
        <div className='body'>
            <div className='top-bar' style={{ zIndex: '0' }} >
                <TopBar
                    className='search-bar'
                    companyName={'Integrations'}
                    backAmount={'../.'}
                    elements={<Button
                        disabled={buttonDisabled}
                        variant="contained"
                        onClick={() => handleAddClick()}
                        sx={{
                            backgroundColor: '#34a8ff',
                            paddingLeft: '8px',
                            paddingRight: '11px',
                            paddingBottom: '5px'
                        }}>
                        Create
                    </Button>}
                />
            </div>
            {currentIntegration[0].type === 1 ? (
                <ApiOnlyInteg
                    integrationName={integrationName}
                    iconDict={iconDict}
                    setApiKey={setApiKey}
                    open={open}
                    handleClick={handleClick}
                    isLoading={isLoading}
                    resultStatusCode={resultStatusCode}
                    buttonDisabled = {buttonDisabled}
                    setButtonDisabled= {setButtonDisabled}
                />
            ) :  currentIntegration[0].type === 4 ? (
                <ThreeFieldInteg currentIntegration={currentIntegration[0]}
                integrationName={integrationName}
                iconDict={iconDict}
                setClientSecret={setClientSecret}
                setPublicId={setPublicId}
                open={open}
                handleClick={handleClick}
                isLoading={isLoading}
                setListID={setListID}
                resultStatusCode={resultStatusCode}
                buttonDisabled = {buttonDisabled}
                setButtonDisabled= {setButtonDisabled}/>
            ) : (
                <TwoFieldInteg
                currentIntegration={currentIntegration[0]}
                integrationName={integrationName}
                iconDict={iconDict}
                setApiKey={setApiKey}
                open={open}
                handleClick={handleClick}
                isLoading={isLoading}
                setListID={setListID}
                setSiteID={setSiteID}
                resultStatusCode={resultStatusCode}
                buttonDisabled = {buttonDisabled}
                setButtonDisabled= {setButtonDisabled}
            />  
            )}

        </div>
    )
}

export default CompanyIntegrationsNew
