import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {createBrowserRouter,RouterProvider,} from "react-router-dom";
import ErrorPage from './CompanyDashboardComponents/ErrorPage';
import CompanyData from './ CompanyDashboardRoutes/CompanyData';
import CompanyDashboard from './ CompanyDashboardRoutes/CompanyDashboard';
import CompanyDashboardRoot from './roots/CompanyDashboardRoot';
import DashboardRoot from './roots/DashboardRoot';
import DashboardCompanies from './DashboardRoutes/DashboardCompanies';
import DashboardAddCompany from './DashboardRoutes/DashboardAddCompany';
import CompanySeg from './ CompanyDashboardRoutes/CompanySeg';
import LoginRoot from './roots/LoginRoot';
import AppRoot from './roots/AppRoot';
import CompanySegRoot from './roots/CompanySegRoot';
import CompanySegNew from './ CompanyDashboardRoutes/CompanySegNew';
import CompanySegData from './ CompanyDashboardRoutes/CompanySegData';
import { StateProvider } from './StateContext';
import { Navigate } from 'react-router-dom';
import CompanySegDashboard from './ CompanyDashboardRoutes/CompanySegDashboard';
import CompanyIntegrations from './ CompanyDashboardRoutes/CompanyIntegrations';
import CompanyIntegrationRoot from './roots/CompanyIntegrationRoot';
import CompanyIntegrationsNew from './ CompanyDashboardRoutes/CompanyIntegrationsNew';
import ViewInteg from './CompanyDashboardComponents/ViewInteg';
import CompanyCompareRoot from './roots/CompanyCompareRoot';
import CompanyCompare from './ CompanyDashboardRoutes/CompanyCompare';
import { GoogleOAuthProvider } from '@react-oauth/google';
import DashboardPaymentCenter from './DashboardRoutes/DashboardPaymentCenter';


const router = createBrowserRouter(
  [
  {
    path: "",
    element: <Navigate to={'/login'}/>,
  },
  {
    path: "/login",
    element: <LoginRoot />,
  },
  {
    path: "/app",
    element: <AppRoot />,
    children: [
      {
        path: "",
        element:  <Navigate to={'/app/companies'}/>,
        errorElement: <ErrorPage />,
      },
      {
        path: "companies",
        element:  <DashboardRoot />,
        children: [
          {
            path: "",
            element: <DashboardCompanies />,
            errorElement: <ErrorPage />,
          },
          {
            path: "admin",
            element: <DashboardAddCompany />,
            errorElement: <ErrorPage />,
          },
          {
            path: ":companyId",
            element: <CompanyDashboardRoot />,
            errorElement: <ErrorPage />,
            children: [
              {
                path: "",
                element: <CompanyDashboard/>,
              },
              {
                path: "data",
                element: <CompanyData />
              },
              {
                path: "segment",
                element: <CompanySegRoot/>,
                children: [
                  {
                    path: "",
                    element: <CompanySeg />,
                  },
                  {
                    path: "new",
                    element: <CompanySegNew/>,
                  },
                  {
                    path: ":segmentID",
                    element: <CompanySegDashboard />,
                  },
                  {
                    path: ":segmentID/data",
                    element: <CompanySegData/>,
                  },
                  {
                    path: ":segmentID/compare",
                    element: <CompanyCompareRoot />,
                    children: [
                      {
                        path: "",
                        element: <CompanyCompare />,
                      },
                    ]
                  },
                  {
                    path: ":segmentID/integrations",
                    element: <CompanyIntegrationRoot />,
                    children: [
                      {
                        path: "",
                        element: <CompanyIntegrations />,
                      },
                      {
                        path: ":integrationID",
                        element: <ViewInteg />,
                      },
                      {
                        path: "new/:integrationName",
                        element: <CompanyIntegrationsNew />,
                      },
                    ]
                  }
                ]
              },
              {
                path: "integrations",
                element: <CompanyIntegrationRoot />,
                children: [
                  {
                    path: "",
                    element: <CompanyIntegrations />,
                  },
                  {
                    path: ":integrationID",
                    element: <ViewInteg />,
                  },
                  {
                    path: "new/:integrationName",
                    element: <CompanyIntegrationsNew />,
                  },
                ]
              },
              {
                path: "compare",
                element: <CompanyCompareRoot />,
                children: [
                  {
                    path: "",
                    element: <CompanyCompare />,
                  },
                ]
              },
              {
                path: "payment",
                element: <DashboardPaymentCenter />,
                errorElement: <ErrorPage />,
              },
            ],
          },
        ]
      },
    ],
  },
  {

  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
          <StateProvider>
    <RouterProvider router={router}/>
  </StateProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


//   <GoogleOAuthProvider clientId="852749625849-0711o5c0mn6elckm3cqllf546am5u58d.apps.googleusercontent.com">
