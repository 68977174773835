import React, { useState } from 'react'
import TopBar from '../CompanyDashboardComponents/TopBar';
import PeopleCount from '../CompanyDashboardComponents/PeopleCount';
import { Form, useOutletContext } from "react-router-dom";
import DateRange from '../CompanyDashboardComponents/DateRange';
import PopularChart from '../CompanyDashboardComponents/PopularChart';
import ByPrecent from '../CompanyDashboardComponents/ByPrecent';
import MapChart from '../CompanyDashboardComponents/MapChart';
import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import CreditBreakdownChart from '../CompanyDashboardComponents/CreditBreakdownChart';
import IncomeBreakdown from '../CompanyDashboardComponents/IncomeBreakdown';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import GaugeChart from '../CompanyDashboardComponents/GaugeChart';


function CompanyDashboard() {


    const [dashboardDetails, setDashboardDetails] = useOutletContext()
    const [content, setContent] = useState("");



    return (
            <div className='dashboard-page' style={{ display: 'flex', flexDirection: 'column'}}>
                <div className='top-bar' style={{ position: 'sticky', top: "0", zIndex: 2}}>
                    <TopBar
                        elements={
                            <div style={{ display: 'flex' }}>
                                <DateRange
                                    currentStartDate={dashboardDetails.start_date}
                                    currentEndDate={dashboardDetails.end_date}
                                    setDashboardDetails={setDashboardDetails}
                                    
                                />
                            </div>
                        }
                        companyName={dashboardDetails.company_name}
                        backAmount={'..'}
                    />
                </div>
                <div className='details'>
                    <Box sx={{ flexGrow: 1, marginLeft: '4%', marginTop: '2%', height: '140vh'}}>
                        <Grid container spacing={2} style={{ gap: '20px'}}>
                            <Grid item sx={{ backgroundColor: 'white', borderRadius: '10px', height: '350px', width: '70%'}}>
                                <PopularChart dashboardDetails={dashboardDetails} setDashboardDetails={setDashboardDetails} />
                            </Grid>
                            <Grid item sx={{ background: 'linear-gradient(11deg, rgba(61,145,255,1) 29%, rgba(0,209,255,1) 100%)', borderRadius: '10px', width: '27%', height: '350px'}}>
                                <PeopleCount
                                    className='people-count'
                                    peopleCount={dashboardDetails.people_count}
                                    journeyCount={dashboardDetails.journey_count}
                                />
                            </Grid>
                            <Grid item sx={{ backgroundColor: 'white', borderRadius: '10px', width: '35%', height: '350px'}}>
                                <ByPrecent dashboardDetails={dashboardDetails} setDashboardDetails={setDashboardDetails} />
                            </Grid>
                            <Grid item sx={{ backgroundColor: 'white', borderRadius: '10px', width: '62%', height: '350px'}}>
                                <MapChart dashboardDetails={dashboardDetails} setDashboardDetails={setDashboardDetails} setTooltipContent={setContent} toolTipContent={content} />
                                <Tooltip id="map-tooltip" />                            
                            </Grid>
                            <Grid item sx={{ backgroundColor: 'white', borderRadius: '10px', width: '55%',}}>
                                <IncomeBreakdown dashboardDetails={dashboardDetails} setDashboardDetails={setDashboardDetails}/>
                            </Grid>
                            <Grid item sx={{ backgroundColor: 'white', borderRadius: '10px', width: '42%', display: 'flex', flexDirection: 'column'}}>
                                <GaugeChart dashboardDetails={dashboardDetails} setDashboardDetails={setDashboardDetails} />
                            </Grid>
                        </Grid>
                    </Box>
                </div>
            </div>
    )
}

export default CompanyDashboard


    //     <PopularChart dashboardDetails={dashboardDetails} setDashboardDetails={setDashboardDetails} />
        // <div className='people-count'>
        //     <PeopleCount
        //         className='people-count'
        //         peopleCount={dashboardDetails.people_count}
        //         journeyCount={dashboardDetails.journey_count}
        //     />
        // </div>
    // </div>
    // <div className='second-row-details'>
    //     <ByPrecent dashboardDetails={dashboardDetails} setDashboardDetails={setDashboardDetails} />
    //     <div className="map-chart-details">
    //     <MapChart dashboardDetails={dashboardDetails} setDashboardDetails={setDashboardDetails} setTooltipContent={setContent} toolTipContent={content} />
    //     <Tooltip id="map-tooltip" />
    //     </div>
    // </div>
    // <div className='third-row-details'>
    // <div className="income-breakdown-details">
    //     <IncomeBreakdown dashboardDetails={dashboardDetails} setDashboardDetails={setDashboardDetails}/>
    //     </div>
    //     {/* <CreditBreakdownChart dashboardDetails={dashboardDetails} setDashboardDetails={setDashboardDetails}/> */}
    // </div>