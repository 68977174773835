import React from 'react'
import Popup from 'reactjs-popup';
import Button from '@mui/material/Button';
import { InputLabel, TextField } from '@mui/material';
import { MenuItem } from 'react-pro-sidebar';
import { useState } from 'react';

function Range(props) {

    const [ selectedValue, setSelectedValue ] = useState()

    const handleRangeChange = (event, type) => {
        const { name, value } = event.target;

        props.setRange((prevState) => ({
            ...prevState,
            name: name,
        }))

        if (type == 'min') {
            props.setRange((prevState) => ({
                ...prevState,
                minVal: value,
            }))
        } else if (type == 'max') {
            props.setRange((prevState) => ({
                ...prevState,
                maxVal: value,
            }))
        }
    };

    const handleRangeDoneClick = (close) => {
        const id = 'range';
        const end_value = props.range;

        props.setColumnFilters((prevState) => {
            // Find the index of the object with the same id in the array
            const index = prevState.findIndex((filter) => filter.id === id && filter.value.name === end_value.name);

            // If an object with the same id is found, update it
            if (index !== -1) {
                if (end_value.minVal.length === 0 || end_value.maxVal.length === 0) {
                    // If the end_value is empty, remove the filter by filtering out the one with the matching id
                    return prevState.filter((filter) => filter.value.name !== end_value.name);
                }

                // If an object with the same id and name is found and the end_value is not empty, update it
                return prevState.map((filter, i) =>
                    i === index ? { ...filter, value: end_value } : filter
                );
            } else {
                if (end_value.minVal.length > 0 || end_value.maxVal.length > 0) {
                    // If not found and end_value is not empty, add a new object to the array
                    return [...prevState, { id, value: end_value }];
                } else {
                    // If not found and end_value is empty, return the previous state (no change)
                    return prevState;
                }
            }
        })

        localStorage.removeItem('users_list')
        setSelectedValue(`${props.range.maxVal} - ${props.range.minVal}`)
        close()
    }
    return (
        <Popup
            trigger={<MenuItem icon={props.icon} style={{ display: 'flex', flexDirection: 'row', borderWidth: '0px 0px 1px 0px', borderStyle: 'solid', borderColor: 'gainsboro'}}>
            <div className='item-name'>
                {props.menuItemName}
            </div>
            <div className='selected-value' style={{ float: 'left', color:'gray' }}>
                {selectedValue}
            </div>
            </MenuItem>}
            position="buttom center"
            nested
        >
            {close => (
                <div className='popup-url-container'>
                    <div className='popup-url'>
                        <InputLabel>{`${props.menuItemName} Range`}</InputLabel>
                        <div className='range-div' name='range'>
                            <TextField
                                id="outlined-number"
                                name={props.name}
                                label={'From'}
                                type="number"
                                size='small'
                                onChange={(event) => handleRangeChange(event, 'min')}
                                style={{ marginTop: '10px', alignSelf: 'center'}}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                            <TextField
                                id="outlined-number"
                                name={props.name}
                                label={'To'}
                                type="number"
                                size='small'
                                onChange={(event) => handleRangeChange(event, 'max')}
                                style={{ marginTop: '15px', alignSelf: 'center'}}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </div>
                    </div>
                    <Button onClick={() => handleRangeDoneClick(close)}> Done </Button>
                </div>
            )}
        </Popup>
    )
}

export default Range
