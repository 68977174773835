import React from 'react'
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';

function CompanyCompareCard(props) {
  return (
        <Grid item sx={{ borderRadius: '10px' }}>
            <div className='card' style={{ maxWidth: "275px" }}>
                <Card sx={{ minWidth: 275, borderRadius: '10px' }}>
                    <CardContent>
                        <Typography sx={{ marginTop: '5px', display: 'flex', gap: '10px' }} component="div">
                            <div style={{ alignSelf: 'center', color: '#399bfe', fontSize: '25px' }}>
                                {props.cardValue}
                            </div>
                        </Typography>
                        <Typography sx={{ marginTop: '10px' }} color="text.secondary">
                            {props.cardName}
                        </Typography>
                        <Typography sx={{ marginTop: '5px', display: 'flex', gap: '10px' }} component="div">
                            {props.cardComparePrecent && props.cardComparePrecent.includes('-') ? (
                                <div style={{ display: 'flex',alignSelf: 'center', backgroundColor: '#FBADAE', color: '#9A0507', textAlign: 'center', borderRadius: '10px' }}>
                                <div style={{ marginLeft: '4px'}}>
                                    {props.cardComparePrecent}
                                </div>
                                <ArrowDownwardIcon sx={{ transform: 'scale(0.65)', backgroundColor: '#9A0507', color: 'white', borderRadius: '100px'}} />
                            </div>
                            ) : props.cardComparePrecent[0] == '0' ? (
                            <div style={{ display: 'flex',alignSelf: 'center', justifyContent:'space-between' , backgroundColor: 'gainsboro', color: 'gray', textAlign: 'center', borderRadius: '10px' }}>
                            <div style={{ marginLeft: '4px'}}>
                            {'0.00%'}
                            </div>
                            <HorizontalRuleIcon sx={{ transform: 'scale(0.65)', backgroundColor: 'gray', color: 'white', borderRadius: '100px'}} />
                        </div>) :
                             (
                                <div style={{ display: 'flex',alignSelf: 'center', backgroundColor: '#D9F9D9', color: '#06A606', textAlign: 'center', borderRadius: '10px' }}>
                                <div style={{ marginLeft: '4px'}}>
                                    {props.cardComparePrecent}
                                </div>
                                <ArrowUpwardIcon sx={{ transform: 'scale(0.65)', backgroundColor: '#06A606', color: 'white', borderRadius: '100px'}} />
                            </div>
                            )}
                        </Typography>
                    </CardContent>
                </Card>
            </div>
        </Grid>
  )
}

export default CompanyCompareCard
